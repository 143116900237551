import fetchRQ from '../../plugins/data-request.js'
import fetchGetRQ from '../../plugins/data-get-requests'

const excludeColumns = ['clientId', 'venueId', 'colour', 'partitionKey', 'table', 'isAssigned', 'slotType']
const columnNames = [
    { id: 'decowaveId', text: 'Decowave ID', sortable: true, order: 1},
    { id: 'devicename', text: 'Device name', sortable: true, order: 2 },
    { id: 'id', text: 'Allocation ID', sortable: true, order: 3 },
    { id: 'displayFloor', text: 'Floor', sortable: true, order: 4 },
    { id: 'name', text: 'Device type', sortable: true, order: 5 },
    { id: 'location', text: 'Coordinates (lat/lng)', sortable: false, order: 6 },
    { id: 'createdDate', text: 'Date Created', sortable: true, order: 7 },
    { id: 'action', text: 'Action', sortable: false, order: 8 }
]
const slotTypes = [
    { type: 0, text: 'Not defined', color: "#e7e7e7"},
    { type: 1, text: 'Wireless Gateway', color: "#844000" },
    { type: 8, text: 'Wireless Sniffer', color: "#83ce00" },
    { type: 2, text: 'Doorway Counter', color: "#ffde50" },
    { type: 3, text: 'BriteZone Beacon', color: "#1ca5ff" },
    { type: 4, text: 'Hand Braclet', color: "#ff1c1c" },
    { type: 5, text: 'Repeater', color: "#ffab00" },
    { type: 6, text: 'Origin Beacon', color: "#00FFFF" },
    { type: 7, text: 'X Axis Point', color: "#9362fb" }
]

export default {
    state: {
        isDeviceListLoaded: false,
        locations_list: { headers: [], values: [] },
        devicemap_ready: false,
        devices_list: slotTypes,
        locations_markers: [],
        selected_location: null,
        is_change_location: false,
        selector_colour: null,
        devices_loc_list: []
    },
    getters : {
        IS_DLIST_LOADED: state => {
          return state.isDeviceListLoaded  
        },
        IS_CHANGE_LOCATION: state => {
            return state.is_change_location
        },
        DEVICE_LOCATIONS_LIST: state => {
            return state.locations_list
        },
        DEVICEMAP_READY: state => {
            return state.devicemap_ready
        },
        DEVICE_TYPES_LIST: state =>{
            return state.devices_list
        },
        GET_LOCATIONS_MARKERS: state => {
            return state.locations_markers
        },
        GET_SELECTED_LOCATION: state => {
            return state.selected_location
        },
        GET_SELECTOR_COLOUR: state => {
            return state.selector_colour
        },
        GET_DEVICES_LOC_LIST: state => {
           return state.devices_loc_list
        }
    },
    actions: {
        INIT_DEVICEMAP: async(context) => {
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            await context.dispatch('CREATE_DEVICES_LIST', null, { root: true })
        },
        GET_DEVICE_LOCATIONS: async (context) => {
            let userID = context.rootGetters.GET_USER_ID
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let locations = await fetchGetRQ(`client/${userID}/venue/${venueID}/devicelocation`)

            locations.message.forEach(location => {
                location['slotType'] = context.state.devices_list.filter(x => x.type == location['slotType']).text
                location['isAssigned'] = location['assignedDevice'] !== null
                location['decowaveId'] = location['assignedDevice'] != null && location['assignedDevice'].decowaveId != null ? location['assignedDevice'].decowaveId : "Not assigned"
                location['devicename'] = location['assignedDevice'] != null && location['assignedDevice'].name != null ? location['assignedDevice'].name : "Not assigned"
                location['assignedDevice'] = location['assignedDevice'] != null ? location['assignedDevice'].id : "Not assigned"
                location['createdDate'] = new Date(location['createdDate']).toLocaleDateString("en-US")
                location['displayFloor'] = location['floor'] + 1
            });

            context.commit('SET_LOCATIONS_LIST', locations.message)
            context.dispatch('CREATE_LOCATIONS_MARKERS')
            context.commit('SET_DLIST_LOADED', locations.message.length > 0)
        },
        SHOW_DLOCATION: async (context, locatioID) => {

            let _locations = context.rootGetters.DEVICE_LOCATIONS_LIST.values
            let selected = _locations.find(x => x.id == locatioID)

            context.commit('SET_SELECTED_LOCATION', selected)
            context.commit('SET_CHANGE_LOCATION', true)
        },
        GET_DLOCATIONS_HEADERS: async (context) => {
            let headers = []
            if(context.state.isDeviceListLoaded){
                columnNames.forEach(column => {
                    headers.push({text: column.text, sortable: column.sortable, value: column.id})
                })
                context.commit('SET_LOCATIONS_HEADERS', headers)
            }
        },
        ADD_DLOCATION: async (context, data) => {
            let userID = context.rootGetters.GET_USER_ID
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let devices = context.rootGetters.GET_DEVICES

            let _device = devices.find(x => x.id == data.deviceID)
            let deviceDecowave = _device !== undefined ? _device.decowaveId : null;

            let deviceName = slotTypes.find(x => x.type == data.device)
            
            let rq = { 
                name: deviceName.text, 
                lat: data.coordinates[0], 
                lng: data.coordinates[1], 
                type: data.device, 
                floor: data.floor, 
                deviceId: data.deviceID, 
                colour: deviceName.color, 
                decowaveId: deviceDecowave, 
                devicename: _device != null ? _device.name : "", 
                x: parseFloat(data.xposition), 
                y: parseFloat(data.yposition) 
            }
            
            await fetchRQ(`client/${userID}/venue/${venueID}/devicelocation`, rq).then((data) => {
                if(data){
                    context.dispatch('GET_DEVICE_LOCATIONS')
                }
            })
        },
        UPDATE_DLOCATION: async (context, data) => {
            
            let userID = context.rootGetters.GET_USER_ID
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let devices = context.rootGetters.GET_DEVICES_LOC_LIST
            let locationID = context.rootGetters.GET_SELECTED_LOCATION.id

            let _device = data.deviceID != null ? devices.find(x => x.id == data.deviceID) : null
            let deviceName = slotTypes.find(x => x.type == data.deviceType)
            let _decowaveId = _device != null ? _device.original.decowaveId : null
            let _deviceName = _device != null ? _device.original.name : "Not Assigned"


            let rq = { 
                name: deviceName.text, 
                lat: data.coordinates[0], 
                lng: data.coordinates[1], 
                type: data.deviceType, 
                floor: data.floor, 
                deviceId: data.deviceID, 
                decowaveId: _decowaveId,
                devicename: _deviceName,
                x: parseFloat(data.xposition), 
                y: parseFloat(data.yposition)
            }

            await fetchRQ(`client/${userID}/venue/${venueID}/devicelocation/update/${locationID}`, rq).then((data) => {
                if(data){
                    context.dispatch('GET_DEVICE_LOCATIONS')
                }
            })
        },
        CREATE_LOCATIONS_MARKERS: async (context) => {
            
            let i = 0
            let _markers = [];
            let _data = context.rootGetters.DEVICE_LOCATIONS_LIST.values
            let _floor = context.rootGetters.GET_SELECTED_FLOOR;

            _data.forEach(location => {
                if(location.floor == _floor){
                    _markers.push({ 
                          id: location.id,
                          decowaveId: location.decowaveId,
                          devicename: location.devicename != null ? location.devicename : "Not Assigned",
                          device: location.assignedDevice,
                          value: [location.location.lng, location.location.lat], 
                          coordinates: location.location.lat + " / " + location.location.lng, 
                          name: location.name, 
                          colour: location.colour 
                        })
                    i++
                }
            })

            context.commit('SET_LOCATIONS_MARKERS', _markers)
        },
        DELETE_LOCATION: async (context, values) => {

            let userID = context.rootGetters.GET_USER_ID
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let locationID = values.id
            let deviceID = values.assignedDevice

            await fetchGetRQ(`client/${userID}/venue/${venueID}/devicelocation/${locationID}/delete/${deviceID}`).then((response) => {
                if(response && response.valid && response.message.success){
                    context.dispatch('GET_DEVICE_LOCATIONS')
                }
            })
        },
        CREATE_LIST_LOC_DEVICES: async (context, selected) => {

            let _list = []
            let _devices = context.rootGetters.GET_DEVICES
            let _locations = context.rootGetters.DEVICE_LOCATIONS_LIST.values
        
            _devices.forEach(device => {       
                let _selected = _locations.some(x => x.assignedDevice == device.id)
                if(!_selected){
                    _list.push({ id: device.id, name: device.name + " - " + device.decowaveId, original: { decowaveId: device.decowaveId, name: device.name } })
                }
            });
      
            if(context.rootGetters.IS_CHANGE_LOCATION && selected !== null){
              let _current = _devices.find(x => x.id == selected)
              _list.unshift({ id: _current.id, name: _current.name + " - " + _current.decowaveId, original: { decowaveId: _current.decowaveId, name: _current.name }});
            }
            
            context.commit('SET_DEVICES_LOC_LIST', _list)
        },
        SETUP_LOCATION_DATA: async (context) => {

            let _selected = context.rootGetters.GET_SELECTED_LOCATION

            let _data = {
                id: _selected.id,
                floor: _selected.floor,
                deviceType: _selected.slotType === undefined ? slotTypes.find(x => x.text == _selected.name).type : _selected.slotType,
                device: _selected.assignedDevice !== "Not assigned" ? _selected.assignedDevice : null,
                xposition: _selected.tLocation != null ? _selected.tLocation.x : 0,
                yposition: _selected.tLocation != null ? _selected.tLocation.y : 0,
                location: [ _selected.location.lat, _selected.location.lng ],
                mapMarker: { color: _selected.colour, coordinates: [_selected.location.lng, _selected.location.lat]}
            }

            context.commit('SET_SELECTOR_COLOUR', _selected.colour)
            context.dispatch('CHANGE_DEVICE_TYPE', _data.deviceType)
            context.commit('SET_JUMPER', { coordinates: [_selected.location.lng, _selected.location.lat], zoom: 18 })

            return _data
        },
        RESET_LOCATION_DATA: async (context) => {

            let _data = {
                id: "",
                floor: context.rootGetters.GET_SELECTED_FLOOR,
                deviceType: null,
                device: null,
                location: [],
                mapMarker: {},
                xposition: null,
                yposition: null
            }

            context.dispatch('CHANGE_JUMPER', 18)
            return _data
        }
    },
    mutations: {
        SET_LOCATIONS_LIST: (state, value) => {
            state.locations_list.values = value
        },
        SET_LOCATIONS_HEADERS: (state, value) => {
            state.locations_list.headers = value
        },
        SET_DLIST_LOADED: (state, value) => {
            state.isDeviceListLoaded = value
        },
        SET_DEVICEMAP_READY: (state, value) => {
            state.devicemap_ready = value
        },
        SET_LOCATIONS_MARKERS: (state, value) => {
            state.locations_markers = value
        },
        SET_SELECTED_LOCATION: (state, value) => {
            state.selected_location = value
        },
        SET_CHANGE_LOCATION: (state, value) => {
            state.is_change_location = value
        },
        SET_SELECTOR_COLOUR: (state, value) => {
            state.selector_colour = value
        },
        SET_DEVICES_LOC_LIST: (state, value) => {
            state.devices_loc_list = value
        }
    }
}