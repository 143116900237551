<template>
  <v-dialog v-model="value" persistent width="400" @keydown.esc="close()" @keydown.enter="close()">
    <v-card class="d-flex flex-column justify-center" style="height: 400px;">
      <v-card-title class="headline grey lighten-2" primary-title>Camera View</v-card-title>
      <div style="width: 75%; margin: auto;  margin-top: 20px;">
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <iframe
          v-show="!loading"
          :src="url"
          frameborder="0"
          scrolling="no"
          height="250"
          width="300"
        ></iframe>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: Boolean,
    url: String
  },
  data() {
    return {
      loading: true
    };
  },
  watch: {
    value(newV, oldV) {
      if (newV) {
        this.loading = true;
        setTimeout(() => (this.loading = false), 2500);
      } else {
      }
    }
  },
  methods: {
    close() {
      this.$emit("input", false);
    }
  }
};
</script>

<style></style>
