import Vue from 'vue'

var endpoint = "client"



class ClientDeviceApiService {

  
    get(clientId) {
        return Vue.axios.get(`${endpoint}/${clientId}/device`);
    }

    // post(request ) {
    //     return Vue.axios.post(endpoint,request)
    // }

    // assignDevice(request) {
    //     return Vue.axios.post(`${endpoint}/assign`,request)
    // }

    // unassignDevice(request)  {
    //     return Vue.axios.post(`${endpoint}/unassign`,request)
    // }
}

var deviceService = new ClientDeviceApiService();

export default deviceService;