class VenueService {
    constructor() {
        this.venues = [];
        this.markers = [];

        this.currentVenue = undefined;
        this.listener = undefined;

    }

    setVenue(venue) {
        this.currentVenue = this.venues.find(s => s.name === venue);

        if(this.listener !== undefined && this.listener !=null) {
            this.listener(this.currentVenue);
        }
    }

    setVenues(venues) {
        this.venues = venues;
        this.markers = [];
        venues.forEach(venue => 
            this.markers.push(venue.location.coordinates)
        );
    }
}

var venueService = new VenueService();

export default venueService;