import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request'

export default {
    state: {
        devices: [],
        tagdevices: [],
        showdevices: true
    },
    getters : {
        GET_DEVICES: state => {
            return state.devices
        },
        GET_TAGDEVICES: state => {
            return state.tagdevices
        },
        GET_DEVICES_VISIBILITY: state => {
            return state.showdevices
        }
    },
    actions: {
        CREATE_DEVICES_LIST: async (context) => {
            let userID = context.rootGetters.GET_USER_ID
            let devices = await fetchGetRQ(`client/${userID}/device`)
            context.commit('SET_DEVICES', devices.message)
        },
        CREATE_TAGDEVICES_LIST: async (context) => {
            let userID = context.rootGetters.GET_USER_ID
            let rq = { userId: userID, isAllocated: true, type: 4 }
            let devices = await fetchRQ(`client/${userID}/device/getlist`, rq)

            context.commit('SET_TAGDEVICES', devices.message)
        },
        CHANGE_DEVICE_TYPE: async (context, value) => {        
            let show = value == 7;
            context.commit('SET_DEVICES_VISIBILITY', show)
        }
    },
    mutations: {
        SET_DEVICES: (state, value) => {
            state.devices = value
        },
        SET_TAGDEVICES: (state, value) => {
            state.tagdevices = value
        },
        SET_DEVICES_VISIBILITY: (state, value) => {
            state.showdevices = value
        }
    }
}