<template>
  <div class="padding-body">
    <div class="table-list">
      <div class="inner">
        <div class="list-box">
            <template>
              <v-data-table :headers="GET_LIST_VENUES.headers" :items="GET_LIST_VENUES.values" :items-per-page="15">
                  <template v-slot:item.action="{item}">
                      <v-btn class="mr-1" @click="editSettings(item)"><v-icon small title="Edit">mdi-pencil-outline</v-icon></v-btn>
                      <v-btn class="mr-1" :to="{ name: 'Venue Floors', params: { id: item.id } }"><v-icon small title="Manage Floors">mdi-layers-triple</v-icon></v-btn>
                      <v-btn class="mr-1" @click="remove(item)"><v-icon small title="Delete">mdi-trash-can-outline</v-icon></v-btn>
                      <v-btn class="mr-1" :to="{ name: 'Venue Zones', params: { id: item.id } }"><v-icon small title="Edit Zones">mdi-image-filter-center-focus</v-icon></v-btn>
                      <v-btn class="mr-1" :to="{ name: 'Venue Alerts', params: { id: item.id } }"><v-icon small title="Edit Alerts">mdi-comment-alert-outline</v-icon></v-btn>
                  </template>
              </v-data-table>
            </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" @click="addVenue()">Add a venue</v-btn>
        </div>
        <venue-settings v-model="GET_MODAL_STATUS.venue_settings"></venue-settings>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueSettings from './settings/index'

export default {
  components: { VenueSettings },
  data() {
    return {};
  },
  computed:{
      ...mapGetters(['GET_LIST_VENUES', 'GET_MODAL_STATUS'])
  },
  methods: {
    addVenue(){
      this.$store.commit('SET_VENUE_CREATE', true)   
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_settings", value: true })
    },
    editSettings(){
      this.$store.commit('SET_VENUE_CREATE', false)
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_settings", value: true }) 
    },



    startCreateFlow: function() {
        this.$router.push({path: './venue/create'});
    },
    edit: function(item) {
      this.$router.push({path: `./venue/${item.id}/edit`});
    },
  },
  mounted() {
    this.$store.commit('SET_CLIENT', this.$route.params.clientId);
    this.$store.dispatch('CREATE_CLIENT_LIST_VENUES').then((response) => {
      this.$store.commit('SET_SETTINGSMAP_READY', true)
    })
  },
};
</script>