import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/authentication'
import Venue from './modules/venueModule'
import Tracking from './modules/trackingModule'
import User from './modules/userModule'
import Dashboard from './modules/dashboardModule'
import Devices from './modules/devicesModule'
import DeviceLocations from './modules/deviceLocationsModule'
import Lifetime from './modules/lifetimeModule'
import HeatMap from './modules/heatmapModule'
import WirelessCounters from './modules/wireless/counters'
import WirelessInsights from './modules/wireless/insights'
import WirelessHeatmap from './modules/wireless/heatmap'
import AllDevices from './modules/devices'
import Venues from './modules/venues'
import Zones from './modules/zones'
import Alerts from './modules/alerts'
import AlertsMessages from './modules/alerts-messages'
import Floors from './modules/floors'
import Modals from './common/modals'
import Loading from './common/loading'
import DoorCounters from './modules/doorCounters'

import VenueModule from './venueModule'

Vue.use(Vuex)

const store = () => {
  return new Vuex.Store({
    namespaced: true,
    modules: {
      Auth,
      Venue,
      Venues,
      Floors,
      Zones,
      Alerts,
      AlertsMessages,
      Tracking,
      User,
      Dashboard,
      Devices,
      DeviceLocations,
      Lifetime,
      HeatMap,
      WirelessCounters,
      WirelessInsights,
      WirelessHeatmap,
      AllDevices,
      Modals,
      Loading,
      DoorCounters,
      venues: VenueModule
    }
  })
}
export default store