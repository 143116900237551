<template>
  <v-form v-model="valid">
    <v-container>
      <v-row justify="center" cols="12">
        <v-col md="10" lg="6" cols="12">
          

          <v-select :items="venues" item-text="text" label="Parent Venue"></v-select>

        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: {
    name: String
  },
    data: () => ({
    valid: false,
     venues: [
      {
        name: "Dashboard",
        icon: "mdi-view-dashboard",
        url: "/admin/dashboard"
      },
      { name: "Clients", icon: "mdi-account-box", url: "/admin/clients" },
      {
          name: "Users",
          icon: "mdi-account-multiple"
      }
    ],
  })
};
</script>