<template>
  <div class="inner_body padding">
    <v-row>
      <v-col>
        <v-btn color="primary" @click="addDialog=true" raised>Add Client</v-btn>
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left no-mobile">Venues</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in clients" :key="item.id">
            <td>{{item.name}}</td>
            <td class="no-mobile">{{item.venues}}</td>
            <td>
              <v-btn @click="manage(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <AddClient v-model="addDialog" v-on:reload="reload"></AddClient>
  </div>
</template>

<script>
import AddClient from "./addClientModal";
import ClientSdk from "../../sdks/clientSdk";

export default {
  components: {
    AddClient,
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      clients: [],
      addDialog: false,
      inviteDialog: false,
      selectedClient: {},
      isPartnerMode: false,
      partnerId: undefined
    };
  },
  mounted() {
    this.isPartnerMode = this.$route.params.partnerId === undefined? false : true;
    this.partnerId = this.$route.params.partnerId;
    this.reload()
  },
  methods: {
    reload: function() {
      ClientSdk.getClients(this.partnerId).then(response => {
        this.clients = response.data;
      });
    },
    inviteClient: function(client) {
      this.selectedClient = client;
      this.inviteDialog = true;
    },
    manage: function(client) {
      this.$store.commit('CHANGE_USER_ID', client.id)
      this.$store.dispatch('RESET_SELECTED_VENUE', null, { root: true })
      this.$router.push({ path: `/client/${client.id}/dashboard` });
    }
  }
};
</script>

<style>
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

.no-mobile {
  display: none;
}
#mobile_menu{ visibility:visible;}

#computer_menu{display:none}

}
</style>