<template>
  <div style="max-width:1200px">
    <v-tabs class="mx-4" v-model="tab">
      <v-tab>Footfall Counters</v-tab>
      <v-tab>Virtual Tour</v-tab>
      <v-tab >Wayfinding</v-tab>
      <v-tab>Order Summary</v-tab>
      <!-- <v-tab>Complete Order</v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab" class="mx-4">
      <v-tab-item>
        <v-card>
          <footfall-pricing
            :order="order"
            v-on:update="updateCounterOrder"
            v-on:next="next"
            v-on:checkout="checkout"
          ></footfall-pricing>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <tour-pricing
            :order="order"
            v-on:update="updateTourOrder"
            v-on:next="next"
            v-on:checkout="checkout"
          ></tour-pricing>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <wayfinding
            :order="order"
            v-on:update="updateWayfindingOrder"
            v-on:next="next"
            v-on:checkout="checkout"
          ></wayfinding>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <order-summary :order="order" v-on:checkout="checkout"></order-summary>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-card>
          <complete-order :order="order"></complete-order>
        </v-card>
      </v-tab-item> -->
      <!-- <v-card flat>
          <v-card-text v-text="text"></v-card-text>
      </v-card>-->
    </v-tabs-items>
    <!-- <v-row class="mx-4">
      <v-col cols="12">
        <p>The above prices represent list pricing. Get in touch if you want to talk to us about partner or enterprise pricing.</p>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
/* ALEX WARD - 2020/04/06
THIS FILE IS HOSTED HERE FOR SIMPLICITY
DUE TO THE LIMITATIONS WITH THE WIX WEBSITE CURRENTLY AT HTTPS://BRITEYELLOW.COM
THIS COMPONENT IS IFRAMED BY THE SITE TO PROVIDE THE PRICING FUNCTIONALITY

THIS IS A TEMPORARY SOLUTION FOR THE MOMENT AND MIGHT BE REFACTORED OUT IN THE FUTURE.
DOUBLE CHECK HOW SITE PRICING IS IMPLEMENTED BEFORE REMOVING
*/

import FootfallPricing from "./footfallPricing";
import TourPricing from "./tourPricing";
import CompleteOrder from "./completeOrder";
import OrderSummary from "./orderSummary";
import Wayfinding from "./wayfindingOrder";

export default {
  components: { OrderSummary, Wayfinding, FootfallPricing, TourPricing, CompleteOrder },
  data: () => {
    return {
      tab: 0,
      order: {
        counters: undefined,
        tours: undefined,
        wayfinding: undefined,
        details: undefined
      }
    };
  },
  methods: {
    next() {
      this.tab++;
    },
    checkout() {
      this.tab = 3;
    },
    updateCounterOrder(order) {
      this.order.counters = order;
    },
    updateTourOrder(order) {
      this.order.tours = order;
    },
    updateWayfindingOrder(order) {
      this.order.wayfinding = order;
    }
  }
};
</script>

<style>
</style>