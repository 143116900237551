<template>
  <div class="map-full">
    <MglMap container="MapBox" :accessToken="accessToken" :mapStyle="mapStyle" :center="jumpTo.coordinates" :zoom="jumpTo.zoom" v-on:load="onLoad">
        <MglNavigationControl position="top-right" />
        <MglMarker :key="item.id" v-for="item in markers.list" :coordinates="item.value" :color="markers.colour"  />
        <MglMarker :key="item.id" v-for="item in devices" :coordinates="item.value" :color="item.colour">
            <MglPopup>
                <div class="map-modal device-locations">
                    <span class="header">Decowave ID:</span> {{ item.decowaveId }}<br>
                    <span class="header">Device name:</span> {{ item.devicename }}<br>
                    <span class="header">Device type:</span> {{ item.name }}<br>
                    <span class="header">Coordinates (Lat/Lng):</span> {{ item.coordinates }}
                </div>
            </MglPopup>
        </MglMarker>
        <MglMarker :key="item.id" v-for="item in lifetrackers.list" :coordinates="item.value" :color="lifetrackers.colour">
            <MglPopup><div>Device ID: {{ item.device }}</div></MglPopup>
        </MglMarker>
        <MglMarker :key="item.id" v-for="item in wireless" :coordinates="item.value" :color="item.colour"></MglMarker>
        <MglMarker v-if="selector.coordinates && selector.coordinates.length > 0 && isSelector" :coordinates="selector.coordinates" :color="GET_SELECTOR_COLOUR"  />
        
        <!-- <MglGeojsonLayer v-if="venueZones.data !== undefined" :sourceId="venueZones.data.id" :source="venueZones" layerId="venueZones" :layer="zoneLayer"></MglGeojsonLayer>  -->
        
        <MglGeojsonLayer v-if="geoMap.data !== undefined" :sourceId="geoMap.data.id" :source="geoMap" layerId="floorPlan" :layer="mapLayer"></MglGeojsonLayer>
        <MglGeojsonLayer v-if="tracks.data !== undefined" :sourceId="tracks.data.id" :source="tracks" layerId="Tracks" :layer="pathLayer"></MglGeojsonLayer>
        <MglGeojsonLayer v-if="heatMap.data !== undefined" :sourceId="heatMap.data.id" :source="heatMap" layerId="HeatMap" :layer="heatLayer"></MglGeojsonLayer>
    </MglMap>
  </div>
</template>

<script type="text/javascript">

import { mapGetters } from 'vuex'
import { MglMap, MglGeojsonLayer, MglNavigationControl, MglMarker, MglPopup } from "vue-mapbox";
import 'mapbox-gl/dist/mapbox-gl.css'
import { RulerControl } from 'mapbox-gl-controls'
import MapboxDraw from 'mapbox-gl-draw'
import 'mapbox-gl-draw/dist/mapbox-gl-draw.css'

export default {
    components:{ MglMap, MglGeojsonLayer, MglNavigationControl, MglMarker, MglPopup },
    name: "Map",
    data() {
        return{
            mapRef: null,
            mapStyle: "mapbox://styles/mapbox/light-v10",//"mapbox://styles/alexwardby/ck0xo4znv03gp1cqyuk4ufe3q", //process.env.MAPBOX_STLE
            accessToken: "pk.eyJ1IjoiYWxleHdhcmRieSIsImEiOiJjazB4ajJ6ODgwNjEwM21xbWl3bjI4bmNrIn0.uoA6VRMGaOJUIY1EPkEf5g", //process.env.MAPBOX_TOKEN
            mapLayer: {
                id: "floorPlan",
                type: "line",
                paint: { "line-color": "black" }
            },
            pathLayer: {
                id: "Tracks",
                type: "line",
                source: "Tracks",
                paint: {
                'line-color': "red",
                "line-width": 3,
                }
            },
            heatLayer: {
                id: "HeatMap",
                type: "heatmap",
                maxzoom: 20,
                paint: {
                "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "mag"],
                    0,
                    0,
                    6,
                    1
                ],
                // Increase the heatmap color weight weight by zoom level
                // heatmap-intensity is a multiplier on top of heatmap-weight
                "heatmap-intensity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    1,
                    9,
                    3
                ],
                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                // Begin color ramp at 0-stop with a 0-transparancy color
                // to create a blur-like effect.
                "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(33,102,172,0)",
                    0.2,
                    "rgb(103,169,207)",
                    0.4,
                    "rgb(209,229,240)",
                    0.6,
                    "rgb(253,219,199)",
                    0.8,
                    "rgb(239,138,98)",
                    1,
                    "rgb(178,24,43)"
                ],
                // Adjust the heatmap radius by zoom level
                "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 1, 1,15,3, 16,7,17,15,18,30,19,60,21,100]
                // Transition from heatmap to circle layer by zoom level
                // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0]
                }
            },
            zoneLayer: {
                id: "venueZones",
                type: "fill",
                paint: {
                    'fill-color': '#0080ff',
                    'fill-opacity': 0.5
                }
            },
            selector: { colour: "#1ca5ff", coordinates:[] },
            zoneDraw: {}
        }
    },
    props: {
        markers:{
            default: () => { return { list: [], colour: null } },
            type: Object
        },
        lifetrackers:{
            default: () => { return { list: [], colour: null } },
            type: Object
        },
        wireless:{
            default: () => { return [] },
            type: Array
        },
        devices:{
            default: () => { return [] },
            type: Array
        },
        jumpTo:{
            default: () => { return { coordinates: [], zoom: 5} },
            type: Object
        },
        geoMap:{
            default: () => { return { type: "geojson", data: { id: "floorPlan", type: "FeatureCollection", features: [] }}},
            type: Object
        },
        heatMap: {
            default: () => { return { type: "geojson", data: { id: "HeatMap", type: "FeatureCollection", features: [] }}},
            type: Object
        },
        tracks:{
            default: () => { return { type: "geojson", data: { id: "Tracks", type: "FeatureCollection", features: [] }}},
            type: Object            
        },
        isSelector: {
            default: false,
            type: Boolean
        },
        selectorPosition: {
            default: () => { return { colour: "#1ca5ff", coordinates:[] } },
            type: Object
        },
        useRuler: {
            default: false,
            type: Boolean
        },
        drawZones: {
            default: false,
            type: Boolean
        },
        venueZones:{
            default: () => { return { type: "geojson", data: { id: "venueZones", type: "FeatureCollection", features: [] }}},
            type: Object
        },
    },
    watch: {
        selectorPosition(newValue, oldValue){
            this.selector = { colour: this.GET_SELECTOR_COLOUR, coordinates:newValue.coordinates }    
        }
    },
    created() {
        this.mapLayer = {
            id: "floorPlan",
            type: "line",
            paint: {"line-color": "#1976d2"}
        }
    },
    methods: {
        emitClick(obj) {
            this.$emit('map-clicked', obj.lngLat);
            this.selector.coordinates = [obj.lngLat.lng, obj.lngLat.lat]
            this.selector.colour = this.GET_SELECTOR_COLOUR
        },
        onLoad(e){
            this.mapRef = e.component;
            this.mapRef.map.resize()
            this.mapRef.map.on('click', this.emitClick);

            if(this.useRuler){
                this.mapRef.map.addControl(new RulerControl({
                    units: 'meters',
                    mainColor: '#ff236e',
                    labelFormat: n => `${n.toFixed(2)} m`,
                }), 'top-right');
            }

            if(this.drawZones){

                this.zoneDraw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        polygon: true,
                        trash: true
                    },
                    defaultMode: 'draw_polygon'
                });

                this.mapRef.map.addControl(this.zoneDraw)
                this.mapRef.map.on('draw.create', this.saveZone);
                this.mapRef.map.on('draw.delete', this.saveZone);
                this.mapRef.map.on('draw.update', this.saveZone);
            }
        },
        saveZone(e){
            let zone = this.zoneDraw.getAll();
            let _coordinates = zone.features.length > 0 ? zone.features[0].geometry.coordinates[0] : [];
            this.$store.commit('SET_ZONE_DIMENSIONS', _coordinates)
        }
    },
      computed:{
      ...mapGetters(['GET_SELECTOR_COLOUR'])
  },
}
</script>