<template>
    <v-dialog v-model="GET_MODAL_STATUS.venue_floors" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <div class="box-header">
                <div class="left">
                    <span>Floors</span>
                </div>
                <div class="right">
                <v-btn icon @click="close()">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                </div>
            </div>
            <div>

            </div>
        </div>
    </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    component:{},
    data() {
        return {

        }
    },
    computed:{
        ...mapGetters(['GET_MODAL_STATUS'])
    },
    methods: {
        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "venue_floors", value: false}) 
        },
    }
}

</script>