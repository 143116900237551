import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import Amplify from 'aws-amplify';
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/global.scss'
import store from './store/index'
import HighchartsVue from 'highcharts-vue'
// By simply importing authInterceptor it becomes active and adds bearer token. 
// this is a bit weird as it doesn't look like it does anything and should be removed as an unused import.
import Interceptor from '../src/sdks/authInterceptor';

axios.defaults.baseURL = process.env.VUE_APP_API; 

Vue.use(VueAxios, axios)
Vue.use(HighchartsVue)

axios.interceptors.request.use()
Vue.config.productionTip = false

Amplify.configure({
  Auth: {
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_I38PweVl5',
      userPoolWebClientId: '21qccuirl8vr1snver3egbf9u1',
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});

Vue.use(AmplifyPlugin, AmplifyModules)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
