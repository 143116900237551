<template>
  <div class="view-map realtime">
      <div class="info-box" v-if="!IS_VENUE_SELECTED">
        <div class="notification">
          <div class="inner"><v-icon dark>mdi-cancel</v-icon> To view the data, please select a venue</div>
        </div>
      </div>
      <div class="map-inner" v-else>
        <div class="filter-area map" v-if="LIFETIMEMAP_READY">
          <div class="filter-device-selector left limited">
            <v-container fluid>
              <v-select v-model="selectedDevices" :items="GET_TAGDEVICES" item-text="name" item-value="id" label="Select Devices" multiple>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectedDevices.length > 0 ? 'indigo darken-4' : ''"> {{ icon }} </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0"><span>{{ item.name }}</span></v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">(+{{ selectedDevices.length - 1 }} others)</span>
                </template>
              </v-select>
            </v-container>
          </div>
          <div class="filter-floor left">
            <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
          </div>
          <div class="filter-refresh-button left">
            <v-btn color="primary" @click="showHistory = true">Show History Table</v-btn>
          </div>
        </div>
        <mapview :jumpTo="GET_JUMPER" :geoMap="GET_VENUE_MAP" :lifetrackers="GET_LIFETIME"></mapview>
      </div>
      <history v-model="showHistory"></history>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from '../shared/mapview'
import History from '../realtime/history'

export default {
  components: { Mapview, History },
  data() {
    return {
      selectedDevices: [],
      selectedFloor: null,
      showHistory: false,
    };
  },
  computed:{

      ...mapGetters(['GET_USER_ID', 'CURRENT_VENUE', 'GET_SELECTED_FLOOR', 'GET_JUMPER', 'GET_VENUE_MAP', 'GET_VENUE_FLOORS', 'GET_LIFETIME', 'LIFETIMEMAP_READY', 'IS_VENUE_SELECTED', 'GET_TAGDEVICES']),

      selectAll () {
        return this.selectedDevices.length === this.GET_TAGDEVICES.length
      },
      selectSome () {
        return this.selectedDevices.length > 0 && !this.selectAll
      },
      icon () {
        if (this.selectAll) return 'mdi-close-box'
        if (this.selectSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  watch: {
    selectedDevices(newValue, oldValue){
      this.$store.commit('TRACK_LIFEDEVICES', this.selectedDevices)
      this.$store.dispatch('FIND_REALTIME_TRACKS')
    },
    selectedFloor(newValue, oldValue){
      this.$store.commit('TRACK_LIFEDEVICES', this.selectedDevices)
      this.$store.dispatch('FIND_REALTIME_TRACKS')
    },
    CURRENT_VENUE(newValue, oldValue){
      this.$store.dispatch('INIT_LIFETIME_MAP').then((response) => {           
          this.selectedFloor = this.GET_SELECTED_FLOOR
          this.$store.commit('SET_LIFETIMEMAP_READY', true)
          this.$store.commit('TRACK_LIFEDEVICES', this.selectedDevices)
          this.runInterval()
      })
    }
  },
  mounted() {
    if(this.IS_VENUE_SELECTED){
      this.$store.dispatch('INIT_LIFETIME_MAP').then((response) => {
        this.selectedFloor = this.GET_SELECTED_FLOOR
        this.$store.commit('SET_LIFETIMEMAP_READY', true)
        this.$store.commit('TRACK_LIFEDEVICES', this.selectedDevices)
        this.runInterval()
      })
    }
  },
  methods: {
      changeFloor(){
        this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
        this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor) 
      },
      toggle () {
        this.$nextTick(() => {
          if (this.selectAll) {
            this.selectedDevices = []
          } else {
            this.selectedDevices = this.GET_TAGDEVICES.map(x => x.id)
          }
        })
      },
      runInterval(){
        this.timer = setInterval(() => {
          this.$store.dispatch('FIND_REALTIME_TRACKS', this.selectedDevices)
        }, 5000)
      }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>