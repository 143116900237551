<template>
  <div>
    <div v-if="venue==null">Loading...</div>
    <div v-if="venue!=null">
      <v-simple-table style="max-width:200px;">
        <tr>
          <th>Name</th>
          <td>{{venue.name}}</td>
        </tr>
        </v-simple-table>

          <div style="margin:10px">

          <v-btn @click="addFloor()">Add Floor</v-btn>
          </div>
          <v-simple-table style="max-width:800px;">
            <tr>
              <th class="cell-narrow">Floor</th>
              <th class="cell-narrow">FloorHeight (m)</th>
              <th class="cell-narrow">FloorWidth (m)</th> 
              <th class="cell-narrow">FloorDepth (m)</th>                           
              <th>Floorplan</th>
              <th>GeoJson</th>
            </tr>
            <floor-edit v-for="floor in venue.floors" :key="floor.floorIndex" :floor="floor" :venueId="venue.id" :clientId="venue.clientId"/>
          </v-simple-table>
    </div>
  </div>
</template>

<script>
import VenueSdk from "../../sdks/venueSdk";
import FloorEdit from "./floorEdit";

export default {
  components: {
    FloorEdit
  },
  data() {
    return {
      // venue: null
    };
  },
  mounted() {
    
    // this.venue = this.$store.getters['venues/getVenue'](this.$route.params.venueId);
  },
  computed: {
    venue () {
        return this.$store.getters['venues/getVenue'](this.$route.params.venueId);
    }
  },
  methods: {
    addFloor() {
       this.$router.push({path: `./edit/floor`});
      //this.$store.dispatch('venues/addFloor', this.venue,{ root: true });
    }
  }
};
</script>

<style>

.cell-narrow {
  max-width: 50px;
}
</style>