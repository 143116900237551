<template>
  <div class="padding-body">
    <div class="table-list">
      <div class="inner">
        <div class="list-box">
            <template>
              <v-data-table :headers="GET_LIST_FLOORS.headers" :items="GET_LIST_FLOORS.values" :items-per-page="15">
                  <template v-slot:item.action="{item}">
                      <v-btn class="mr-1" @click="editFloor(item)"><v-icon small title="Edit">mdi-pencil-outline</v-icon></v-btn>
                      <v-btn class="mr-1" @click="deleteFloor(item)"><v-icon small title="Delete">mdi-trash-can-outline</v-icon></v-btn>
                  </template>
              </v-data-table>
            </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" @click="addFloor()">Add a floor</v-btn>
        </div>
        <venue-floors v-model="GET_MODAL_STATUS.venue_floors"></venue-floors>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueFloors from './manage-floor'

export default {
  components: { VenueFloors },
  data() {
    return {};
  },
  computed:{
      ...mapGetters(['GET_LIST_FLOORS', 'GET_MODAL_STATUS'])
  },
  methods: {
    addFloor(){
      this.$store.commit('SET_FLOOR_CREATE', true)   
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_floors", value: true })
    },
    editFloor(){
      this.$store.commit('SET_FLOOR_CREATE', false)
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_floors", value: true })
    },
    deleteFloor(){

    }
  },
  mounted() {
    this.$store.dispatch('CREATE_VENUE_FLOOR_LIST').then((response) => {
      this.$store.commit('SET_FLOORMAP_READY', true)
    })
  },
};
</script>