import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

export default {
    state: {
        counters: []
    },
    getters : {
        GET_DOOR_COUNTERS: state => {
            return state.counters
        },
    },
    actions: {
        CREATE_LIST_DOOR_COUNTERS: async (context) => {
            let userID = context.rootGetters.GET_USER_ID
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let devices = await fetchGetRQ(`client/${userID}/venue/${venueID}/devicelocation/doors`)

            if(devices.valid){
                context.commit('SET_DOOR_COUNTERS', devices.message)
            }      
        },
    },
    mutations: {
        SET_DOOR_COUNTERS: (state, value) => {
            state.counters = value
        },
    }
}