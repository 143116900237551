<template>
  <div>
    <v-dialog v-model="value" width="400" @keydown.esc="close()" @keydown.enter="close()">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Select Client</v-card-title>
        <div style="margin:10px">
          <date-picker v-on:date-update="onDateChange"></date-picker>
          <!-- <v-form>
                    <v-select
          class="align-center fix-width"
            :items="clients"
            item-text="name"
            hide-details
            prepend-icon="mdi-domain"
            v-model="client"
            v-on:change="set"
            item-value="id"
          ></v-select>
          </v-form>-->
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="value = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from "./datePicker";
export default {
  components: { DatePicker },
  props: {
    value: Boolean
  },
  methods: {
      onDateChange(startDate) {
          this.$emit("submit",{
              startDate: startDate
          });
          this.$emit('input',false);
      },
      close() {
          this.value = false;
      }
  }
};
</script>

<style>
</style>