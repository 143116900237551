import Vue from 'vue'
import Amplify from 'aws-amplify';
import { AmplifyEventBus } from "aws-amplify-vue";
import axios from 'axios'
import router from "../router";


class AuthInterceptor {

    
    constructor() {
        this.accessToken = '';
        this.listener = undefined;

        axios.interceptors.request.use(this.authInterceptor)

        Amplify.Auth.currentAuthenticatedUser().then( user => 
           { this.accessToken= user.signInUserSession.idToken.jwtToken; }
          )
          .catch(_ => this.accessToken = '');
    
        AmplifyEventBus.$on("authState", eventInfo => {

          if(this.listener) {
            this.listener(eventInfo);
          }

          if (eventInfo === "signedIn" || eventInfo === "signIn") {
             Amplify.Auth.currentAuthenticatedUser()
              .then(user => {
                  this.accessToken= user.signInUserSession.idToken.jwtToken;
              })
            
          } else if (eventInfo === "signedOut") {
            this.accessToken = '';
          }
        });
    }

    authInterceptor = (config) => {
        if (this.accessToken && this.accessToken !== '') {
            config.headers['Authorization'] = `Bearer ${ this.accessToken }`;
          } else {
              router.push({ path: `/` });
          }
      
          return config;
    }
    
}

var interceptor = new AuthInterceptor();

export default interceptor;