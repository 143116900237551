<template>
  <div class="inner_body padding">
    <v-btn color="primary" @click="inviteOpen = true">Invite User</v-btn>
    <v-data-table :headers="headers" :items="users" :items-per-page="5" class="users-list"> 
    <template v-slot:item.actions="{ item }">
        <v-btn class="mr-1" @click="editUser(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
        <v-btn class="mr-1" @click="deleteUser(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
    </template>
    </v-data-table>
    <invite-modal v-model="inviteOpen" :mode="mode" :scopeId="scopeId"></invite-modal>
    <edit-user-modal v-model="editUserBox"></edit-user-modal>
  </div>
</template>

<script>
import UserSdk from "../../sdks/userSdk";
import InviteModal from "./inviteModal";
import EditUserModal from "./editUserModal"

export default {
  components: { InviteModal, EditUserModal },
  mounted() {
    if (this.$route.params.partnerId !== undefined) {
      this.mode = 1;
      this.scopeId = this.$route.params.partnerId;
    }

    if (this.$route.params.clientId !== undefined) {
      this.mode = 2;
      this.scopeId = this.$route.params.clientId;
    }

    this.load();
  },
  data() {
    return {  
      inviteOpen: false,
      editUserBox: false,
      mode: "Admin",
      scopeId: undefined,
      users: [],
      headers:[
        { text: 'Name', align: 'start', value: 'firstName', sortable: true},
        { text: 'Surname', value: 'lastName', sortable: true},
        { text: 'Email', value: 'email', sortable: true},
        { text: 'Action', value: 'actions', sortable: false}
      ]
    };
  },
  methods: {
    load: function() {
      UserSdk.getUsers(this.mode,this.scopeId).then(response => {
        this.users = response.data;
      });
    },
    deleteUser: function(user){
      UserSdk.delete(user.id)
      this.$emit("reload")
    },
    editUser: function(user){
      this.editUserBox = !this.editUserBox

    }
  }
};
</script>

<style>
</style>