<template>
  <div class="data-box doors">
      <venue-selection-box v-if="!IS_VENUE_SELECTED"></venue-selection-box>
      <div class="data-box-container" v-else>
        <div class="filter-no-data" v-if="GET_DOOR_COUNTERS.length == 0">No door counters available for this venue</div>
        <div class="filter-data-on" v-else>
          <div class="filter-data-container">
            <div class="filter-box left">
              <v-select :items="GET_DOOR_COUNTERS" label="Select a door counter" v-model="selectedDoor" item-text="name" item-value="locationID"></v-select>
            </div>
            <div class="filter-box left">
              <date-filter v-on:date-change="handleDate"></date-filter>
            </div>
            <div class="filter-box left">
              <res-filter v-model="label" v-on:set-res="setRes"></res-filter>
            </div>
          </div>
          <div class="filtered-data">
            <door-container :filter="makeFilter()"></door-container>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex'
import VenueSelectionBox from '../shared/venue-selection-box'


import DoorLocService from "../devices/deviceLocationApiService";
import DoorContainer from "./doorContainer";
import ResFilter from "../shared/resolutionFilter";
import DateFilter from "../shared/dateFilter";

export default {
  components: { VenueSelectionBox, DoorContainer, ResFilter, DateFilter },
  data() {
    return {
      selectedDoor: null,
      date: {
        start: null,
        end: null
      },
      mode: 1,
      toggle: 2,
      label: "Hour"
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'IS_VENUE_SELECTED', 'GET_DOOR_COUNTERS'])
  },
  methods: {
    setRes(resolution) {
      this.toggle = resolution;
    },
    handleDate(time) {
      this.date.start = time.start;
      this.date.end = time.end;
      this.label = time.res;
    },
    makeFilter() {
      let _data = {
        startDate: this.date.start,
        endDate: this.date.end,
        period: this.toggle,
        mode: this.mode,
        devLocId: this.selectedDoor
      }
      return _data
    }
  },

  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.$store.dispatch('CREATE_LIST_DOOR_COUNTERS')
    },
    GET_DOOR_COUNTERS(newValue, oldValue){
      this.selectedDoor = newValue[0].locationID
    }
  },
  mounted() {
    if(this.IS_VENUE_SELECTED){
      this.$store.dispatch('CREATE_LIST_DOOR_COUNTERS')
    }
  }
};
</script>
