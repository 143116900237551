<template>
  <div>
      <v-btn color="primary" @click="value=true">Create</v-btn>
      <v-dialog
    @keydown.esc="close()"
    @keydown.enter="close()"
    v-model="value"
    width="400"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Create Zone</v-card-title>

      <v-form>
        <v-text-field v-model="name" label="Name"></v-text-field>
        <v-text-field v-model="capacity" label="Capacity" type="number"></v-text-field>
        
      </v-form>

      

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="create()">
          Ok
        </v-btn>
        <v-btn @click="value = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
data: () => {
    return {
        value: false,
        name: '',
        capacity: 1
    }
},
methods: {
    create() {
        this.$emit('submit', {
          name: this.name,
          capacity: this.capacity
          });
        this.value = false;
    }

}
}
</script>

<style>

</style>