
export default {
    state: {
        map_ready: false
    },
    getters : {
        DMAP_READY: state => {
            return state.map_ready
        }
    },
    actions: {
        INIT_VENUES: async(context) => {         
            await context.dispatch('GET_VENUES', null, { root: true })
            await context.dispatch('CREATE_MARKERS', null, { root: true })
            await context.dispatch('CREATE_JUMPER', null, {root: true})
        },
    },
    mutations: {
        SET_MAP_READY: (state, value) => {
            state.map_ready = value
        }
    }
}