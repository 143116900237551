<template>
  <div>

    <h1>Floor No: {{this.$route.params.floorNo}}</h1>

    <v-card class="mb-800">
      <div>
        <img :src="selectImage" />
      </div>
      <div>      
        <v-btn color="primary" @click="returnTo3D()">Return to 3D View</v-btn>
      </div>      
    </v-card>
  </div>
</template>

<script>
import VenueService from "../../sdks/venueService";


export default {
  components: {
  },
  data() {
    return {
      selectImage: VenueService.currentVenue.floors[this.$route.params.floorNo].floorPlanUrl
    };
  },
  mounted() {
    //this.venue = VenueService.currentVenue;
    //this.planUrl = this.venue.floors[this.$route.params.floorNo].floorPlanUrl;
  },
  
  
  methods: {
    returnTo3D: function() {
      this.$router.push({path: `../../threeD`});
    }

  }
};
</script>

<style lang="scss">
.counter-report-container {
  height: 75vh;
}

.counterfilter-header-bar {
  display: flex;
  justify-content: space-around;
  max-width: 1100px;
}

.filter-container {
  max-width: 200px;

  .col-12 {
    padding: 0px;
  }
}

.filter-title {
}

.filter-input {
}
</style>
