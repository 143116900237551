<template>
  <v-dialog v-model="value" width="400" @keydown.esc="close()" @keydown.enter="close()">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Assign Device</v-card-title>
      <div style="margin:10px">
        <v-form>
          <v-radio-group v-if="!isPartnerMode" v-model="mode" row>
            <v-radio label="Partner" value="partner"></v-radio>
            <v-radio label="Client" value="client"></v-radio>
          </v-radio-group>
          <v-select
          v-if="mode"
            class="align-center fix-width"
            :items="entities"
            item-text="name"
            hide-details
            prepend-icon="mdi-domain"
            v-model="entity"
            item-value="id"
          ></v-select>
        </v-form>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()">Cancel</v-btn>
        <v-btn raised color="primary" @click="set()">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientSdk from "../../sdks/clientSdk";
import DeviceService from "../../sdks/deviceApiService";

export default {
  props: {
    value: Boolean,
    deviceId: String,
    isPartnerMode: false
  },
  data() {
    return {
      mode: undefined,
      entity: undefined,
      entities: [],
    };
  },
 
  methods: {
    close() {
      this.$emit("input", false);
    },
    set() {
      let mode = undefined;
      switch(this.mode) {
        case 'partner': mode = 1; break;
        case 'client': mode = 2; break;
        default: return;
      }
      DeviceService.assignDevice({
        entityId: this.entity,
        deviceId: this.deviceId,
        mode: mode
      }).then(response => {
        this.entity = undefined;
        this.mode = undefined;
        this.$emit("reload");
        this.close();
      });
    }
  },
  watch: {
    mode() {
      if(this.mode === 'client') {

          ClientSdk.getClients(this.$route.params.partnerId)
          .then(response => (this.entities = response.data));

      } else if(this.mode === 'partner') {
        this.axios.get('partner').then(response => this.entities = response.data)
      } else {
        this.entities = []
      }

    },
     value() {

      if(this.isPartnerMode) {
        this.mode = "client"
      }
      this.$emit("input", this.value);
    }
  }
};
</script>

<style>
</style>