<template>
    <v-dialog v-model="GET_MODAL_STATUS.venue_zone" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <div class="box-header">
                <div class="left">
                    <span class="modal-header" v-if="!GET_ZONE_CREATE">Edit Zone</span>
                    <span class="modal-header" v-else>Add Zone</span>
                </div>
                <div class="right">
                <v-btn icon @click="close()">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                </div>
            </div>
            <div class="box-map" v-if="ZONEMAP_READY">
                <mapview :jumpTo="GET_JUMPER" :geoMap="GET_VENUE_MAP" :venueZones="selectedDimensions" :drawZones="true"></mapview>
            </div>
            <div class="box-device-selection">
                <div class="left five-piece">
                    <v-text-field hide-details="true" v-model="selectedName" outlined label="Name"></v-text-field>
                </div>
                <div class="middle-one five-piece-two">
                    <v-text-field hide-details="true" v-model="selectedDescription" outlined label="Description"></v-text-field>             
                </div>
                <div class="middle-two five-piece-three">
                    <v-text-field type="number" hide-details="true" v-model="selectedCapacity" outlined label="Zone Capacity"></v-text-field>             
                </div>
                <div class="middle-two five-piece-four">
                    <v-select v-model="selectedZoneType" :items="GET_ZONE_TYPES" item-text="text" item-value="id" label="Select zone type" v-on:change="changeZoneType()"></v-select>
                </div>
                <div class="middle-three five-piece-five">
                    <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" :disabled="GET_ZONE_TYPES_VISIBILITY" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
                </div>
                <div class="right five-piece-six">
                    <div class="inner">
                        <v-btn v-if="GET_ZONE_CREATE" color="primary" @click="addZone()">Add zone</v-btn>
                        <v-btn v-if="!GET_ZONE_CREATE" color="primary" @click="updateZone()">Update zone</v-btn>
                        <v-btn @click="close()">Cancel</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import Mapview from '../../shared/mapview'

export default {
    components: { Mapview },
    data() {
        return {
            selectedName: "",
            selectedDescription: "",
            selectedCapacity: "",
            selectedFloor: null,
            selectedZoneType: null,
            selectedDimensions: null
        }
    },
    props: {
        value: {
            default: false,
            type: Boolean
        }
    },
    async mounted() {
        this.selectedFloor = this.GET_SELECTED_FLOOR
    },
    computed:{
        ...mapGetters(['GET_MODAL_STATUS', 'GET_ZONE_CREATE', 'GET_ZONE_TYPES', 'GET_VENUE_FLOORS', 'ZONEMAP_READY', 'GET_JUMPER', 'GET_VENUE_MAP', 'GET_SELECTED_FLOOR', 'GET_ZONE_DIMENSIONS', 'GET_SELECTED_ZONE_DATA', 'GET_ZONE_TYPES_VISIBILITY'])
    },
    watch: {
        value(newValue, oldValue){
            if(!this.GET_ZONE_CREATE){
                this.$store.dispatch('FIND_SELECTED_ZONE')
            }else{
                this.clearForm();
            }
        }, 
        GET_SELECTED_ZONE_DATA(newValue, oldValue){
            let _data = newValue;
            this.selectedName = _data.name
            this.selectedDescription = _data.description
            this.selectedCapacity = _data.capacity
            this.selectedFloor = _data.floor,
            this.selectedZoneType = _data.type
            this.selectedDimensions = _data.dimensions
            this.changeZoneType()
        }
    },
    methods: {
        changeFloor(){
            this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
            this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor) 
        },
        addZone(){
            let _data = { name: this.selectedName, description: this.selectedDescription, floor: this.selectedFloor, type: this.selectedZoneType, capacity: parseInt(this.selectedCapacity) }
            this.$store.dispatch('CREATE_VENUE_ZONE', _data)
        },
        updateZone(){
            let _data = { name: this.selectedName, description: this.selectedDescription, floor: this.selectedFloor, type: this.selectedZoneType, capacity: parseInt(this.selectedCapacity) }
            this.$store.dispatch('EDIT_VENUE_ZONE', _data)
        },
        changeZoneType(){
            this.$store.dispatch('VALIDATE_ZONE_TYPE', this.selectedZoneType) 
        },
        clearForm(){
            this.selectedName = ""
            this.selectedDescription = ""
            this.selectedFloor = this.GET_SELECTED_FLOOR,
            this.selectedZoneType = ""
            this.selectedDimensions = {}
        },
        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "venue_zone", value: false}) 
        },
    }
}

</script>