import DataResponse from '../entities/response.js'

export default async function (url, data) {

    let _response = new DataResponse(false, "Uknown error", 500).init()
    const host = process.env.VUE_APP_API
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    try {
        const fetchResponse = await fetch(host + "/" + url, settings);
        const data = await fetchResponse.json();
        _response = { valid: true, code: 401, message: data }
    } catch (error) {
        _response = { valid: false, code: 501, message: error.message }
    }  

    return _response
    
}