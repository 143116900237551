<template>
  <v-dialog v-model="GET_MODAL_STATUS.locations" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
    <div class="dialog-box-inner sp-padding">
      <div class="box-map full" v-if="DEVICEMAP_READY">
        <div class="filter-area map-box">
          <div class="map-box-inner">
            <div class="filter-floor left">
              <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
            </div>
            <div class="filter-close left">
              <v-btn class="primary" @click="close()">Close</v-btn>
            </div>
          </div>
          <div class="map-legend">
            <div class="legend-inner">
              <ul class="legend">
                <li v-for="item in GET_SLOT_TYPES" :key="item.type">
                  <div class="slot-box">
                    <div class="slot-colour" :style="{'background-color':item.color}"></div>
                    <div class="slot-name">{{item.text}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <mapview :jumpTo="GET_JUMPER" :geoMap="GET_VENUE_MAP" :devices="GET_LOCATIONS_MARKERS" :isSelector="false" :useRuler="true"></mapview>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from '../../shared/mapview'

export default {
  components: { Mapview },
  data() {
    return {
      selectedFloor: null,
      selectedMarker: [],
    };
  },
  methods: {
    close() {
      this.$store.commit('SET_MODAL_STATUS', { target: "locations", value: false})      
    },
    changeFloor(){
      this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
      this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor) 
      this.$store.dispatch('CREATE_LOCATIONS_MARKERS')
    }
  },
  async mounted() {
    this.selectedFloor = this.GET_SELECTED_FLOOR
  },
  computed:{
      ...mapGetters(['DEVICEMAP_READY', 'GET_JUMPER', 'GET_VENUE_MAP', 'GET_VENUE_FLOORS', 'GET_SELECTED_FLOOR', 'GET_LOCATIONS_MARKERS', 'GET_MODAL_STATUS', 'GET_SLOT_TYPES'])
  },
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  watch: {}
};
</script>