<template>
  <div class="view-map tour">
    <div class="info-box" v-if="!IS_VENUE_SELECTED">
      <div class="notification">
        <div class="inner"><v-icon dark>mdi-cancel</v-icon> To view the data, please select a venue</div>
      </div>
    </div>
    <div class="add-tour" v-if="!tourExists">
      <v-btn color="primary" @click="createModal=true">Add a tour</v-btn>
    </div>
    <div class="tour-inner" v-if="tourLoaded">
      <iframe id="iframe" :src="tour.url" scrolling="no" allowfullscreen></iframe>
    </div>
    <create-tour-modal v-model="createModal" :clientId="getClientId()" :venueId="getVenueId()" v-on:reload="reload"></create-tour-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueService from "../../sdks/venueService";
import tourService from "./tourApiService";
import CreateTourModal from "./createTourModal";

export default {
  name: "Tour",
  components: { CreateTourModal },
  data: () => ({
    tour: { url: ""},
    venueService: VenueService,
    createModal: false,
    tourLoaded: false,
    tourExists: true
  }),
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'IS_VENUE_SELECTED'])
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.reload();
    }
  },
  mounted() {
    if (this.IS_VENUE_SELECTED) {
      this.reload();
    }
  },
  methods: {
    getClientId() {
      return this.$route.params.clientId;
    },
    getVenueId() {
      return this.CURRENT_VENUE.id;
    },
    reload: function() {
      tourService
        .getCustomerTour(this.$route.params.clientId, this.CURRENT_VENUE.id, 3)
        .then(t => {
          this.tour = t.data === "" ? undefined : t.data;
          this.tourExists = !(t.data === "");
          this.tourLoaded = !(t.data === "");
        })
        .catch(e => console.error(e));
    }
  }
};
</script>

<style>
#iframe {
  height: 100%;
  min-height: 450px;
  width: 100%;
}
</style>