<template>
<div style="height:200px">
    <v-simple-table>

      <thead>
        <tr>
          <th class="text-left">Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tabledata.labels" :key="item">
          <td>{{ item }}</td>
        </tr>
      </tbody>

  </v-simple-table>
</div>
</template>

<script>
export default {
    props: {
        tabledata: Object
    }

}
</script>

<style>

</style>