<template>
  <div>
    <v-row>
      <v-col cols="3">
        <b>Item</b>
      </v-col>
      <v-col cols="2">
        <b>Quantity</b>
      </v-col>
      <v-col cols="2">
        <b>Cost</b>
      </v-col>
    </v-row>
    <div v-for="item in items"  :key="item.name">
      <v-row v-if="item.quantity > 0">
        <v-col cols="3">
          <p>{{item.name}}</p>
        </v-col>
        <v-col cols="2">
          <p>{{item.quantity}}</p>
        </v-col>
        <v-col cols="2">
          <p>£ {{item.total}}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      default: []
    }
  }
};
</script>

<style>
</style>