<template>
  <v-container>
    <v-row justify="center">
      <v-col style="width:100%">
        <div class="venue-selection-map">
          <mapbox v-on:map-clicked="mapClicked" :zoom.sync="zoom" :locations="locations" :flylocation="coordinates"></mapbox>
        </div>        
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">Venue Details</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">Venue Map Location</v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="field-box third"><v-text-field v-model="name" label="Venue name" placeholder="Type the venue name"></v-text-field></div>
              <v-btn color="primary" @click="e1 = 2">Next</v-btn>
              <v-btn @click="cancel()" text>Cancel</v-btn>
            </v-stepper-content>

            <v-stepper-content step="2" style="width:100%">
                <div class="venue-selection-coordinates">
                    <div class="field-box third"><v-text-field v-model="latitude" label="Latitude" placeholder="Type the latitude"></v-text-field></div>
                    <div class="field-box third"><v-text-field v-model="langitude" label="Langitude" placeholder="Type the langitude"></v-text-field></div>
                    <div class="field-box third"><v-btn class="bnt-full" @click="findPlace()">Find</v-btn></div>                   
                </div>
                <div class="venue-selection-btns">
                  <v-btn color="primary" @click="createVenueCall()">Create</v-btn>
                  <v-btn @click="cancel()" text>Cancel</v-btn>
                </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateBasicStep from "./createBasicStep";
import PlacementStep from "./createPlacementStep";
import Mapbox from "../../shared/mapbox";
import VenueSdk from "../../../sdks/venueSdk";
import VenueService from "../../../sdks/venueService";

export default {
  components: {
    CreateBasicStep,
    Mapbox,
    PlacementStep
  },
  data() {
    return {
      e1: 1,
      zoom: 5,
      name: "",
      locations: [],
      lat: 0,
      lng: 0,
      coordinates: {"lat": "0", "lng": "0"},
      latitude: "",
      langitude: ""
    };
  },
  methods: {
    cancel: function() {
      this.$router.push({ name: "venueList" });
    },
    mapClicked: function(clickCoords) {
      this.lat = clickCoords.lat;
      this.lng = clickCoords.lng;
      this.locations = [[clickCoords.lng, clickCoords.lat]];
    },
    createVenueCall() {
      VenueSdk.postVenue( {
          name: this.name,
          clientId: this.$route.params.clientId,
          initialZoom: this.zoom,
          lat: this.lat,
          lng: this.lng
      }, this.$route.params.clientId)
      .then( request => {
              VenueSdk
                .getVenues(this.$route.params.clientId)
                .then(venues => VenueService.setVenues(venues.data));
        this.$router.push({ name: "venueList" });
      });
    },
    findPlace(){
      this.coordinates = {"lat": this.latitude.trim(), "lng": this.langitude.trim() } 
    }
  }
};
</script>