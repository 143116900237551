<template>
  <v-row>
    <v-col cols="12">
          <v-select v-model="internalValue" :items="dropdown_icon" item-text="text" label="Resolution"></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      internalValue: "Hour",
      dropdown_icon: ["Day","Hour","Half Hour"]
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
    internalValue() {
      let resVal;
      switch(this.internalValue) {
        case "Day": resVal = 3; break;
        case "Hour": resVal = 2; break;
        case "Half Hour": resVal = 1; break;
        default: console.error("Unrecognised resolution string"); return;
      }
      this.$emit("set-res", resVal);
    }
  }
};
</script>

<style>
</style>