
const floor_headers = [
    { id: 'index', text: 'Index', sortable: true, order: 1 },
    { id: 'height', text: 'Height', sortable: true, order: 2 },
    { id: 'width', text: 'Width', sortable: true, order: 3 },
    { id: 'depth', text: 'Depth', sortable: true, order: 4 },
    { id: 'ismap', text: 'Map uploaded', sortable: true, order: 5 },
    { id: 'created', text: 'Created date', sortable: true, order: 5 },
    { id: 'action', text: 'Action', sortable: false, order: 6, align: 'right'},
]

export default {
    state: {
        is_floor_create: true,
        is_floormap_ready: false,
        floors_list: { headers: [], values: []}
    },
    getters:{
        GET_LIST_FLOORS: state => {
            return state.floors_list;
        }
    },
    actions: {
        CREATE_VENUE_FLOOR_LIST: async (context, venueId) => {

            let venues = context.rootGetters.VENUE_LIST
            let floors = venues != null ? venues[0].floors : null //await Vue.axios.get(`client/${venueId}/venue`);

            let _floors = { values: [], headers: []}

            if(floors != null && floors.length > 0) {
                
                floors.forEach(floor =>{
                    _floors.values.push({ index: floor.floorIndex, width: floor.floorWidthM, height: floor.floorHeightM, depth: floor.floorDepthM, ismap: floor.geoJsonUrl != null ? "Yes" : "No"  })
                })
            }

            _floors.headers = await context.dispatch('CREATE_VENUE_FLOOR_LIST_HEADERS', null, { root: true })

            context.commit('SET_LIST_FLOORS', _floors)

        },
        CREATE_VENUE_FLOOR_LIST_HEADERS: async (context) => {

            let _headers = []

            floor_headers.forEach(header =>{
                _headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return _headers;
        }
    },
    mutations: {
        SET_FLOOR_CREATE: (state, value) => {
            state.is_floor_create = value
        },
        SET_FLOORMAP_READY: (state, value) => {
            state.is_floormap_ready = value
        },
        SET_LIST_FLOORS: (state, value) => {
            state.floors_list = value
        },
        SET_FLOORMAP_READY: (state, value) => {
            state.is_floormap_ready = value
        }
    }
}