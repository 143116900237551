export default {
    state: {
        loader_status: { wirelessHeat: false, trackingHeat: false }
    },
    getters:{
        GET_LOADER_STATUS: state => {
            return state.loader_status
        },
    },
    mutations: {
        SET_LOADER_STATUS: (state, value) => {
            state.loader_status[value.target] = value.value
        },
    }
}