import Amplify from 'aws-amplify';


export default {
    state: {
        is_user: {}
    },
    getters : {
    },
    actions: {
        LOGOUT: async(context) => {
            await Amplify.Auth.signOut().then().catch((error) => {
                console.log('Cannot sign out the user. Error: ', error)
            })
        },
    },
    mutations: {
    }
}