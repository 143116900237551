<template>
  <div>
    <p v-if="error">Error: Contact Support</p>
    <heatmap-container v-if="report!=null" :report="report" :venue="CURRENT_VENUE.id"></heatmap-container>
    <v-progress-linear v-if="loading" indeterminate color="yellow darken-2"></v-progress-linear>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeatmapContainer from "../counting/heatmapContainer";
import VenueService from "../../sdks/venueService";

export default {
  components: { HeatmapContainer },
  props: {
    filter: Object
  },
  data() {
    return {
      report: null,
      venueService: VenueService,
      error: false,
      loading: false
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE'])
  },
  mounted() {
    this.loadData();
  },
  watch: {
    filter() {
      this.loadData();
    }
  },
  methods: {
    loadData() {

      this.error = false;
      this.loading = true;

      const request = {
        venueId: this.CURRENT_VENUE.id,
        start: this.filter.startDate,
        end: this.filter.endDate,
        resolution: this.filter.period,
        format: 2,
        AsPercentOfMax: false
      };

      this.axios
        .post("door/report/venue", request)
        .then(response => {
          console.log(response.data)
          this.report = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.error = true;
          this.loading = false;
          this.report = null;
        });
    }
  }
};
</script>

<style>
</style>