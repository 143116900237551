import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

export default {
    state: {
        venueList: [],
        currentVenue: {},
        venue_markers: { list: [], colour: "#1976d2" },
        marker: [],
        jumper: {},
        is_venue_selected: false,
        venuemap: {},
        venuefloors: {},
        selected_floor: 1
    },
    getters : {
        IS_VENUE_SELECTED: state => {
            return state.is_venue_selected
        },
        VENUE_LIST: state => {
            return state.venueList
        },
        CURRENT_VENUE: state => {
            return state.currentVenue
        },
        VENUE_MARKERS: state => {
            return state.venue_markers
        },
        GET_MARKER: state => {
            return state.marker
        },
        GET_JUMPER: state => {
            return state.jumper
        },
        GET_VENUE_MAP: state => {
            return state.venuemap
        },
        GET_VENUE_FLOORS: state => {
            return state.venuefloors
        },
        GET_SELECTED_FLOOR: state => {
            return state.selected_floor
        }
    },
    actions: {
        GET_VENUES: async (context) => {
            let userID = context.rootGetters.GET_USER_ID
            let venues = await fetchGetRQ(`client/${userID}/venue`)
            context.commit('SET_VENUES', venues.message)
        },
        CHANGE_VENUE: async (context, _id) => {
            let venue = context.state.venueList.find(x => x.id == _id)
            context.commit('SET_CURRENT_VENUE', venue)
            context.commit('SET_SELECTED_VENUE', true)
        },
        CREATE_MARKERS: async (context) => {
            let i = 0
            let markers = []
            let venues = context.state.venueList;
            venues.forEach(venue => {
                markers.push({ id: i, value: venue.location.coordinates})
                i++
            });
            context.commit('SET_MARKERS', { list: markers, colour: "#1976d2" })
        },
        GET_MARKER: async (context, _id) => {
            let venues = context.state.venueList;
            let marker = venues.find(x => x.id == _id)
            context.commit('SET_MARKER', marker)
        },
        CHANGE_JUMPER: async (context, zoom) => {
            let jumper = { coordinates: context.state.currentVenue.location.coordinates, zoom: zoom }
            context.commit('SET_JUMPER', jumper)
        },
        CREATE_JUMPER: async (context, zoom) => {

            let _jumper = {}

            if(context.state.currentVenue.location == undefined){
                let venues = context.state.venueList
                _jumper = { coordinates: venues[0].location.coordinates, zoom: zoom == null ? 5 : zoom }
            }else{
                _jumper = { coordinates: context.state.currentVenue.location.coordinates, zoom: zoom }
            }

            context.commit('SET_JUMPER', _jumper)
        },
        FIND_VENUE_MAP: async (context, floor) => {

            if(context.state.is_venue_selected){
                let currentVenue = context.state.currentVenue
                let currentFloor = floor == null ? context.state.selected_floor : floor
                let geoMap = currentVenue.floors.find(x => x.floorIndex == currentFloor + 1)

                if(geoMap != undefined){
                    let rq = { geoUrl: geoMap.geoJsonUrl }
                    let map = await fetchRQ('location/getgeojson', rq)
                    let mapResponse = JSON.parse(JSON.stringify(map.message))
        
                    if(mapResponse.isValid){
                        let features = { ... JSON.parse(mapResponse.message), id: "FloorMap"}
                        let geoJson = { type: "geojson", data: features }
                        context.commit('SET_VENUE_MAP', geoJson)
                    }
                }
    
            }

        },
        FIND_VENUE_FLOORS: async (context) => {

            if(context.state.is_venue_selected){

                let floors = []
                let currentVenue = context.state.currentVenue

                currentVenue.floors.forEach(floor => {
                    floors.push({ name: floor.floorIndex + " floor", value: { id: floor.floorIndex - 1, geoJson: floor.geoJsonUrl } })
                });

                context.commit('SET_VENUE_FLOORS', floors)
            }
        },
        RESET_SELECTED_VENUE: async (context) => {
            context.commit('SET_CURRENT_VENUE', {})
            context.commit('SET_SELECTED_VENUE', false)
        }
    },
    mutations: {
        SET_VENUES: (state, value) => {
            state.venueList = value
        },
        SET_CURRENT_VENUE: (state, value) => {
            state.currentVenue = value
        },
        SET_MARKERS: (state, value) => {
            state.venue_markers = value
        },
        SET_MARKER: (state, value) => {
            state.marker = value
        },
        SET_JUMPER: (state, value) => {
            state.jumper = value
        },
        SET_SELECTED_VENUE: (state, value) => {
            state.is_venue_selected = value
        },
        SET_VENUE_MAP: (state, value) => {
            state.venuemap = value
        },
        SET_VENUE_FLOORS: (state, value) => {
            state.venuefloors = value
        },
        SET_SELECTED_FLOOR: (state, value) => {
            state.selected_floor = value
        }
    }
}