<template>
  <div class="inner_body">
    <client-bar></client-bar>
      <v-content>
        <v-container>
          <router-view></router-view>
        </v-container>
      </v-content>
    </div>
</template>

<script>
import ClientBar from "./clientBar";
import VenueSdk from "./../sdks/venueSdk";
import VenueService from "./../sdks/venueService";

export default {
  components: { ClientBar },
  data: () => ({
    venues: []
  }),
  methods: {},
  created(){
    this.$store.commit("CHANGE_USER_ID", this.$route.params.clientId) 
  }
};
</script>

<style>
</style>