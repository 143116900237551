import Vue from "vue";
import Router from "vue-router";
import Amplify from "aws-amplify"
import ClientDashboard from "./client/dashboard"
import Login from "./shared/login"
import ClientList from "./admin/clients/clientList"
import AdminBase from "./admin/adminBase"
import ClientBase from "./client/clientBase"
import PartnerBase from './partner/partnerBase'
import VenueList from './client/venues/venueList'
import CreateVenue from './client/venues/create/createVenue'
import Tour from './client/tour/tour'
import CustomerTour from './client/customer-tour/tour'
import AdminDeviceList from './admin/devices/deviceList'
import CounterView from './client/counting/counterView'
import DoorView from './client/doors/doorView'
import AppView from './client/apps/appView'
import RealtimeView from './client/realtime/realtimeView'
import HeatmapView from './client/heatmaps/heatmapView'
import TrackView from './client/tracks/trackView'
import ZoneView from './client/zones/zoneView'
import PartnerView from './admin/partner/partnerView'
import UserList from './shared/user/userView'
import Pricing from './shared/pricing'
import VenueEdit from './client/venues/venueEdit'
import threeDView from './client/threeD/threeDView'
import CreateFloor from './client/venues/create/createFloor'
import floorPlan from './client/venues/floorPlan'
import WirelessCounters from './client/wireless-counters/index'

import VenueSettings from './client/venues/venue-settings'
import VenueZones from './client/venues/venue-zones'
import VenueAlerts from './client/venues/venue-alerts'
import VenueFloors from './client/venues/settings/floors'
import VenueDevices from './client/venues/venue-devices'
import TrackingAlerts from './client/venues/alerts/list'
import WirelessInsights from './client/wireless-counters/insights'

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [ 
    {
      path: "/",
      name: "login",
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: "/admin",
      name: "adminBase",
      meta: { requiresAuth: true },
      component: AdminBase,
      children: [     
        {
          path: 'clients',
          component: ClientList
        },
        {
          path: 'users',
          component: UserList
        },
        {
          path: 'devices',
          component: AdminDeviceList
        },
        {
          path: 'partners',
          component: PartnerView
        }
      ]
    },
    {
      path: "/partner/:partnerId",
      name: "partnerArea",
      meta: { requiresAuth: true },
      component: PartnerBase,
      children: [
        {
          path: 'pricing-calc',
          component: Pricing,
        },
        {
          path: 'clients',
          component: ClientList
        },
        {
          path: 'users',
          component: UserList
        },
        {
          path: 'devices',
          component: AdminDeviceList
        },
      ]
    },
    {
      path: "/client/:clientId",
      name: "clientArea",
      meta: { requiresAuth: true },
      component: ClientBase,
      children: [
        {
          name: 'clientDashboard',
          path: 'dashboard',
          component: ClientDashboard,
        },
        {
          name: 'clientUserList',
          path: 'users',
          component: UserList
        },
        {
          name: 'tour',
          path: 'tour',
          component: Tour
        },
        {
          name: 'customer-tour',
          path: 'customer-tour',
          component: CustomerTour
        },
        {
          name: 'zone',
          path: 'zones',
          component: ZoneView
        },
        {
          name: 'WirelessCounters',
          path: 'wireless-counters',
          component: WirelessCounters
        },
        {
          name: 'counter',
          path: 'counter',
          component: CounterView
        },
        {
          name: 'doors',
          path: 'doors',
          component: DoorView
        },
        {
          name: 'tracks',
          path: 'tracks',
          component: TrackView
        },
        {
          name: 'apps',
          path: 'apps',
          component: AppView
        },
        {
          name: 'realtime',
          path: 'realtime',
          component: RealtimeView
        },
        {
          name: 'heatmap',
          path: 'heatmap',
          component: HeatmapView
        },
        {
          name: 'threeD',
          path: 'threeD',
          component: threeDView
        },
        {
          name: 'createFloor',
          path: 'venue/:venueId/edit/floor',
          component: CreateFloor
        },
        {
          name: 'floorPlan',
          path: 'threeD/:floorNo/plan',
          component: floorPlan
        },
        {
          name: 'Venue Settings',
          path: 'venues/list',
          component: VenueSettings
        },
        {
          name: 'Venue Floors',
          path: 'venues/:id/floors',
          component: VenueFloors
        },
        { 
          name: 'Venues Zones',
          path: 'venues/zones',
          component: VenueZones,
        },
        { 
          name: 'Venue Zones',
          path: 'venues/:id/zones',
          component: VenueZones,
        },
        { 
          name: 'Venues Alerts',
          path: 'venues/alerts',
          component: VenueAlerts,
        },
        { 
          name: 'Venue Alerts',
          path: 'venues/:id/alerts',
          component: VenueAlerts,
        },
        {
          name: 'Devices',
          path: 'venues/devices',
          component: VenueDevices,
        },
        {
          name: 'Tracking Alerts',
          path: 'alerts',
          component: TrackingAlerts
        },
        {
          name: 'Wireless Insights',
          path: 'wireless-insights',
          component: WirelessInsights
        },
        {
          name: 'VenueEdit',
          path: 'venue/:venueId/edit',
          component: VenueEdit
        },
        {
          name: 'venueList',
          path: 'venue',
          component: VenueList
        },
        {
          name: 'createVenue',
          path: 'venue/create',
          component: CreateVenue
        },
      ]
    },
  ]
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    Amplify.Auth.currentAuthenticatedUser()
      .then(user => {
        next();
      })
      .catch(error => {
        next({
          path: "/",
          query: {
            redirect: to.fullPath
          }
        });
      });

  }
  next();
});

export default router;