import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      title: {
        text: "hows flip",
        display: false
      }
    },
    // height: {
    //   type: Number,
    //   default: 400
    // },
    width: {
      type: Number,
      default: 600
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.rerender();
      }
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.rerender();
  },
  methods: {
    rerender() {
      this.renderChart(this.chartData, this.options)
    }
  }
 
}