<template>
  <div class="padding-body">
    <v-tabs v-model="selectedTab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :key="0">Devices</v-tab>
      <v-tab :key="1">Device Locations</v-tab> 
      <v-tab-item :key="0">
        <v-card flat tile>
          <device-list></device-list>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-card flat tile>
            <device-locations></device-locations>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import DeviceList from '../venues/devices/list'
import DeviceLocations from '../venues/devices/locations'

export default {
    components: { DeviceList, DeviceLocations },
    data() {
      return {
        selectedTab: 0,
      };
    },
};
</script>