<template>
  <div class="report-container">
    <div class="report-container-inner">
      <div class="chart-container">
        <line-chart v-if="report!=null" :chart-data.sync="report" :options.sync="options"></line-chart>
      </div>
    </div>
    <v-progress-linear v-if="loading" indeterminate color="yellow darken-2"></v-progress-linear>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from "../counting/LineGraph";

export default {
  components: { LineChart },
  data() {
    return {
      loading: false,
      report: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: `Locations`,
          display: false
        }
      }
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE'])
  },
  props: {
    filter: Object
  },
  mounted() {
    this.loadData();
  },
  watch: {
    filter() {
      if(this.filter.devLocId === '') {
        this.report = null;
      } else {
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios
        .post("door/report/single", this.filter)
        .then(response => {

          console.log(response.data)

          this.report = response.data;
          this.loading = false;
          })
        .catch(error => {
          this.report = null;
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.report-container {
  margin-top: 10px;
  height: 100%;
}

.report-container-inner {
  margin: 10px;
}

.chart-container {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}
</style>
