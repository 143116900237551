import Vue from 'vue'
import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request'

const excludeColumns = ['cameraUrl', 'clientId', 'in', 'out', 'partitionKey', 'partnerId', 'table', 'url', 'venueId', 'deviceLocation', 'type', 'assigned'];
const columnNames = [
    { id: 'decowaveId', text: 'Decowave ID', sortable: true, order: 1 },
    { id: 'name', text: 'Name', sortable: true, order: 2 },
    { id: 'device_type', text: 'Device type', sortable: true, order: 3 },
    { id: 'allocated', text: 'Allocated', sortable: true, order: 4 },
    { id: 'status', text: 'Active', sortable: true, order: 5},
    { id: 'createdDate', text: 'Date Created', sortable: true, order: 6 },
    { id: 'action', text: 'Action', sortable: false, order: 7 },
]

const slotTypes = [
    { type: 0, text: 'Not defined', color: "#e7e7e7"},
    { type: 1, text: 'Wireless Gateway', color: "#844000" },
    { type: 8, text: 'Wireless Sniffer', color: "#83ce00" },
    { type: 2, text: 'Doorway Counter', color: "#ffde50" },
    { type: 3, text: 'BriteZone Beacon', color: "#1ca5ff" },
    { type: 4, text: 'Hand Braclet', color: "#ff1c1c" },
    { type: 5, text: 'Repeater', color: "#ffab00" },
    { type: 6, text: 'Origin Beacon', color: "#00FFFF" },
    { type: 7, text: 'X Axis Point', color: "#9362fb" }
]

export default {
    state: {
        all_devices: { headers: [], values: [] },
        partner_id: null,
        partner_mode: { value: false, name: "System" },
    },
    getters : {
        GET_ALL_DEVICES: state => {
            return state.all_devices
        },
        GET_PARTNER_ID: state => {
            return state.partner_id
        },
        GET_PARTNER_MODE: state => {
            return state.partner_mode
        },
        GET_SLOT_TYPES: state => {
            return slotTypes
        },
    },
    actions: {
        CREATE_ALL_DEVICES_LIST: async (context) => {

            let mode = context.rootGetters.GET_PARTNER_MODE
            let partnerId = context.rootGetters.GET_PARTNER_ID
        
            let url = partnerId != null ? `device?mode=${mode.name}&id=${partnerId}` : `device?mode=${mode.name}`
            let devices = await Vue.axios.get(url);

            if(devices.data && devices.data.length > 0) {

                devices.data.forEach(device => {
                    device['device_type'] = slotTypes.find(x => x.type == device['type']).text
                    device['allocated'] = device['deviceLocation'] !== null ? "Yes" : "No"
                    device['status'] = device['status'] == "InActive" ? "No" : "Yes"
                    device['createdDate'] = new Date(device['createdDate']).toLocaleDateString("en-US")
                    device['assigned'] = device['clientId'] !== null || device['partnerId'] !== null
                });

                context.commit('SET_ALL_DEVICES_VALUES', devices.data)
                context.dispatch('CREATE_ALL_DEVICES_HEADERS')
            }
        },
        DEFINE_PARTNER_MODE: async (context, value) => {
            
            let mode = { value: false, name: "System" }

            if(value !== undefined){
                context.commit('SET_PARTNER_ID', value)
                mode = { value: true, name: "Partner" }
            }
            context.commit('SET_PARTNER_MODE', mode)
        },
        CREATE_ALL_DEVICES_HEADERS: async (context) => {
            
            let headers = []

            columnNames.forEach(column => {
                headers.push({text: column.text, sortable: column.sortable, value: column.id})
            })

            context.commit('SET_ALL_DEVICES_HEADERS', headers)
        },
        UNASSIGN_DEVICES: async (context) => {

        },
        ASSIGN_DEVICE_TO_VENUE: async (context) => {

        },
        DELETE_DEVICE: async (context, device) => {

            let url = `device/delete/${device.id}`
            await Vue.axios.get(url);

            let all_devices = context.rootGetters.GET_ALL_DEVICES
            let filtered = all_devices.values.filter(function(item) {
                return item.id !== device.id
            })
            
            context.commit('SET_ALL_DEVICES_VALUES', filtered)
        }

    },
    mutations: {
        SET_ALL_DEVICES: (state, value) => {
            state.all_devices = value
        },
        SET_ALL_DEVICES_HEADERS: (state, value) => {
            state.all_devices.headers = value
        },
        SET_ALL_DEVICES_VALUES: (state, value) => {
            state.all_devices.values = value
        },
        SET_PARTNER_ID: (state, value) => {
            state.partner_id = value
        },
        SET_PARTNER_MODE: (state, value) => {
            state.partner_mode = value
        }
    }
}
