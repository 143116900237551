<template>
  <div>
    <!-- <v-btn color="Primary" @click="startCreateFlow()">Add Venue</v-btn> -->
    <div class="table-place">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr></tr>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Name</th>
              <!-- <th class="text-left">In</th>
              <th class="text-left">Out</th> -->
              <th class="text-left">Type</th>
              <th class="text-left">Attached</th>
              <!-- <th class="text-left">Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in devices" :key="item.id">
              <td>{{item.id}}</td>
              <td>{{item.name}}</td>
              <!-- <td>{{inName(item)}}</td>
              <td>{{outName(item)}}</td> -->
              <td>{{friendlyType(item)}}</td>
              <td>{{friendlyAssignStr(item)}}</td>
              <td>
                <v-btn v-if="hasUrl(item)" @click="viewCamera(item)">
                  <v-icon>mdi-cctv</v-icon>
                </v-btn>
                <connect-zone-modal
                  v-if="item.type === 2"
                  v-on:selected="zoneSelect"
                  :deviceId="item.id"
                  :buttonIcon="'mdi-link-plus'"
                  :zones="zones"
                ></connect-zone-modal>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <camera-modal v-model="cameraModal" :url="getUrl()"></camera-modal>
    <!-- <AdminInviteModal v-model="inviteOpen"></AdminInviteModal> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientDeviceApiService from "../../devices/clientDeviceApiService";
import CameraModal from "../../devices/cameraModal";
import ConnectZoneModal from "../../zones/zonePickerModal";
import DeviceApiService from "../../../sdks/deviceApiService";

export default {
  components: {
    CameraModal,
    ConnectZoneModal
  },
  data() {
    return {
      cameraModal: false,
      devices: [],
      selectedDevice: null,
      zones: []
    };
  },
  mounted() {
    this.loadDevices();
    this.loadZones();
  },
  computed:{
      ...mapGetters([''])
  },
  methods: {
    loadDevices() {
      DeviceApiService.get("Client",this.$route.params.clientId).then(
        request => (this.devices = request.data)
      );
    },
    zoneSelect(data) {
      this.axios
        .post("device/zone/connect", {
          deviceId: data.deviceId,
          zoneId: data.zoneId,
          zoneName: data.zoneName,
          inOrOutDirection: data.direction
        })
        .then(_ => this.loadDevices());
    },
    loadZones() {
      this.axios
        .get("zone/client/" + this.$route.params.clientId)
        .then(response => {
          this.zones = response.data;
        });
    },
    getUrl() {
      return this.selectedDevice == null ? null : this.selectedDevice.cameraUrl;
    },
    viewCamera(device) {
      this.selectedDevice = device;
      this.cameraModal = true;
    },
    hasUrl(item) {
      if (item.cameraUrl == null) return false;
      if (item.cameraUrl == "") return false;
      return true;
    },
    friendlyAssignStr(device) {
      return device.deviceLocationId == null ? "No" : "Yes";
    },
    friendlyType(device) {
      switch (device.type) {
        case 1:
          return "Wireless Gateway";
          break;
        case 2:
          return "Doorway Counter";
          break;
        case 3:
          return "BriteZone Beacon";
          break;
        case 4:
          return "Hand Braclet";
          break;
        case 5:
          return "Repeater";
          break;
        case 8:
          return "Wireless Sniffer";
          break;
        default:
          return "NOT KNOWN";
      }
    },
    inName: device => (device.in == null ? "" : device.in.name),
    outName: device => (device.out == null ? "" : device.out.name)
  }
};
</script>

<style>
</style>