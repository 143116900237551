<template> 
    <v-app>
    <router-view></router-view>
  </v-app>  
</template>

<script>
import AppBar from './admin/adminBar'

export default {
  name: 'app',
  components: {
    AppBar
  }
}
</script>