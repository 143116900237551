<template>
  <div id="admin-bar">
    <v-app>
      <v-app-bar app color="primary" dark style="height:64px;margin-top:0px;transform:translateY(0px);left:0px;right:0px;">
        <router-link to="/" class="logo-box"><img height="40" src="/img/mini-logo.png" alt="Briteyellow" />
          <span class="logo-title">BriteLocate Platform</span>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-select class="align-center fix-width" :items="VENUE_LIST" item-text="name" item-value="id" hide-details prepend-icon="mdi-domain" v-model="selectedVenue" v-on:change="setVenue"></v-select>
          <v-btn v-if="showBack" icon @click="route()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn icon @click.stop="changeValue(!drawer)">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-btn icon @click.stop="logout()">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <v-navigation-drawer app clipped color="grey lighten-5" class="elevation-1" v-model="drawer">
        <div class="btn-box dashboard">
          <v-btn color="primary btn-dashboard" to="dashboard"><v-icon class="pa-2">mdi-view-dashboard</v-icon>Dashboard</v-btn>
        </div>
        <v-expansion-panels class="pa-2">
          <v-expansion-panel v-for="group in groups" :key="group.id">
            <v-expansion-panel-header>{{group.name}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense nav>
                <v-list-item v-for="item in getItemsForGroup(group.id)" :key="item.title" :to="getAbsoluteRoute(item.url)" link>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-navigation-drawer>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueService from "../sdks/venueService";
import UserSDK from "../sdks/userSdk";

export default {
  data: () => ({
    selectedVenue: null,
    venuesList: [],
    showBack: false,
    user: null,
    drawer: null,
    venueService: VenueService,
    curVenue: undefined,
    groups: [
      { name: "Insights", id: 1 },      
      { name: "Virtual Reality", id: 2 },
      { name: "Venues", id: 4 },
      { name: "Manage", id: 5 },
    ],
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard", url: "dashboard" },
      { title: "Density Zones", icon: "mdi-texture-box", url: "zones", group: 1 },
      { title: "Realtime", icon: "mdi-map-marker-outline", url: "realtime", group: 1 },
      { title: "Heatmaps", icon: "mdi-fire", url: "heatmap", group: 1 },
      { title: "Tracks", icon: "mdi-shoe-print", url: "tracks", group: 1 },
      { title: "Wireless Counter", icon: "mdi-account-reactivate-outline", url: "wireless-counters", group: 1 },
      { title: "Tracking alerts", icon: "mdi-alert-circle-outline", url: "alerts", group: 1 },
      { title: "Footfall: Passers-by", icon: "mdi-counter", url: "counter", group: 1 },
      { title: "Footfall: Waypoint", icon: "mdi-door-open", url: "doors", group: 1 },
      { title: "3D View", icon: "mdi-cube", url: "threeD", group: 1 },

      { title: "Planning Tour", icon: "mdi-virtual-reality", url: "tour", group: 2 },
      { title: "Customer Tour", icon: "mdi-virtual-reality", url: "customer-tour", group: 2 }, 

      { title: "Settings", icon: "mdi-pencil-box-outline", url: "venues/list", group: 4 },
      { title: "Zones", icon: "mdi-image-filter-center-focus", url: "venues/zones", group: 4 },
      { title: "Alerts", icon: "mdi-comment-alert-outline", url: "venues/alerts", group: 4 },
      { title: "Devices", icon: "mdi-power-plug", url:"venues/devices", group: 4 },

      { title: "Apps", icon: "mdi-cellphone-wireless", url: "apps", group: 5 },
      { title: "Users", icon: "mdi-account-multiple", url: "users", group: 5 }
    ],
    right: null
  }),
  computed:{
      ...mapGetters(['VENUE_LIST'])
  },
  mounted() {
    UserSDK.getUserFromToken().then(user => {
      this.user = user.data.user;
      if (this.user.type === 1 || this.user.type === 2) {
        this.showBack = true;
      }      
    });
  },
  methods: {
    setVenue (){
      this.$store.dispatch('CHANGE_VENUE', this.selectedVenue)
      this.$store.dispatch('CHANGE_JUMPER', 18)
    },
    logout(){
      this.$store.dispatch('LOGOUT').then(_x => {
        this.$router.push("/");
      })
    },

    
    getAbsoluteRoute(relRoute) {
      return `/client/${this.$route.params.clientId}/${relRoute}`
    },
    route() {
      if(this.user.type === 1) {
        this.$router.push({ path: `/admin/clients` });
      } else if (this.user.type === 2) {
        this.$router.push({ path: `/partner/${this.user.partnerId}/clients` });
      }
    },
    getItemsForGroup(id) {
      return this.items.filter(item => item.group === id);
    },

    changeValue(newVal) {
      this.drawer = newVal;
    },
  }
};
</script>

<style>
/* 
.v-text-field__details {
  display: none !important;
} */

.fix-width {
  width: 200px;
}

#admin-bar {
  height: 0px;
}
</style>