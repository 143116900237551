<template>
<div class="inner_body paddding">
   <partner-bar></partner-bar>
    <v-content>
      <v-container fluid>

        <router-view></router-view>
      </v-container>
    </v-content>
    </div>
</template>

<script>
import PartnerBar from "./partnerBar";

export default {
  components: { PartnerBar }
};
</script>