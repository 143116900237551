<template>
    <v-dialog v-model="GET_MODAL_STATUS.venue_alerts" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <div class="box-header">
                <div class="left">
                    <span class="modal-header" v-if="!GET_ALERT_CREATE">Edit Alert</span>
                    <span class="modal-header" v-else>Create Alert</span>
                </div>
                <div class="right">
                <v-btn icon @click="close()">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                </div>
            </div>
            <div class="box-map" v-if="ALERTMAP_READY">
                <mapview :jumpTo="GET_JUMPER" :geoMap="GET_VENUE_MAP"></mapview>
            </div>
            <div class="box-device-selection">
                <div class="left three-point">
                    <v-select v-model="_data.type" :items="GET_ALERT_TYPES" item-text="text" item-value="id" label="Select alert type" v-on:change="changeAlertType()"></v-select>
                </div>
                <div class="middle three-point" v-if="GET_ALERT_SELECTION.enter || GET_ALERT_SELECTION.leave">
                    <div class="inner">
                        <div class="middle_field">
                            <v-text-field hide-details="true" v-model="_data.name" outlined label="Name"></v-text-field>
                        </div>
                        <div class="middle_field">
                            <v-text-field hide-details="true" v-model="_data.description" outlined label="Descirption"></v-text-field>
                        </div>
                        <div class="middle_field">
                            <v-select v-model="_data.zones.begin.zoneid" :items="GET_ALL_ZONES" item-text="name" item-value="id" label="Select a zone"></v-select>
                        </div>
                    </div>
                </div>
            
                <div class="middle three-point" v-if="GET_ALERT_SELECTION.fromto">
                    <div class="inner">
                        <div class="middle_field four">
                            <v-text-field hide-details="true" v-model="_data.name" outlined label="Name"></v-text-field>
                        </div>
                        <div class="middle_field four">
                            <v-text-field hide-details="true" v-model="_data.description" outlined label="Descirption"></v-text-field>
                        </div>
                        <div class="middle_field four">
                            <v-select v-model="_data.zones.begin.zoneid" :items="GET_ALL_ZONES" item-text="name" item-value="id" label="Select a zone one"></v-select>
                        </div>
                        <div class="middle_field four">
                            <v-select v-model="_data.zones.end.zoneid" :items="GET_ALL_ZONES" item-text="name" item-value="id" label="Select a zone two"></v-select>
                        </div>  
                    </div>
                </div>
                <div class="right three-point">
                    <div class="inner">
                        <v-btn v-if="GET_ALERT_CREATE" color="primary" @click="addAlert()">Add alert</v-btn>
                        <v-btn v-if="!GET_ALERT_CREATE" color="primary" @click="updateAlert()">Update alert</v-btn>
                        <v-btn @click="close()">Cancel</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import Mapview from '../../shared/mapview'

export default {
    components: { Mapview },
    data() {
        return {
            _data: {
                type:null,
                zones:{
                    begin:{
                        zoneid: null,
                        name: null
                    },
                    end:{
                        zoneid: null,
                        name: null
                    }
                },
                name: null,
                description: null
            }
        }
    },
    props: {
        value: {
            default: false,
            type: Boolean
        }
    },
    computed:{
        ...mapGetters(['GET_MODAL_STATUS', 'GET_ALERT_CREATE', 'GET_ALERT_TYPES', 'GET_ALL_ZONES', 'GET_ALERT_SELECTION', 'ALERTMAP_READY', 'GET_VENUE_MAP', 'GET_JUMPER', 'GET_SELECTED_ALERT_DATA'])
    },
    watch: {
        value(newValue, oldValue){
            if(!this.GET_ALERT_CREATE){
                this.$store.dispatch('FIND_SELECTED_ALERT')
            }else{
                this.clearForm();
            }
        }, 
        GET_SELECTED_ALERT_DATA(newValue, oldValue){
            let input = newValue;

            this._data = {
                type: input.type,
                zones:{
                    begin:{
                        zoneid: input.zones.begin.zoneid,
                        name: null
                    },
                    end:{
                        zoneid: input.zones.end.zoneid,
                        name: null
                    }
                },
                name: input.name,
                description: input.description
            }
            this.changeAlertType()
        }
    },
    methods: {
        addAlert(){
            this.$store.dispatch('CREATE_ALERT', this._data)
        },
        updateAlert(){
            this.$store.dispatch('EDIT_ALERT', this._data)
        },
        changeAlertType(){
            this.$store.dispatch('UPDATE_ALERT_SELECTION', this._data.type)
        },
        clearForm(){
            this._data = {
                type:null,
                zones:{
                    begin:{
                        zoneid: null,
                        name: null
                    },
                    end:{
                        zoneid: null,
                        name: null
                    }
                },
                name: null,
                description: null
            }
        },
        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "venue_alerts", value: false}) 
        },
    },
    mounted() {}
}
</script>