import Vue from 'vue'

var endpoint = "device"


class DeviceApiService {

  
    get(mode,id) {
        let url = endpoint;

        if(mode) {
            url += `?mode=${mode}`;
        }

        if(id) {
            url += `&id=${id}`;
        }
        return Vue.axios.get(url);
    }

    post(request ) {
        return Vue.axios.post(endpoint, request)
    }

    update(values){

        let _device = {
            id: values.id,
            name: values.name,
            type: values.type,
            decowaveId: values.decowaveId
        }

        return Vue.axios.post(`${endpoint}/update`, _device)
    }

    assignDevice(request) {
        return Vue.axios.post(`${endpoint}/assign`, request)
    }

    unassignDevice(request)  {
        return Vue.axios.post(`${endpoint}/unassign`, request)
    }
}

var deviceService = new DeviceApiService();

export default deviceService;