<template>
  <div class="map-full">
    <MglMap container="MapBox" :accessToken="accessToken" :mapStyle.sync="mapStyle" :center="center" :zoom.sync="zoom" v-on:load="onLoad">
      <MglNavigationControl position="top-right" />
      <MglMarker :key="item[0]" v-for="item in locations" :coordinates="item" color="green" />
    </MglMap>
  </div>
</template>

<script>
import { MglMap, MglMarker, MglGeojsonLayer, MglNavigationControl } from "vue-mapbox";
import 'mapbox-gl/dist/mapbox-gl.css'
import { log } from "util";

export default {
  components: { MglMap, MglMarker, MglGeojsonLayer, MglNavigationControl },
  name: "App",
  props: {
    locations: {
      default: () => [],
      type: Array
    },
    flylocation: {},
    venue: Object,
  },
  data() {
    return {
      mapRef: null,
      zoom: 4.5,
      center: this.flylocation,
      loading: false,
      loaded: false,
      snackText: 'An error occured',
      snackbar: false,
      accessToken: "pk.eyJ1IjoiYWxleHdhcmRieSIsImEiOiJjazB4ajJ6ODgwNjEwM21xbWl3bjI4bmNrIn0.uoA6VRMGaOJUIY1EPkEf5g",
      mapStyle: "mapbox://styles/alexwardby/ck0xo4znv03gp1cqyuk4ufe3q"
    };
  },
  watch: {
    date: function(newVal, oldVal) {
      this.getTracks();
    },
    flylocation:  function(newVal, oldVal) {
      this.mapRef.actions
        .flyTo({ center: newVal })
        .then(_ => this.zoom = 17)
        .catch(_ => console.log("error", _));
    },
    venue: function(newVal, oldVal) {

      if(this.venue === undefined) return;

      const coords = this.venue.location.coordinates;
      
      this.mapRef.actions
        .flyTo({ center: coords })
        .then(_ => this.zoom = 17)
        .catch(_ => console.log("error", _));
    },
    zoom: function(newVal, oldVal) {
      this.$emit('zoom', newVal);
    }
  },
  mounted() {
      if(this.venue !== undefined) {
        const coords = this.venue.location.coordinates;
        this.center =  {"lng":coords[0],"lat": coords[1]};
        this.zoom = 17;
      }
  },
  methods: {
    emitClick(obj) {
      this.$emit('map-clicked', obj.lngLat);
    },
    onLoad(item) {
      this.mapRef = item.component;
      this.mapRef.map.resize()
      this.mapRef.map.on('click', this.emitClick);
    },
  }
};
</script>


