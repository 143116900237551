import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

const excludeColumns = []
const columnNames = [
    { id: 'deviceId', text: 'Device ID', sortable: true, order: 1 },
    { id: 'venueId', text: 'Venue ID', sortable: true, order: 2 },
    { id: 'label', text: 'Label', sortable: true, order: 3 },
    { id: 'coordinates', text: 'Coordinates', sortable: true, order: 4 },
]

export default {
    state: {
        lifetime_ready: false,
        history_selected_date: new Date().toISOString().substr(0, 10),
        lifetime_tracking: { list: [], colour: null },
        history_list: { headers: [], values: []},
        selected_lifedevices: null
    },
    getters : {
        LIFETIMEMAP_READY: state => {
            return state.lifetime_ready
        },
        GET_LIFETIME: state => {
            return state.lifetime_tracking
        },
        GET_HISTORY_LIST: state => {
            return state.history_list
        },
        GET_HISTORY_DATE: state => {
            return state.history_selected_date
        },
        GET_SELECTED_LIFEDEVICES: state => {
            return state.selected_lifedevices
        }
    },
    actions: {
        INIT_LIFETIME_MAP: async (context) => {
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            await context.dispatch('CREATE_TAGDEVICES_LIST', null, { root: true })
        },
        FIND_REALTIME_TRACKS: async(context) => {
            
            let _tracks = { list: [], colour: "#1976d2"}
            let _values = []
            let _headers = []
            let _devices = context.state.selected_lifedevices

            if(_devices.length > 0) {

                await context.dispatch('GET_REALTIME_TRACK', _devices, {root: true}).then(async (data) => {
                    if(data != null && data.valid){
                        if(data.message != null && data.message.devices.length > 0){
                            let _id = 0
                            data.message.devices.forEach(device => {
                                _tracks.list.push( { "id": _id, "value": device.coordinates, "device": device.deviceId })
                                _id++
                            });     
                        }

                        if(data.message != null && data.message.history.length > 0){
                            _values = data.message.history    
                        }
                        _headers = await context.dispatch('GET_LIFETRACKS_LIST', data.message.history)  
                    }

                    context.commit('SET_LIFETIME_TRACKS', _tracks)
                    context.commit('SET_HISTORY_LIST_VALUES', _values)
                    context.commit('SET_LIST_HEADERS', _headers)
                })
            }else{
                context.commit('RELOAD_LIFETIME')
            }

        },
        GET_LIFETRACKS_LIST: async (context, data) => {

            let headers = []

            columnNames.forEach(column => {
                headers.push({text: column.text, sortable: column.sortable, value: column.id})
            });

            return headers
        }
    },
    mutations: {
        SET_LIFETIMEMAP_READY: (state, value) => {
            state.lifetime_ready = value
        },
        SET_LIFETIME_TRACKS: (state, value) => {
            state.lifetime_tracking = value
        },
        SET_HISTORY_LIST_VALUES: (state, value) => {
            state.history_list.values = value
        },
        SET_LIST_HEADERS: (state, value) => {
            state.history_list.headers = value
        },
        RELOAD_LIFETIME: (state) => {
            state.history_list = { headers:[], values: [] }
            state.lifetime_tracking = { list: [], colour: "#1976d2"}
        },
        TRACK_LIFEDEVICES: (state, value) => {
            state.selected_lifedevices = value
        }

    }
}