<template>
  <div>
    <v-row>
      <v-col>
        <v-btn color="primary" @click="open=true" raised>Add Partner</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="open" width="400" @keydown.esc="close()" @keydown.enter="close()">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Create Partner</v-card-title>

        <div style="margin:10px">
          <v-form>
            <v-text-field v-model="name" label="Name"></v-text-field>
          </v-form>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="add()">Ok</v-btn>
          <v-btn @click="close()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      open: false
    };
  },
  methods: {
    add() {
        this.axios.post('partner', {
            name: this.name
        }).then(response => {
            this.open = false;
            this.$emit("reload")
        })
      
    },
    close() {
        this.open = false;
    }
  }
};
</script>

<style>
</style>