<template>
  <div>
    <div v-if="open" class="d-flex justify-end">
      <v-text-field class="d-inline" v-model="count" label="Count"></v-text-field>
      <v-btn class="d-inline" icon @click="adjustCount()">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn class="d-inline" icon @click="open = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-if="!open" class="d-flex justify-end">
      <v-btn class="d-inline" icon @click="open = true">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        zoneId: ""
    },
  data: function() {
    return {
      count: 0,
      open: false
    };
  },
  methods: {
    adjustCount() {
      this.axios
        .post("zone/adjust", {
          zoneId: this.zoneId,
          clientId: this.$route.params.clientId,
          newCount: Number(this.count)
        })
        .then(_ => this.open = false)
        .catch(error => {
            this.open = false;
            });
        
    }
  }
};
</script>

<style>
</style>