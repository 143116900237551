<template>
  <div>
    <h2 v-if="!venueSelected()">Select Venue</h2>
    <div v-if="venueSelected()">
      <h1>3D View</h1>
      <vgl-renderer ref="renderer" camera="mycam" scene="myscene" class="getting-started">
        <vgl-directional-light></vgl-directional-light>
        <vgl-box-geometry name="box"></vgl-box-geometry>
        <vgl-plane-geometry name="plane" :width="width" :height="height" />
        <vgl-scene ref="myscene" name="myscene">
          <vgl-mesh-standard-material ref="mymat" name="std" />
          <vgl-ambient-light color="#ffeecc" />
          <vgl-directional-light position="0 1 2" />
        </vgl-scene>
        <vgl-perspective-camera ref="camera" name="mycam" orbit-position="3 1 0.5"></vgl-perspective-camera>
        <orbit-controls camera="mycam"></orbit-controls>
      </vgl-renderer>

      <v-card class="mb-800">
        <v-form >
          <v-container>
            <v-row justify="left" >
              <v-col class="col-6">
                <v-text-field v-model="inputFloorNo" label="Floor No"></v-text-field>
              </v-col>
              <v-col class="col-12">
                <v-btn color="primary" @click="display2DFloorPlan()">View 2D Map</v-btn>
              </v-col> 
              <!--<v-col class="col-12">
                <v-btn color="primary" @click="display3DFloorPlan()">Display 3D Floor Plan</v-btn>
              </v-col> -->                           
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      
    </div>  
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGL from "vue-gl";
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
Object.keys(VueGL).forEach(name => Vue.component(name, VueGL[name]));
import VenueService from "../../sdks/venueService";

export default {
  components: {   OrbitControls },
  data() {
    return {
      width: 3,
      height: 3,
      controls: null,
      camera: undefined
    };
  },
  mounted() {
    //this.timer = setInterval(this.loadFloors, 50);
    //this.loadFloors();

    VenueService.listener = this.venueChangedListener;
    setTimeout(this.loadFloors(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    venueChangedListener(venue) {
      this.venue = VenueService.currentVenue;

      this.loadFloors();
    },
    venueSelected() {
      return VenueService.currentVenue !== undefined;
    },
    loadFloors() {
      if (VenueService.currentVenue !== null) {

        this.venue = VenueService.currentVenue;

        this.renderer = this.$refs.renderer.inst

        this.camera = this.$refs.camera.inst;

        for(let i = 0; i < this.venue.floors.length; i++) {
            this.addImageToMesh(this.createFloor, i);                
        }

      }
    },
    addImageToMesh(fAddFloor, floorNo) {
      new THREE.ImageLoader()
					.load(this.venue.floors[floorNo].floorPlanUrl, function ( imageBitmap ) {

						var texture = new THREE.CanvasTexture( imageBitmap );
						var material = new THREE.MeshBasicMaterial( { map: texture } );

            fAddFloor(material, floorNo);

					}, function ( p ) {

					}, function ( e ) {

					} );
    },
    redraw() {
      this.$refs.renderer.requestRender();
    },
    createFloor(material, n) {
      
      var width = this.venue.floors[n].floorWidthM;
      var height = this.venue.floors[n].floorHeightM; 
      
      var depth = 0; 
      if(n > 0)
      {
        for(let i = 0; i < n; i++) {
          depth += this.venue.floors[i].floorDepthM;               

        }
      }         

      var geometry = new THREE.PlaneBufferGeometry(width,height);
      
      var cube = new THREE.Mesh( geometry, material );

        cube.position.set( -3,-6+depth,-5); 
        cube.rotation.set( -1.65, 0, 0 ); 

        this.$refs.myscene.inst.add(cube);
        this.redraw();
    },
    display2DFloorPlan(){
      if(this.inputFloorNo != undefined){
        if(this.inputFloorNo < this.venue.floors.length){
          this.$router.push({path: `./threeD/${this.inputFloorNo}/plan`});
        }
        else{
          alert("Please input a right number.");
        }
      }
      else{
        alert("Please input a floor number.");        
      }
    },
    display3DFloorPlan(){
      //this.$refs.myscene.inst.clear();
      //this.redraw();
      //alert("test");
    } 
  },
  computed: {
    cmr() {
      return this.$refs.camera.inst; 
    }
  },
  watch: {
    camera: {
      handler(cmr) {
        Vue.nextTick(() => {
          if (cmr == undefined) {
            return;
          } else {
          }
          const controls = new OrbitControls(
            cmr,
            this.$refs.renderer.inst.domElement
          );
          controls.addEventListener("change", () => {
            this.redraw();
          });
        });
      },
      immediate: true
    }
  }
};
</script>

<style>
.getting-started {
  width: 800px;
  height: 500px;
}
.mb-800 {
  max-width: 800px;
}
.col-6 {
    max-width: 150px;
}
.col-12 {
    max-width: 250px;
}
</style>