<template>
  <div id="admin-bar">
    <v-app>
      <v-app-bar
        app
        color="primary"
        dark
        style="height:64px;margin-top:0px;transform:translateY(0px);left:0px;right:0px;"
      >
        <router-link to="/" class="logo-box"><img height="40" src="/img/briteyellow-600px-wide.webp" alt="Briteyellow logo" /></router-link>
        <v-toolbar-title class="headline" style="padding-right:5px">
          <!-- <span class="font-weight-light">BriteLocate Platform</span> -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>Logged in as Admin</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-app-bar>

      <v-navigation-drawer app clipped color="grey lighten-3" class="elevation-1" v-model="drawer">

        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" :to="item.url" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app>
  </div>
</template>

<script>
export default {
  methods: {
    changeValue(newVal) {
      this.drawer = newVal;
    }
  },
  data: () => ({
    drawer: null,
    items: [
      { title: "Clients", icon: "mdi-account-box", url: "clients" },
      { title: "Devices", icon: "mdi-radio-tower", url: "devices" },
      { title: "Pricing Calculator", icon: "mdi-calculator", url: "pricing-calc"},
      { title: "Users", icon: "mdi-account-multiple", url: "users" }
    ],
    right: null
  })
};
</script>

<style>
.fix-width {
  width: 200px;
}
#admin-bar {
  height: 0px;
}
</style>