<template>
  <v-dialog v-model="GET_MODAL_STATUS.edit" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
    <div class="dialog-box-inner">
      <div class="box-header">
        <div class="left"></div>
        <div class="right">
          <v-btn icon @click="close()">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="box-map" v-if="DEVICEMAP_READY">
        <div class="coordinate-box">
          <div class="inner">
              <div class="box-field left"><v-text-field hide-details="true" v-model="locationData.location[0]" v-on:change="changeCoordinates()" outlined label="Latitude" append-icon="mdi-map-marker"></v-text-field></div>
              <div class="box-field right"><v-text-field hide-details="true" v-model="locationData.location[1]" v-on:change="changeCoordinates()" outlined label="Longitude" append-icon="mdi-map-marker"></v-text-field></div>
          </div>
        </div>
        <mapview :jumpTo="GET_JUMPER" :geoMap="GET_VENUE_MAP" :selectorPosition="locationData.mapMarker" v-on:map-clicked="addMarker" :isSelector="true" :zonesDraw="true"></mapview>
      </div>
      <div class="box-device-selection">
        <div class="left five-piece">
          <v-select v-model="locationData.floor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
        </div>
        <div class="middle-one five-piece-two">
          <v-select v-model="locationData.deviceType" :items="DEVICE_TYPES_LIST" item-text="text" item-value="type" label="Select device type" v-on:change="changeDeviceType()"></v-select>
        </div>
        <div class="middle-two five-piece-three">
          <v-select v-model="locationData.device" :disabled="GET_DEVICES_VISIBILITY" :items="GET_DEVICES_LOC_LIST" item-text="name" item-value="id" label="Select a device"></v-select>
        </div>
        <div class="middle-two five-piece-s">
            <v-text-field type="text" hide-details="true" v-model="locationData.xposition" outlined label="X Position"></v-text-field>             
        </div>
        <div class="middle-two five-piece-s">
            <v-text-field type="text" hide-details="true" v-model="locationData.yposition" outlined label="Y Position"></v-text-field>             
        </div>
        <div class="right five-piece-six">
          <div class="inner">
            <v-btn v-if="!IS_CHANGE_LOCATION" color="primary" @click="addToLocation()">Add device to location</v-btn>
            <v-btn v-if="IS_CHANGE_LOCATION" color="primary" @click="updateLocation()">Update device location</v-btn>
            <v-btn @click="close()">Cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from '../../shared/mapview'

export default {
  components: { Mapview },
  data() {
    return {
      locationData: { location: [], mapMarker: {}, xposition: 0, yposition: 0},
      selectedFloor: null,
      selectedDeviceType: null,
      selectedDevice: null,
      selectedMarker: [],
      mapMarker: { color: "", coordinates: []},
    };
  },
  methods: {
    close() {
      this.$store.commit('SET_MODAL_STATUS', { target: "edit", value: false}) 
    },
    addMarker(coordinates){
      this.locationData.location = [coordinates.lat, coordinates.lng]
    },
    changeFloor(){
      this.$store.commit('SET_SELECTED_FLOOR', this.locationData.floor)
      this.$store.dispatch('FIND_VENUE_MAP', this.locationData.floor) 
    },
    changeDeviceType(){
      this.$store.dispatch('CHANGE_DEVICE_TYPE', this.locationData.deviceType)
      this.updateMarkerColour();
    },
    addToLocation() {
      this.$store.dispatch('ADD_DLOCATION', { floor: this.locationData.floor, device: this.locationData.deviceType, coordinates: this.locationData.location, deviceID: this.locationData.device, xposition: this.locationData.xposition, yposition: this.locationData.yposition })
      this.close();
    },
    changeCoordinates(){
    
      let lat = parseFloat(this.locationData.location[0])
      let lng = parseFloat(this.locationData.location[1])
      let type = this.DEVICE_TYPES_LIST.find(x => x.type == this.locationData.deviceType)

      this.locationData.location = [lat, lng]
      this.$store.commit('SET_SELECTOR_COLOUR', type != null ? type.color : "#1ca5ff")
      this.locationData.mapMarker = { color: type != null ? type.color : "#1ca5ff", coordinates: [lng, lat] }
    
    },
    updateMarkerColour(){
      let lat = parseFloat(this.locationData.location[0])
      let lng = parseFloat(this.locationData.location[1])

      let type = this.DEVICE_TYPES_LIST.find(x => x.type == this.locationData.deviceType)
      this.$store.commit('SET_SELECTOR_COLOUR', type != null ? type.color : "#1ca5ff")
      this.locationData.mapMarker = { color: type != null ? type.color : "#1ca5ff", coordinates: [lng, lat] }
    },
    updateLocation(){
      let _location = this.locationData
      this.$store.dispatch('UPDATE_DLOCATION', { floor: _location.floor, deviceType: _location.deviceType, coordinates: _location.location, deviceID: _location.device, xposition: _location.xposition, yposition: _location.yposition })
      this.close();
    }
  },
  computed:{
      ...mapGetters(['DEVICEMAP_READY', 'GET_JUMPER', 'GET_VENUE_MAP', 'DEVICE_TYPES_LIST', 'GET_VENUE_FLOORS', 'GET_SELECTED_FLOOR', 'DEVICE_LOCATIONS_LIST', 'GET_DEVICES', 'GET_SELECTED_LOCATION', 'GET_MODAL_STATUS', 'IS_CHANGE_LOCATION', 'GET_DEVICES_VISIBILITY', 'GET_DEVICES_LOC_LIST'])
  },
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    async value(newValue, oldValue) {
      if(newValue){
        if(this.IS_CHANGE_LOCATION){
          this.locationData = await this.$store.dispatch('SETUP_LOCATION_DATA')
        }else{
          this.locationData = await this.$store.dispatch('RESET_LOCATION_DATA')
        }
        this.$store.dispatch('CREATE_LIST_LOC_DEVICES', this.locationData.device)
      }      
    }
  },
  async mounted() {
    this.locationData.floor = this.GET_SELECTED_FLOOR
    this.locationData.xposition = 0
    this.locationData.yposition = 0
  }
};
</script>
