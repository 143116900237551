import Vue from 'vue'
import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request'
import fetchDeleteRQ from '../../plugins/data-delete-requests'


const _headers = [
    { id: 'name', text: 'Name', sortable: true, order: 1},
    { id: 'description', text: 'Description', sortable: true, order: 2},
    { id: 'type', text: 'Zone Type', sortable: true, order: 3 },
    { id: 'created', text: 'Date Created', sortable: true, order: 4},
    { id: 'action', text: 'Action', sortable: false, order: 5, align: 'right'},
]

const _zoneTypes = [
    { id: 'venue', text: 'Venue as Zone' },
    { id: 'facility', text: 'Facility as Zone' }
]

export default {
    state: {
        zone_map_ready: false,
        zone_list_ready: false,
        is_zone_create: true,
        selected_zone_id: 0,
        selected_zone_data: null,
        zone_types_visibility: true,
        zone_dimensions: [],
        zones:{ headers: [], values: []},
    },
    getters:{
        ZONEMAP_READY: state => {
            return state.zone_map_ready
        },
        GET_ZONE_LIST_READY: state => {
            return state.zone_list_ready
        },
        GET_LIST_ZONES: state => {
            return state.zones
        },
        GET_LIST_ZONES_VALUES: state => {
            return state.zones.values
        },
        GET_ZONE_CREATE: state => {
            return state.is_zone_create
        },
        GET_ZONE_TYPES: state => {
            return _zoneTypes
        },
        GET_ZONE_DIMENSIONS: state => {
            return state.zone_dimensions
        },
        GET_SELECTED_ZONE: state => {
            return state.selected_zone_id
        },
        GET_SELECTED_ZONE_DATA: state => {
            return state.selected_zone_data
        },
        GET_ZONE_TYPES_VISIBILITY: state => {
            return state.zone_types_visibility
        }
    },
    actions: {
        CREATE_VENUE_ZONES_LIST: async (context) => {

            let _list = { headers: [], values: []}
            let _venue = context.rootGetters.CURRENT_VENUE

            if(_venue != null && _venue.id != null){
                let _zones = await fetchGetRQ(`zones/getall/${_venue.id}`)

                if(_zones != null && _zones.valid && _zones.message.isValid){
                    
                    let results = _zones.message.data;
                    results.forEach(result => {
                        let zoneType = _zoneTypes.find(x => x.id == result.type);
                        _list.values.push({id: result.id, name: result.name, description: result.description, type: zoneType != null ? zoneType.text : "Unknown", created: result.created.dateOnly })
                    })

                }
            }

            _list.headers = await context.dispatch('CREATE_VENUE_ZONES_HEADERS', null, { root: true })

            context.commit('SET_LIST_ZONES', _list)
        },
        CREATE_VENUE_ZONES_HEADERS: async (context) => {
            let headers = []

            _headers.forEach(header => {
                headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return headers;
        },
        INIT_ZONE_MAP: async (context) => {
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            context.commit('SET_ZONE_MAP_READY', true)
        },
        FIND_SELECTED_ZONE: async (context) => {

            let zone = context.state.selected_zone_id
            let _zone = await fetchGetRQ(`zones/get/${zone}`)

            if(_zone != null && _zone.valid && _zone.message.isValid){

                let result = _zone.message.data
                let _dimensions = await context.dispatch('RESTORE_ZONES_JSON', result.dimensions, { root: true})
                let _data = { id: result.id, name: result.name, description: result.description, type: result.type, floor: result.floor, dimensions: _dimensions, capacity: result.capacity}
                
                context.commit('SET_SELECTED_ZONE_DATA', _data)
            }
        },
        CREATE_VENUE_ZONE: async (context, data) => {

            let _venue = context.rootGetters.CURRENT_VENUE

            if(_venue != null && _venue.id != null){

                let _dimensions = []
                let dimensions = context.state.zone_dimensions

                dimensions.forEach(dimension => {
                    _dimensions.push({lat: dimension[1], lng: dimension[0]})
                })

                let _data = {...data, venueId: _venue.id, dimensions: _dimensions }

                console.log(_data)
                let _zone = await fetchRQ(`zones/create`, _data)
                
                if(_zone != null && _zone.valid && _zone.message.isValid){
                    context.commit('SET_MODAL_STATUS', { target: "venue_zone", value: false}) 
                    context.dispatch('CREATE_VENUE_ZONES_LIST')
                }

            }       
        },
        EDIT_VENUE_ZONE: async (context, data) => {

            let _venue = context.rootGetters.CURRENT_VENUE
            let _zoneSelected = context.rootGetters.GET_SELECTED_ZONE_DATA

            if(_venue != null && _venue.id != null && _zoneSelected != null && _zoneSelected.id != null){

                let _dimensions = []
                let dimensions = context.state.zone_dimensions

                dimensions.forEach(dimension => {
                    _dimensions.push({lat: dimension[1], lng: dimension[0]})
                })

                let _data = {...data, id: _zoneSelected.id, venueId: _venue.id, dimensions: _dimensions.length > 0 ? _dimensions : null }

                console.log(_data)
                let _zone = await fetchRQ(`zones/update`, _data)
                
                if(_zone != null && _zone.valid && _zone.message.isValid){
                    context.commit('SET_MODAL_STATUS', { target: "venue_zone", value: false}) 
                    context.dispatch('CREATE_VENUE_ZONES_LIST')
                }

            }
        },
        RESTORE_ZONES_JSON: async (context, dimensions) => {
         
            let _coordinates = []
            let polygon = { type: "geojson", data: { id: "venueZones", type: "FeatureCollection" , features: [] }}
            let feature = { type: "Feature", properties: {}, geometry: { coordinates: [], type: "Polygon" }}

            dimensions.forEach(dimension => {
                _coordinates.push([dimension.lat, dimension.lng])
            })

            feature.geometry.coordinates.push(_coordinates)
            polygon.data.features.push(feature)
        
            return polygon
        },
        DELETE_VENUE_ZONE: async (context, item) => {
            
            let _zone = await fetchGetRQ(`zones/delete/${item.id}`)

            if(_zone != null && _zone.valid && _zone.message.isValid){
                if(_zone.message.data.deletedID != null){
                    context.dispatch('CREATE_VENUE_ZONES_LIST')
                }
            }

        },
        VALIDATE_ZONE_TYPE: async (context, value) => {
            let show = value == "venue";
            context.commit('SET_ZONE_TYPES_VISIBILITY', show)
        },
        FIND_ZONES_NAME: async (context, zones) => {

            let _zones = context.rootGetters.GET_ALL_ZONES            
            let beginname = _zones.find(x => x.id == zones.begin.zoneid);
            let endname = _zones.find(x => x.id == zones.end.zoneid);

            let names = {
                begin:  beginname  !== undefined ? beginname.name : "",
                end: endname !== undefined ? endname.name : ""
            };
            
            return names;
        } 
    },
    mutations: {
        SET_LIST_ZONES: (state, value) => {
            state.zones = value
        },
        SET_ZONE_CREATE: (state, value) => {
            state.is_zone_create = value
        },
        SET_ZONE_LIST_READY: (state, value) => {
            state.zone_list_ready = value
        },
        SET_ZONE_MAP_READY: (state, value) => {
            state.zone_map_ready = value
        },
        SET_ZONE_DIMENSIONS: (state, value) => {
            state.zone_dimensions = value
        },
        SET_SELECTED_ZONE: (state, value) => {
            state.selected_zone_id = value
        },
        SET_SELECTED_ZONE_DATA: (state, value) => {
            state.selected_zone_data = value
        },
        SET_ZONE_TYPES_VISIBILITY: (state, value) => {
            state.zone_types_visibility = value
        }
    }
}