<template>
   <v-dialog
      v-model="value"
      width="400"
      @keydown.esc="close()" @keydown.enter="close()"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn
          color="red lighten-2"
          dark
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card >
        <v-card-title class="headline grey lighten-2" primary-title>Create Client</v-card-title>

        <div style="margin:10px">
          <v-form>
              <v-text-field v-model="name" label="Name"></v-text-field>
              </v-form>
        
        </div>
       
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="addClient()"
          >
            Ok
          </v-btn>
          <v-btn
            @click="value = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ClientSdk from '../../sdks/clientSdk'

export default {
    props: ['value'],
    data () {
      return {
        name: ''
      } 
    },
    watch: {
            value() {
                this.$emit('input', this.value);
            }
        },
    methods: {
      addClient() {
        ClientSdk.postClient({'name': this.name},this.$route.params.partnerId)
        .then(response => {
          this.value = false;
          this.$emit('reload');
          });
      }
    }
}
</script>

<style>

</style>