import Vue from 'vue'
import axios from 'axios'

var endpoint = "client"


class ClientSdk {

  
    getClients(partnerId) {

        let url = endpoint
        if(partnerId) {
            url += `?partner_id=${partnerId}`
        }

        return Vue.axios.get(url);
    }

    postClient(request, partnerId ) {

        let url = endpoint
        if(partnerId) {
            url += `?partner_id=${partnerId}`
        }

        return Vue.axios.post(url,request)
    }
}

var clientSdk = new ClientSdk();

export default clientSdk;