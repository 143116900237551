<template>
    <v-dialog v-model="GET_MODAL_STATUS.venue_settings" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <div class="box-header">
                <div class="left">
                    <span class="modal-header" v-if="!GET_VENUE_CREATE">Edit venue</span>
                    <span class="modal-header" v-else>Add a venue</span>
                </div>
                <div class="right">
                <v-btn icon @click="close()">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                </div>
            </div>
            <div class="box-map settings" v-if="SETTINGSMAP_READY">
                <div class="coordinate-box">
                    <div class="inner">
                        <div class="box-field left"><v-text-field hide-details="true" v-model="selectedMarker[0]" v-on:change="changeCoordinates()" outlined label="Latitude" append-icon="mdi-map-marker"></v-text-field></div>
                        <div class="box-field right"><v-text-field hide-details="true" v-model="selectedMarker[1]" v-on:change="changeCoordinates()" outlined label="Longitude" append-icon="mdi-map-marker"></v-text-field></div>
                    </div>
                </div>
                <mapview :jumpTo="GET_JUMPER" :drawZones="true"></mapview>
            </div>
            <div class="box-device-selection settings">
                <div class="field-line">
                    <div class="field-box">
                        <v-text-field hide-details="true" v-model="settingsName" outlined label="Name"></v-text-field>
                    </div>
                    <div class="field-box">
                        <v-text-field hide-details="true" v-model="settingsDescription" outlined label="Description"></v-text-field>
                    </div>
                    <div class="field-box">
                        <v-text-field hide-details="true" v-model="settingsHeight" outlined label="MediumHeight"></v-text-field>
                    </div>
                </div>
                <div class="field-line">
                    <div class="field-box">
                        <v-text-field hide-details="true" v-model="settingsAddress" outlined label="Address"></v-text-field>
                    </div>
                    <div class="field-box">
                        <v-text-field hide-details="true" v-model="settingsPhone" outlined label="Phone"></v-text-field>
                    </div>
                    <div class="field-box buttons">
                        <div class="inner">
                            <v-btn v-if="GET_VENUE_CREATE" color="primary" @click="addVenue()">Add venue</v-btn>
                            <v-btn v-if="!GET_VENUE_CREATE" color="primary" @click="updateVenue()">Update venue</v-btn>
                            <v-btn @click="close()">Cancel</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import Mapview from '../../shared/mapview'

export default {
    components: { Mapview },
    data() {
        return {
            selectedMarker: [],
            mapMarker: { color: "", coordinates: []},
            settingsName: null,
            settingsDescription: null,
            settingsHeight: null,
            settingsAddress: null,
            settingsPhone: null
        }
    },
    computed:{
        ...mapGetters(['GET_MODAL_STATUS', 'GET_VENUE_CREATE', 'GET_JUMPER', 'GET_VENUE_MAP', 'SETTINGSMAP_READY'])
    },
    methods: {
        addMarker(coordinates){
            this.selectedMarker = [coordinates.lat, coordinates.lng]
        },
        changeCoordinates(){
        
            let lat = parseFloat(this.selectedMarker[0])
            let lng = parseFloat(this.selectedMarker[1])

            this.selectedMarker = [lat, lng]
            this.mapMarker = { color: "#1ca5ff", coordinates: [lng, lat] }
        
        },
        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "venue_settings", value: false}) 
        },
    }
}

</script>