<template>
  <div class="pa-6">
    <h2>Your Order</h2>

    <div v-if="!order.counters && !order.tours && !order.wayfinding">
      <p>You have nothing in your basket</p>
    </div>
    <div v-if="order.counters">
      <summary-table :items="order.counters.products"></summary-table>
      <v-row>
        <v-col class="font-weight-bold" cols="2">Term</v-col>
        <v-col cols="2">
          <p>{{order.counters.termTitle}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-weight-bold" cols="2">Sub Total</v-col>
        <v-col cols="2">
          <p>{{order.counters.total}}</p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <div v-if="order.tours">
      <summary-table :items="order.tours.products"></summary-table>

      <v-row>
        <v-col class="font-weight-bold" cols="2">Term</v-col>
        <v-col cols="2">
          <p>{{order.tours.termTitle}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-weight-bold" cols="2">Sub Total</v-col>
        <v-col cols="2">
          <p>{{order.tours.total}}</p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <div v-if="order.wayfinding">
      <summary-table :items="order.wayfinding.products"></summary-table>

      <v-row>
        <v-col class="font-weight-bold" cols="2">Term</v-col>
        <v-col cols="2">
          <p>{{order.wayfinding.termTitle}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-weight-bold" cols="2">Sub Total</v-col>
        <v-col cols="2">
          <p>{{order.wayfinding.total}}</p>
        </v-col>
      </v-row>
    </div>

    <v-row justify="end">
      <v-col cols="2">
        <v-text-field class="font-weight-bold" v-model="quoteTotal"></v-text-field>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <!-- <v-row justify="end">
      <v-col cols="3">
        <v-item-group>
          <v-btn @click="$emit('checkout')" color="primary">Confirm</v-btn>
        </v-item-group>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import SummaryTable from "./summaryTable";
export default {
  components: { SummaryTable },
  props: {
    order: Object
  },
  computed: {
    quoteTotal() {
      const counterTotal = this.order.counters
        ? this.order.counters.numTotal
        : 0;
      const tourTotal = this.order.tours ? this.order.tours.numTotal : 0;
      const wayfindingTotal = this.order.wayfinding ? this.order.wayfinding.numTotal: 0;
      return `£ ${counterTotal + tourTotal + wayfindingTotal}`;
    }
  }
};
</script>

<style>
</style>