<template>
  <div id="admin-bar">
    <v-app>
      <v-app-bar app color="primary" dark style="height:64px;margin-top:0px;transform:translateY(0px);left:0px;right:0px;">
        <router-link to="/" class="logo-box"><img height="40" src="/img/mini-logo.png" alt="Briteyellow" />
          <span class="logo-title">BriteLocate Platform</span>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click.stop="changeValue(!drawer)">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-btn icon @click="logout()">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </v-toolbar-items>    
      </v-app-bar>
      <v-navigation-drawer app clipped color="grey lighten-3" class="elevation-1" v-model="drawer">
        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" :to="item.url" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      { title: "Clients", icon: "mdi-account-box", url: "/admin/clients" },
      { title: "Devices", icon: "mdi-radio-tower", url: "/admin/devices" },
      { title: "Partners", icon: "mdi-handshake", url: "/admin/partners" },
      { title: "Users", icon: "mdi-account-multiple", url: "/admin/users" }
    ],
    right: null
  }),
  methods: {
    changeValue(newVal) {
      this.drawer = newVal;
    },
    logout(){
      this.$store.dispatch('LOGOUT').then(_x => {
        this.$router.push("/");
      })
    }
  }
};
</script>

<style>
.fix-width {
  width: 200px;
}

#admin-bar {
  height: 0px;
}
</style>