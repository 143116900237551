<template>
  <div class="pa-6">
    <h2>Your Details</h2>

    <v-row justify="center">
      <v-col cols="12" md="4">
        <div v-if="submitted">
          <p v-if="!submitError">Thanks! Your quote has been recieved and we will be in contact shortly to arrange delivery.</p>
          <div  v-if="submitError">
            <p>Ouch! This is embarressing .... there seems to be an error submitting your order!</p>
            <p>Please get in touch to place your order by phone.</p>
          </div>
        </div>
        <v-form v-if="!submitted" ref="form" v-model="valid">
          <v-text-field v-model="name" :rules="nameRules" label="Name*" required dense></v-text-field>

          <v-text-field v-model="email" :rules="emailRules" label="E-mail*" required dense></v-text-field>

          <v-text-field v-model="phoneNo" :rules="nameRules" label="Phone Number*" required dense></v-text-field>

          <v-text-field
            v-model="companyName"
            :rules="nameRules"
            label="Company Name*"
            required
            dense
          ></v-text-field>

          <!-- <div style="min-height:30px"></div> -->

          <v-text-field v-model="addr1" :rules="nameRules" label="Address Line One*" required dense></v-text-field>

          <v-text-field v-model="addr2" label="Address Line Two" dense></v-text-field>

          <v-text-field v-model="county" label="County/State" dense></v-text-field>

          <v-text-field v-model="country" :rules="nameRules" label="Country*" required dense></v-text-field>
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">Submit</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    order: Object
  },
  data: () => {
    return {
      submitted: false,
      submitError: false,
      valid: false,
      name: "",
      nameRules: [v => !!v || "Required field"],
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      companyName: "",
      phoneNo: "",
      addr1: "",
      addr2: "",
      county: "",
      country: ""
    };
  },
  methods: {
    submit() {
      const details = {
        name: this.name,
        email: this.email,
        companyName: this.companyName,
        phoneNo: this.phoneNo,
        addressLine1: this.addr1,
        addressLine2: this.addr2,
        county: this.county,
        country: this.country
      };
      this.order.details = details;

      this.axios
        .post("quote", this.order)
        .then(response => {
          this.submitted = true;
        })
        .catch(error => {
          this.submitted = true;
          this.submitError = true;
        });
    }
  }
};
</script>

<style>
</style>