<template>
  <div>
    <v-card class="report-container" v-if="loaded">
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab :key="1">Chart</v-tab>
        <v-tab :key="2">Heatmap</v-tab>
        <v-tab-item :key="1">
          <v-card class="report-container-inner" flat tile>
            <div class="chart-container">
              <door-graph :filter.sync="filter" v-if="tab === 0"></door-graph>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-card class="report-container-inner" flat tile>
            <door-heatmap :filter.sync="filter" v-if="tab === 1"></door-heatmap>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import DoorGraph from './doorGraph'
import DoorHeatmap from './doorHeatmapDataWrapper'

export default {
  components: {DoorGraph, DoorHeatmap},
  props: {
    filter: Object
  },
  data() {
      return {
        tab: 0,
        loaded: true,
        selectMsg: false      
      }
  }
}
</script>

<style lang="scss">
.report-container {
  margin-top: 10px;
  height: 100%;
}

.report-container-inner {
  margin: 10px;
}

.chart-container {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}
</style>