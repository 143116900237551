import fetchRQ from '../../../plugins/data-request'
import fetchGetRQ from '../../../plugins/data-get-requests'


const _wireless_headers = [
    { id: 'zone', text: 'Zone', sortable: true, order: 1},
    { id: 'number', text: 'Number of Devices', sortable: true, order: 2},
    { id: 'owncapcity', text: '% from copacity', sortable: true, order: 3 },
    { id: 'percent', text: '% from total', sortable: true, order: 4 },
    { id: 'action', text: 'Action', sortable: false, order: 5, align: 'right'},
]

const _wireless_period = [
    { id: 'hour', name: 'Hourly'}
    //{ id: 'halfhour', name: 'Half hour' }
]

export default {
    state: {
        period: _wireless_period,
        chart_data: {},
        is_wireless_insights_ready: false,
        list_wireless_data: { headers: [], values: []}
    },
    getters:{
        GET_WINSIGHTS_PERIOD: state => {
            return state.period
        },
        GET_CHART_DATA: state => {
            return state.chart_data
        },

        GET_LIST_WIRELESS_DATA_READY: state => {
            return state.is_wireless_insights_ready
        },
        GET_LIST_WIRELESS_DATA: state => {
            return state.list_wireless_data
        }
    },
    actions: {
        PREPARE_WINSIGHTS: async (context) => {
            await context.dispatch('CREATE_VENUE_ZONES_LIST', null, { root: true })
        },
        PREPARE_WINSIGHTS_DATA: async (context, search) => {

            let _list = { headers: [], values: []}
            let _venue = context.rootGetters.CURRENT_VENUE

            let request = { venueId: _venue.id, zones: search.zones, period: search.period, date: search.selectedDate }
            let insights = await fetchRQ(`counter/wireless/insights`, request)

            console.log(insights)

            if(insights != null && insights.valid && insights.message.isValid && insights.message.data != null){

                var _insights = insights.message.data

                _insights.items.forEach(item => {
                    var _elem = {id: item.id, number: item.numberDevices, owncapcity: item.zoneCopacity + "%", zone: item.name, percent: item.venueCopacity + "%", selected: true }
                    _list.values.push(_elem)
                })

                _list.headers = await context.dispatch('GENERATE_WIRELESS_DATA_LIST_HEADER', null, { root: true })
                await context.dispatch('GENERATE_CHART', insights.message.data, { root: true })
                
                context.commit('SET_LIST_WIRELESS_DATA', _list)
                context.commit('SET_LIST_WIRELESS_DATA_READY', true)
            }
            
        },
        GENERATE_CHART: async (context, data) => {

            let _series = []
            let _categories = data.periods.sort()

            data.zones.forEach(zone => {
                var _data = []
                _categories.forEach(period => {
                    _data.push(zone.date[period].count)
                })
                let _item = { name: zone.name, data: _data }
                _series.push(_item)
            })

            let chartData = {
                chart: {
                    type: 'area'
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    area: {
                        pointStart: 0,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    categories: _categories
                },
                yAxis: {
                    title: {
                        text: 'Number of devices'
                    },
                    labels: {}
                },
                series: _series
            }

            context.commit('SET_CHART_DATA', chartData)
        },
        DELETE_ITEM_CHART: async (context, item) => {
            var chartData = context.rootGetters.GET_CHART_DATA

            chartData = chartData.filter(function(_item) {
                return _item.id !== item.id
            })

            context.commit('SET_CHART_DATA', chartData)
        },
        GENERATE_WIRELESS_DATA_LIST_HEADER: async (context) => {
            let headers = []

            _wireless_headers.forEach(header => {
                headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return headers;
        }
    },
    mutations: {
        SET_CHART_DATA: (state, value) => {
            state.chart_data = value
        },
        SET_LIST_WIRELESS_DATA_READY: (state, value) => {
            state.is_wireless_insights_ready = value
        },
        SET_LIST_WIRELESS_DATA: (state, value) => {
            state.list_wireless_data = value
        }
    }
}