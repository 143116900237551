<template>
<div>
  <h1>Apps</h1>
  <v-btn color="primary" @click="createDialog = true"
        >Create App</v-btn>

<v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Created</th>
            <th class="text-left">Key</th>
            <th class="text-left">Secret</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in apps" :key="item.id">
            <td>{{item.name}}</td>
            <td>{{item.createdDate}}</td>
            <td>{{item.keySecret.key}}</td>
            <td>{{item.keySecret.secret}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>


<v-dialog
    v-model="createDialog"
    width="300"
    @keydown.esc="close()"
    @keydown.enter="close()"
  >
    <v-card style="height: 300px;">
      <v-card-title class="headline grey lighten-2" primary-title
        >Create App</v-card-title
      >

        <v-card-text>
            <v-text-field v-model="appName" label="Name"></v-text-field>
        </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="create()">Create</v-btn>
        <v-btn @click="createDialog=false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
data() {
    return {
      apps: [],
      createDialog: false,
      appName: ""
    };
  },
  watch: {
      createDialog() {
          if(!this.createDialog) {
              // clear the dialog
              this.appName = "";
          }
      }
  },
  mounted() {
      this.load();
  },
  methods: {
      load() {
          this.axios.get("app/" + this.$route.params.clientId).then(response => {
              this.apps = response.data;
          })
      },
      create() {
          this.axios.post("app",{ name: this.appName, clientId: this.$route.params.clientId})
          .then(response => {
              this.load();
              this.createDialog = false;
          })
      },
      close() {
          this.createDialog = false;
      }
  }
}
</script>

<style>

</style>