

export default {
    state: {
        modal_status: { locations: false, edit: false, venue_settings: false, venue_floors: false, venue_zone: false, venue_alerts: false, winsights:false, wheatmap: false }
    },
    getters:{
        GET_MODAL_STATUS: state => {
            return state.modal_status
        },
    },
    mutations: {
        SET_MODAL_STATUS: (state, value) => {
            state.modal_status[value.target] = value.value
        },
    }
}