<template>
    <div>
        <v-dialog @keydown.esc="close()" @keydown.enter="close()" v-model="value" width="500">
            <div class="dialog-content">
            <div class="dialog-form">
                <div class="dialog-field">
                    <v-text-field v-model="device.name" label="Name"></v-text-field>
                </div>
                <div class="dialog-field">
                    <v-text-field v-model="device.decowaveId" label="Decowave ID"></v-text-field>
                </div>
                <div class="dialog-field select">
                    <v-select v-model="device.type" :items="GET_SLOT_TYPES" item-text="text" item-value="type" label="Device type" dense></v-select>
                </div>
            </div>
            <div class="dialog-buttons">
                <div class="btn-left"><v-btn color="primary" @click="updateDevice()">Update</v-btn></div>
                <div class="btn-left"><v-btn @click="value = false">Cancel</v-btn></div>
            </div>
            </div>
        </v-dialog>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import DeviceService from "../../sdks/deviceApiService";

export default {
  props: {
    value: Boolean,
    current: { name: null, decowaveId: null, type: 0 }
  },
  data() {
    return {
        device: { name: null, decowaveId: null, type: 0 }
    };
  },
  computed:{
      ...mapGetters(['GET_SLOT_TYPES'])
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    current(){
        this.device = this.current
    }
  },
  methods: {
    updateDevice(){
      DeviceService.update(this.device).then(response => {
        this.$emit("input", false);
        this.$emit("reload");
      })
      .catch(error => {
        console.log(error.response.data);
      });
    }
  }
};
</script>