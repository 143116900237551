<template>
  <div class="tab-content-block">
    <div class="info-box" v-if="!IS_VENUE_SELECTED">
      <div class="notification">
        <div class="inner"><v-icon dark>mdi-cancel</v-icon> To view the data, please select a venue</div>
      </div>
    </div>
    <div class="table-list" v-else>
      <div class="inner" v-if="IS_DLIST_LOADED">
        <div class="list-box">
          <template>
            <v-data-table :headers="DEVICE_LOCATIONS_LIST.headers" :items="DEVICE_LOCATIONS_LIST.values" :items-per-page="10">
                <template v-slot:item.location="{item}">{{item.location.lat}} / {{item.location.lng}}</template>
                <template v-slot:item.action="{item}">
                    <v-btn class="mr-1" v-if="item.isAssigned" @click="unassign(item)"><v-icon small>mdi-link-off</v-icon></v-btn>
                    <v-btn class="mr-1" v-if="!item.isAssigned" @click="assign(item)"><v-icon small>mdi-link</v-icon></v-btn>
                    <v-btn class="mr-1" @click="remove(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                    <v-btn class="mr-1" @click="edit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                </template>
            </v-data-table>
          </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" @click="add()">Add a location</v-btn>
          <v-btn color="secondary" @click="showAll()">Show locations</v-btn>
        </div>
        <!--<alloc-device v-model="assignModal" :venueId="CURRENT_VENUE.id" :clientId="CURRENT_VENUE.clientId" :deviceLocId="selectedId" :type="selectedType"></alloc-device>-->
      </div>
      <div class="loader-box" v-if="!IS_DLIST_LOADED && IS_VENUE_SELECTED">
        <div class="no-data-box">
          <div class="text">The venue do not have any device location</div>
          <div class="notice-btn"><v-btn class="white middle" @click="add()">Add a location</v-btn></div>
        </div>
      </div>
      <create-location v-model="GET_MODAL_STATUS.edit" :venueId="CURRENT_VENUE.id" :clientId="CURRENT_VENUE.clientId"></create-location>
      <show-locations v-model="GET_MODAL_STATUS.locations" :venueId="CURRENT_VENUE.id" :clientId="CURRENT_VENUE.clientId"></show-locations>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateLocation from './create-location';
import ShowLocations from './show-locations'

export default {
  components: { CreateLocation, ShowLocations },
  data() {
    return {
      addLocation: false,
      assignModal: false,
      showLocations: false,
      selectedId: null,
      selectedType: null
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'IS_VENUE_SELECTED', 'DEVICE_LOCATIONS_LIST', 'IS_DLIST_LOADED', 'IS_CHANGE_LOCATION', 'GET_MODAL_STATUS'])
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
        this.$store.dispatch('GET_DEVICE_LOCATIONS').then((response) => {
          if(this.IS_DLIST_LOADED){
            this.$store.dispatch('GET_DLOCATIONS_HEADERS')
          }
        })
        this.$store.dispatch('INIT_DEVICEMAP').then((response) => {
          this.$store.commit('SET_DEVICEMAP_READY', true)
        })
    }
  },
  mounted() {
    if(this.IS_VENUE_SELECTED){
        this.$store.dispatch('GET_DEVICE_LOCATIONS').then((response) => {
          if(this.IS_DLIST_LOADED){
            this.$store.dispatch('GET_DLOCATIONS_HEADERS')
          }
        })
        this.$store.dispatch('INIT_DEVICEMAP').then((response) => {
          this.$store.commit('SET_DEVICEMAP_READY', true)
        })
    }
  },
  methods: {
    add(){
      this.$store.commit('SET_CHANGE_LOCATION', false)
      this.$store.commit('SET_MODAL_STATUS', { target: "edit", value: true}) 
    },
    showAll(){
      this.$store.commit('SET_MODAL_STATUS', { target: "locations", value: true}) 
    },
    assign(item) {
        this.selectedId = item.id;
        this.selectedType = item.slotType
        this.assignModal = true;
    },
    unassign(item) {
      DeviceLocService.unassignDevice(
        this.CURRENT_VENUE.clientId,
        this.CURRENT_VENUE.id,
        item.id,
        item.assignedDevice
      ).then(
        this.locations.find(x => x.id == item.id).assignedDevice = null
      )
    },
    remove(item){
      this.$store.dispatch('DELETE_LOCATION', item)
    },
    edit(item){
      this.$store.dispatch('SHOW_DLOCATION', item.id)
      this.$store.commit('SET_MODAL_STATUS', { target: "edit", value: true}) 
    }
  }
};
</script>