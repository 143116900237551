<template>
  <div class="padding-body">
    <div class="table-list">
      <div class="inner">
        <div class="list-box">
            <template>
              <div class="box-list-search">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </div>
              <v-data-table :headers="GET_ALL_DEVICES.headers" :items="GET_ALL_DEVICES.values" :search="search" :items-per-page="15">
                  <template v-slot:item.action="{item}">
                      <v-btn class="mr-1" v-if="item.assigned" @click="unassign(item)" alt="Assigned"><v-icon small>mdi-link-off</v-icon></v-btn>
                      <v-btn class="mr-1" v-if="!item.assigned" @click="assign(item)" alt="Not Assigned"><v-icon small>mdi-link</v-icon></v-btn>
                      <v-btn class="mr-1" @click="remove(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                      <v-btn class="mr-1" @click="edit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                  </template>
              </v-data-table>
            </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" v-if="!GET_PARTNER_MODE.value" @click="createModal=true">Add a device</v-btn>
        </div>
        <create-device v-model="createModal" v-on:reload="reload"></create-device>
        <edit-device v-model="editModal" :current="selectedItem" v-on:reload="reload"></edit-device>
        <assign-device v-model="assignClient" :isPartnerMode="GET_PARTNER_MODE.value" :deviceId="selectedId" v-on:reload="reload"></assign-device>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

import CreateDevice from "./createDeviceModal";
import DeviceService from "../../sdks/deviceApiService";
import AssignDevice from "./assignDeviceModal";
import EditDevice from "./editDevice.vue"

export default {
  components: { CreateDevice, AssignDevice, EditDevice },
  data() {
    return {
      search: '',
      selectedItem: null,
      createModal: false,
      editModal: false,
      assignClient: false,
      selectedId: undefined
    }
  },
  computed:{
      ...mapGetters(['GET_ALL_DEVICES', 'GET_PARTNER_MODE'])
  },
  mounted() {
    this.$store.dispatch('DEFINE_PARTNER_MODE', this.$route.params.partnerId)
    this.reload()
  },
  methods: {
    reload(){
      this.$store.dispatch('CREATE_ALL_DEVICES_LIST')
    },
    remove(item){
      this.$store.dispatch('DELETE_DEVICE', item, { root: true })
    },
    edit(item){
      this.selectedItem = item
      this.editModal = true
    },
    unassign(item) {
      DeviceService.unassignDevice({ deviceId: item.id }).then(_ => {
        this.reload()
      });
    },
    assign(item) {
      this.assignClient = true;
      this.selectedId = item.id;
      this.reload()
    }
  }
};
</script>