import Vue from 'vue'
import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

const headers = [
    { id: 'name', text: 'Name', sortable: true, order: 1},
    { id: 'description', text: 'Description', sortable: true, order: 2},
    { id: 'created', text: 'Date Created', sortable: true, order: 3},
    { id: 'action', text: 'Action', sortable: false, order: 4, align: 'right'},
]

export default {
    state: {
        client_id: null,
        list_venues: { values: [], headers: [] },
        is_create_venue: true,
        is_settingsmap_ready: false
    },
    getters: {
        GET_CLIENT: state => {
            return state.client_id 
        },
        GET_LIST_VENUES: state => {
            return state.list_venues
        },
        GET_VENUE_CREATE: state => {
            return state.is_create_venue
        },
        SETTINGSMAP_READY: state => {
            return state.is_settingsmap_ready
        }
    },
    actions: {
        CREATE_CLIENT_LIST_VENUES: async (context) => {
            
            let clientId = context.state.client_id
            let venues = await Vue.axios.get(`client/${clientId}/venue`);

            let _venues = { values: [], headers: []}

            if(venues != null && venues.data.length > 0) {
                
                venues.data.forEach(venue =>{
                    let _description = venue.description != null ? venue.description : ""
                    let _created = venue.created != null ? venue.created : new Date().toLocaleDateString("en-US")
                    _venues.values.push({ id: venue.id, name: venue.name, description: _description, created: _created })
                })

                _venues.headers = await context.dispatch('CREATE_CLIENT_LIST_VENUES_HEADERS', null, { root: true })
                context.commit('SET_LIST_VENUES', _venues)
            }
        },
        CREATE_CLIENT_LIST_VENUES_HEADERS: async (context) => {

            let _headers = []

            headers.forEach(header =>{
                _headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return _headers;
        }
    },
    mutations: {
        SET_CLIENT: (state, value) => {
            state.client_id = value
        },
        SET_LIST_VENUES: (state, value) => {
            state.list_venues = value
        },
        SET_VENUE_CREATE: (state, value) => {
            state.is_create_venue = value
        },
        SET_SETTINGSMAP_READY: (state, value) => {
            state.is_settingsmap_ready = value
        }
    }
}