<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
      prepend-icon="mdi-domain"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="startdate"
          label="Start Date"
          prepend-icon="mdi-calendar-range"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="startdate" @input="menu = false"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startdate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false
    };
  },
  watch: {
      startdate() {
          this.$emit("date-update",this.startdate);
      }
  }
};
</script>

<style>
</style>