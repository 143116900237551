import Vue from 'vue';
import venueService from './../sdks/venueSdk';

export default {
    namespaced: true,
    state: {
        venues: null,
        clientId: null,
        currentVenue: {}
    },
    mutations: { 
        setClient(state,clientId) {
            state.clientId = clientId;
        },
        setVenues(state,venues) {
            state.venues = venues;
        },
        setCurrentVenue(state, currentVenue) {
            state.currentVenue = currentVenue;
        },
        update(state,venue) {
            var index = state.venues.findIndex(x => x.id == venue.id);
            Vue.set(state.venues,index,venue);
        },
        clear(state) {
            state.venues = [];
        }
    },
    actions: { 
        async load(context, clientId) {
            if(clientId !== context.state.clientId) {
                context.commit('clear');
                context.commit('setClient',clientId)
                var response = await venueService.getVenues(clientId);
                context.commit('setVenues',response.data);
            } else {
                if(context.state.venues) {
                    //console.error('Having to load venues after clientId already set. Did a call fail?');
                    var response = await venueService.getVenues(clientId);
                    context.commit('setVenues',response.data);
                }
            }

        },
        async update(context, venue) {
            var response = await venueService.updateVenueModel(venue);
            context.commit('update',response.data);
        },
        async addFloor(context,venue) {
           var response = await venueService.addFloor(venue);
           context.commit('update',response.data);
        },
        async uploadFile(context,request) {
           
           var response = await venueService.uploadFloorFile(request);
           context.commit('update',response.data);

        }
    },
    getters: { 
        getVenues: state =>  {
            if(state.clientId == null) return null;
            if(state.venues == null)  return null;
            return state.venues;
        },
        getVenue: state => (id) => {
            return state.venues.find(x => x.id === id);
        },
        getCurrentVenue: state =>{
            return state.currentVenue
        }
    }
  }