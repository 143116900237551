<template>
  <div style="width:100%;height:100%">
    <!-- <h3 v-if="!loaded&&!loading">No data to display</h3>

    <div style="height:10px">
       <v-progress-linear
       v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
    </div> -->

    <!-- :center.sync="center" -->
      <!-- v-on:load="onLoad" -->
    <MglMap
      :center="center"
      :zoom="17"
      container="map-test"
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :minZoom="4"
      v-on:load="onLoad"
    >
      <MglGeojsonLayer
        :source="geoJson"
        :layer="geoJsonLayer"
        :sourceId="sourceId"
        :layerId="layerId"
      />
    </MglMap>
     <!-- <v-snackbar v-model="snackbar">
      {{ snackText }}
      <v-btn color="red" text @click="snackbar = false">Close</v-btn>
    </v-snackbar> -->
  </div>
</template>

 

<script>
import { MglMap, MglGeojsonLayer } from "vue-mapbox";
import { log } from "util";
import moment from "moment";

const lines = {
        "features": [ ],
        "type": "FeatureCollection"
    };


export default {
  components: { MglMap, MglGeojsonLayer },
  props: {
    heatmap: Object,
    venue: Object
  },
  mounted() {
      const coords = this.venue.location.coordinates;
      this.center =  {"lng":coords[0], "lat": coords[1]};
  },
  watch: {
      venue: function(newVal, oldVal) {

      const coords = this.venue.location.coordinates;
      this.mapRef.actions
        .flyTo({
          center: coords
        })
        .then(_ => console.log("flew"))
        .catch(_ => console.log("error", _));

    },
    heatmap() {
      this.geoJson.data = this.heatmap;
    }
  },
  data() {
    return {
      loading: false,
      loaded: false,
      snackText: "An error occured",
      snackbar: false,
       mapRef: null,
      center: { lng: -0.63051223754882812, lat: 52.068758010864258 },
      timer: "",
      accessToken:
        "pk.eyJ1IjoiYWxleHdhcmRieSIsImEiOiJjazB4ajJ6ODgwNjEwM21xbWl3bjI4bmNrIn0.uoA6VRMGaOJUIY1EPkEf5g",
      mapStyle: "mapbox://styles/alexwardby/ck0xo4znv03gp1cqyuk4ufe3q",
      layerId: "water89",
      sourceId: "firstSource",
      geoJson: {
        type: "geojson",
        data: lines,
        lineMetrics: true
      },
      geoJsonLayer: {
        id: "water89",
        type: "heatmap",
        // source: "THING?"
        maxzoom: 20,
        paint: {
          // Increase the heatmap weight based on frequency and property magnitude
          "heatmap-weight": [
            "interpolate",
            ["linear"],
            ["get", "mag"],
            0,
            0,
            6,
            1
          ],
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            1,
            9,
            3
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(33,102,172,0)",
            0.2,
            "rgb(103,169,207)",
            0.4,
            "rgb(209,229,240)",
            0.6,
            "rgb(253,219,199)",
            0.8,
            "rgb(239,138,98)",
            1,
            "rgb(178,24,43)"
          ],
          // Adjust the heatmap radius by zoom level
          "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 1, 1,15,3, 16,7,17,15,18,30,19,60,21,100]
          // Transition from heatmap to circle layer by zoom level
          // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0]
        }
      }
    };
  },
  methods: {
       onLoad(item) {
      this.mapRef = item.component;
      this.mapRef.map.on('click', this.emitClick);
    },
  }
};

</script>


