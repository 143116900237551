<template>
  <div class="counter-report-container">
    <!-- <current-venue :filter="makeFilter()"></current-venue> -->
    <h3 style="color:red" v-if="error">There was an error</h3>
    <v-card style="display:flex;height:80vh;" v-if="!error">
      <v-row>
        <v-col cols="4">
          <div style="margin:5px;">
          <v-slider
            class="large-slider"
            vertical
            v-model="value"
            :max="numLabels"
            ticks="always"
            :tick-labels="tickLabels"
          ></v-slider>
          </div>
        </v-col>
        <v-col cols="8">
          <mapboxHeatmap v-if="report != null" :venue="venue" :heatmap="getHeatmapSlice()"></mapboxHeatmap>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import MapboxHeatmap from "../heatmaps/MapboxHeatmap";

const blankReport = {
  features: [],
  type: "FeatureCollection"
};

export default {
  components: {
    MapboxHeatmap
  },
  props: {
    report: null,
    venue: null
  },
  data() {
    return {
      value: 0,
      numLabels: 12,
      tickLabels: ["sdf", "ddd"],
      timeKeys: null,
      error: null
    };
  },
  mounted() {
    if(this.report != null) {
      this.tickLabels = this.report.timeKeys;
      this.numLabels = this.tickLabels.length;
    }
  },
  watch: {
    report() {
      this.tickLabels = this.report.timeKeys;
      this.numLabels = this.tickLabels.length;
    }
  },
  methods: {
    getHeatmapSlice() {
      if (this.value === 0 || this.report == null || this.tickLabels == null) {
        return blankReport;
      }
      var heatSlice = this.report.data[this.tickLabels[this.value]];
      heatSlice.features.forEach(element => {
        if (element.properties.mag > 0) {
        }
      });
      return heatSlice;
    }
  }
};
</script>

<style scoped>
.large-slider >>> .v-slider {
  height: 70vh;
}
</style>