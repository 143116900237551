<template>
  <div class="ma-4">
    <h1>Zones</h1>
    <zone-modal v-if="venueId !=null" v-on:submit="createZone"></zone-modal>

    <div v-if="noZones" class="ma-4">
      <h4>No Zones configured for venue</h4>
    </div>
    <div v-if="!noZones">
      <v-row style="max-width:1200px">
        <v-col v-for="item in zones" :key="item.name" lg="2">
          <zone-card :display-stats="displayStats" :zone="item"></zone-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ZoneModal from "./createZoneModal";
import VenueService from "../../sdks/venueService";
import ZoneCard from "./zoneCard";

export default {
  components: { ZoneModal, ZoneCard },
  data: () => {
    return {
      rawZones: [],
      zones: [],
      loaded: false,
      interval: null,
      displayStats: false,
      totalZone: null
    };
  },
  mounted() {
    this.loadZones();
    this.interval = setInterval(() => {
      this.loadZones();
    }, 5000);

    setInterval(() => {
      this.displayStats = !this.displayStats;
    }, 4000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getDisplayName(zone) {
      return !this.displayStats
        ? zone.name
        : `${zone.count} out of ${zone.capacity}`;
    },
    loadZones() {
      this.axios
        .get(
          "zone/client/" + this.$route.params.clientId + "/venue/" + this.CURRENT_VENUE.id
        )
        .then(response => {
          this.loaded = true;
          this.rawZones = response.data;

          this.zones = this.rawZones.map(z => this.transformZone(z));
          let totalCapacity = 0;
          let totalPeople = 0;
          this.rawZones.forEach(z => {
            totalCapacity += z.totalCapacity;
            totalPeople += z.count;
          });

          this.zones.unshift({
            isStation: true,
            name: this.CURRENT_VENUE.name,
            value: (100 * totalPeople) / totalCapacity,
            capacity: totalCapacity,
            count: totalPeople
          });


        });
    },
    transformZone(zone) {
      return {
        isStation: false,
        name: zone.name,
        value: zone.percentFilled * 100,
        capacity: zone.totalCapacity,
        count: zone.count,
        id: zone.id
      };
    },
    createZone(zone) {
      this.axios
        .post("zone", {
          name: zone.name,
          venueId: this.CURRENT_VENUE.id,
          clientId: this.$route.params.clientId,
          capacity: Number(zone.capacity),
          _discard: 12
        })
        .then(_ => this.loadZones());
    },
    innerText(val) {
      return val.toFixed(1) + "%";
    },
    makeColour(percent) {
      const slot = Math.floor(percent / 10);
      switch (slot) {
        case 0:
          return "#D8F2B1";
        case 1:
          return "#D8F2B1";
        case 2:
          return "#D8F2B1";
        case 3:
          return "#BDE97C";
        case 4:
          return "#A3E048";
        case 5:
          return "#CDD840";
        case 6:
          return "#f7d038 ";
        case 7:
          return "#F1A335";
        case 8:
          return "#eb7532";
        case 9:
          return "#E94E29";
        case 10:
          return "#e6261f";
        default:
          return "#e6261f";
      }

      /*
          green = #a3e048    (up to 50%) -> #CDD840
          yellow = #f7d038   (60 - 70) -> #F1A335  
          orange = #eb7532   (80 - 90) -> #E94E29
          red = #e6261f      (90 - 100)
          */

    }
  },
  computed: {
     ...mapGetters(['CURRENT_VENUE']),

    venueId: function(){
      return this.CURRENT_VENUE.id
    },
    noZones: function() {
      return this.loaded && this.rawZones.length === 0;
    }
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.venueId = this.CURRENT_VENUE.id
    },
    venueId() {
      this.loadZones();
    }
  }
};
</script>

<style>
.venue-level-card {
  /* background-color: wheat !important; */
  border: 4px solid lightblue !important;
}
</style>