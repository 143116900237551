<template>
  <div class="padding-body">
    <div class="table-list">
      <div class="inner">
        <div class="list-box">
            <template>
              <v-data-table :headers="GET_LIST_VENUES.headers" :items="GET_LIST_VENUES.values" :items-per-page="15">
                  <template v-slot:item.action="{item}">
                      <v-btn class="mr-1" @click="remove(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                      <v-btn class="mr-1" @click="edit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                  </template>
              </v-data-table>
            </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" @click="startCreateFlow()">Add a venue</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      inviteOpen: false,
    };
  },
  computed:{
      ...mapGetters(['GET_LIST_VENUES'])
  },
  methods: {
    startCreateFlow: function() {
        this.$router.push({path: './venue/create'});
    },
    edit: function(item) {
      this.$router.push({path: `./venue/${item.id}/edit`});
    }

  },
  mounted() {
    this.$store.commit('SET_CLIENT', this.$route.params.clientId);
    this.$store.dispatch('CREATE_CLIENT_LIST_VENUES')
  },
};
</script>

<style>
</style>