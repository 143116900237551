<template>
<div>
  <v-card class="report-container" v-if="loaded">
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab :key="1">Graph</v-tab>
      <v-tab v-show="!hideHeatmap" :key="2" >Heatmap</v-tab>
      <!-- <v-tab :key="3" disabled>Data Table</v-tab> -->

      <v-tab-item :key="1">
        <v-card class="report-container-inner" flat tile>
          <v-progress-linear v-if="loading" indeterminate color="yellow darken-2"></v-progress-linear>
          <div class="chart-container">
            <line-chart
            v-if="tab===0"
              :chart-data="dataCollection"
              :options.sync="options"
            ></line-chart>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2">
        <v-card class="report-container-inner" flat tile>
          <v-progress-linear v-if="loading" indeterminate color="yellow darken-2"></v-progress-linear>
          <HeatmapContainer 
          :report="dataCollection"
          :venue="this.CURRENT_VENUE"
          ></HeatmapContainer>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item :key="3">
        <v-card class="report-container-inner" flat tile>
          <my-table :tabledata="datacollection"></my-table>
        </v-card>
      </v-tab-item> -->

      
    </v-tabs>
  </v-card>
    <h1 v-if="selectMsg">Select a Venue</h1>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from "./LineGraph";
import MyTable from "./counterTable";
import VenueService from "../../sdks/venueService";
import HeatmapContainer from "./heatmapContainer";

export default {
  components: {
    LineChart,
    MyTable,
    HeatmapContainer
  },
  
  props: {
    filter: Object
  },
  watch: {
    filter: {
      deep: true,
      handler() {

        if(this.filter.mode === 1) {
          this.hideHeatmap = true;
          this.tab = 0;
        } else {
          this.hideHeatmap = false;
        }
        this.loadData();
      }
    },
    venueService: {
      deep: true,
      handler() {
        this.loaded = false;
        this.loadData();

        this.options.title = {
          text: this.CURRENT_VENUE.name,
          display: false
        }
      }
    },
    tab() {
      this.loadData();
    }
  },
  data() {
    return {
      loading:  true,
      hideHeatmap: false,
      loaded: false,
      selectMsg: false,
      tab:0,
      dataCollection: {},
      venueService: VenueService,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: `Locations`,
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "No. of Contacts"
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Hour"
              }
            }
          ]
        }
      }
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE'])
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setResolutionStr() {
      let resStr = ""
        switch(this.filter.res) {
          case 1: resStr = "Half Hour"; break;
          case 2: resStr = "Hours"; break;
          case 3: resStr = "Days"; break;
          default: resStr = "Unrecognised";
        }
        this.options.scales.xAxes[0].scaleLabel.labelString = resStr;
    },
    loadData() {
      if(this.filter.mode === 0) {
        this.getCountReading();
      }

      if(this.filter.mode === 1) {
        this.getVenueReadings();
      }
    },
    getVenueReadings() {
      this.selectMsg = false;
      this.loading = true;

      this.axios
        .post("counter/report/venue", {
          clientId: this.$route.params.clientId,
          resolution: this.filter.res,
          start: this.filter.startTime,
          end: this.filter.endTime
        })
        .then(response => {
          this.loading = false;
          if (response.data === "no_data") {
            this.loaded = false;
            // this.snackText = "No Data found"
            // this.snackbar = true;
          } else {
            this.setResolutionStr();
            this.loaded = true;
            
            this.dataCollection = response.data;
          }
        })
        .catch(error => {
          this.loading = false;
          this.loaded = false;
          this.snackText = "Error getting data";
          this.snackbar = true;
        });
    },
    getCountReading() {
      if( this.CURRENT_VENUE == null) {
        this.selectMsg = true;
        return;
      } 
      this.selectMsg = false;
      this.loading = true;

      this.axios
        .post(`/counter/${this.$route.params.clientId}/report`, {
          venueId: this.CURRENT_VENUE.id,
          resolution: this.filter.res,
          start: this.filter.startTime,
          end: this.filter.endTime,
          format: this.tab === 1 ? 2 : 1,
          asPercentOfMax: true
        })
        .then(response => {
          this.loading = false;
          if (response.data === "no_data") {
            this.loaded = false;
            // this.snackText = "No Data found"
            // this.snackbar = true;
          } else {
            this.setResolutionStr();
            this.loaded = true;
            this.dataCollection = response.data;
          }
        })
        .catch(error => {
          this.loading = false;
          this.loaded = false;
          this.snackText = "Error getting data";
          this.snackbar = true;
        });
    }
  }
};
</script>

<style lang="scss">
.report-container {
  margin-top: 10px;
  height: 100%;
}

.report-container-inner {
  margin: 10px;
}

.chart-container {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}
</style>
