import fetchRQ from '../../../plugins/data-request'
import fetchGetRQ from '../../../plugins/data-get-requests'


export default {
    state: {
        wireless_heat_ready: false,
        wireless_heatmap_data: {}
    },
    getters: {
        WIRELESS_HEATMAP_READY: state => {
            return state.wireless_heat_ready
        },
        GET_WIRELESS_HEATMAP: state => {
            return state.wireless_heatmap_data
        }
    },
    actions: {
        CREATE_WIRELESS_HEATMAP: async (context, range) => {        
            
            let _floor = context.rootGetters.GET_SELECTED_FLOOR
            let _venueID = context.rootGetters.CURRENT_VENUE.id
            let _type = context.rootGetters.GET_SELECTED_DURATION.labelId

            let request = { venueid: _venueID, floor: _floor, type: _type == 1 ? "hour" : "day", fulldatetime: range}

            await fetchRQ(`counter/wireless/heatmap`, request).then((data) => {
                let geoJson = { type: "geojson", data: { id: "WirelessHeatMap", type: "FeatureCollection", features: []} }
                if(data.valid){
                    if(data.message.isValid){
                        let features = { ... JSON.parse(JSON.stringify(data.message.data)), id: "WirelessHeatMap"}
                        geoJson = { type: "geojson", data: features }
                    }              
                }
                context.commit('SET_WIRELESS_HEATMAP_DATA', geoJson)
            })
        },     
    },
    mutations: {
        SET_WHEATMAP_READY: (state, value) => {
            state.wireless_heat_ready = value
        },
        SET_WIRELESS_HEATMAP_DATA: (state, value) => {
            state.wireless_heatmap_data = value
        }
    }
}