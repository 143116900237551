<template>
  <div class="px-6">
    <!-- <h1>Footfall pricing</h1> -->

    <v-card-text class="ma-0">
      <v-row align="center" justify="center" class="flex-column">
        <v-btn-toggle v-model="term" rounded>
          <v-btn>1 Year</v-btn>
          <v-btn>3 Year</v-btn>
          <v-btn>5 year</v-btn>
        </v-btn-toggle>
        <p>Discounts apply for longer terms</p>
      </v-row>
    </v-card-text>

    <v-row>
      <v-col cols="3">
        <b>Product</b>
      </v-col>
      <v-col cols="2">
        <b>Quantity</b>
      </v-col>
      <v-col cols="2">
        <b>Price Per Device</b>
      </v-col>
      <v-col cols="2">
        <b>Annual License Per Device</b>
      </v-col>
      <v-col cols="2">
        <b>Total</b>
      </v-col>
    </v-row>

    <product-line
      v-for="product in products"
      :product="product"
      :key="product.name"
      v-on:update="update"
    ></product-line>

    <v-row justify="end">
      <v-col cols="2">
        <v-text-field class="font-weight-bold" v-model="counterTotal"></v-text-field>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="3">
        <v-btn @click="$emit('next')">Next</v-btn>
        <v-btn @click="$emit('checkout')" color="primary">Go to Checkout</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductLine from "./productLine";

export default {
  components: { ProductLine },
  props: {
    order: Object
  },
  data: () => {
    return {
      term: 0,
      numTotal: 0,
      products: [
        {
          name: "People Counter (Outdoors)",
          pricePerUnit: 875 *1.3,
          liscencePrice: 395 *1.3,
          discount: 0,
          quantity: 0,
          total: 0,
          title: "one year",
          years: 1
        },
        {
          name: "People Counter (Indoors)",
          pricePerUnit: 550 *1.3,
          liscencePrice: 395 *1.3,
          discount: 0,
          quantity: 0,
          total: 0,
          title: "one year",
          years: 1
        },
        {
          name: "Wireless Counter (Outdoors)",
          pricePerUnit: 595 *1.3,
          liscencePrice: 265 *1.3,
          discount: 0,
          quantity: 0,
          total: 0,
          title: "one year",
          years: 1
        },

        {
          name: "Wireless Counter (Indoors)",
          pricePerUnit: 395 *1.3,
          liscencePrice: 265 *1.3,
          discount: 0,
          quantity: 0,
          total: 0,
          title: "one year",
          years: 1
        }
      ]
    };
  },
  watch: {
    term() {
      switch (this.term) {
        case 0:
          this.setTerm("one year", 0,1);
          break;
        case 1:
          this.setTerm("three year", 0.2,3);
          break;
        case 2:
          this.setTerm("five year", 0.35,5);
          break;
        default:
          break;
      }
    }
  },
  methods: {
    setTerm(title, discount,years) {
      this.products.forEach(p => {
        p.title = title;
        p.discount = discount;
        p.years = years
        this.recalculateLine(p);
      });
      this.emitUpdate();
    },
    recalculateLine(line) {
      const totalLineDevicePrice = line.pricePerUnit * line.quantity;
      const totalLiscPrice = line.quantity * (1 - line.discount) * line.liscencePrice * line.years;
      line.total = Math.floor(totalLineDevicePrice+totalLiscPrice);
    },
    update(productUpdate) {
      this.products.forEach(p => {
        if (p.name === productUpdate.name) {
          p.quantity = productUpdate.quantity;
          this.recalculateLine(p);
        }
      });
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit("update", {
        products: this.products,
        termTitle: this.products[0].title,
        termDiscount: this.products[0].discount,
        total: this.counterTotal,
        numTotal: this.numTotal
      });
    }
  },
  computed: {
    counterTotal() {
      let total = 0;
      this.products.forEach(p => (total += p.total));
      this.numTotal = total;
      return `£ ${total}`;
    }
  }
};
</script>

<style>
</style>