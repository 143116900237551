<template>
  <div>
    <v-btn @click="value=true">
      <v-icon>{{buttonIcon}}</v-icon>
    </v-btn>
    <v-dialog @keydown.esc="close()" @keydown.enter="close()" v-model="value" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Select Zone</v-card-title>

        <v-form>
          <v-switch v-model="inOutToggle" :label="direction"></v-switch>
          <v-select :items="zones" label="Zone" v-model="zoneId" item-text="name" item-value="id"></v-select>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="select()">Ok</v-btn>
          <v-btn @click="value = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    deviceId: String,
    buttonIcon: String,
    zones: Array
  },
  data: () => {
    return {
      value: false,
      name: "",
      selectedZone: null,
      zoneId: null,
      inOutToggle: true
    };
  },
  methods: {
    select() {

      const selectedZone = this.zones.find(a => a.id === this.zoneId);

      this.$emit("selected", {
        zoneId: this.zoneId,
        deviceId: this.deviceId,
        direction: this.inOutToggle,
        zoneName: selectedZone.name
      });
      this.value = false;
    }
  },
  computed: {
    direction() {
      return this.inOutToggle ? 'In' : 'Out'
    }
  },
  watch: {
    value() {
      // if(this.value) {
      //     this.loadZones();
      // }
    }
  }
};
</script>

<style>
</style>