import Vue from 'vue'
import axios from 'axios'

class TourApiService {

    postTour(request, clientId) {
        return Vue.axios.post(`client/${clientId}/tour`, request);
    }

    getTours(clientId) {
        return Vue.axios.get(`client/${clientId}/tour/`);
    }

    getTour(clientId, venueId) {
        return Vue.axios.get(`client/${clientId}/tour/venue/${venueId}`);
    }

    getCustomerTour(clientId, venueId, type) {
        return Vue.axios.get(`client/${clientId}/tour/venue/${venueId}/${type}`);
    }
}

var sdk = new TourApiService();
export default sdk;