<template>
  <v-container>
    <v-row justify="center">
      <v-col md="10" lg="8">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-simple-table>
              <tr>
                <th><h1>Add floor</h1></th>
              </tr>
            </v-simple-table>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12">
                <v-form >
                  <v-container>
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorHeightM" label="Floor Height"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorWidthM" label="Floor Width"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorDepthM" label="Floor Depth"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorBottomLeftCornerLong" label="Bottom Left Corner Longitude"></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorBottomLeftCornerLat" label="Bottom Left Corner Latitude"></v-text-field>
                      </v-col>
                    </v-row>  
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorTopRightCornerLong" label="Top Right Corner Longitude"></v-text-field>
                      </v-col>
                    </v-row>    
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorTopRightCornerLat" label="Top Right Corner Latitude"></v-text-field>
                      </v-col>
                    </v-row>      

                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorTopLeftCornerLong" label="Top Left Corner Longitude"></v-text-field>
                      </v-col>
                    </v-row> 
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorTopLeftCornerLat" label="Top Left Corner Latitude"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorBottomRightCornerLong" label="Top Bottom Right Longitude"></v-text-field>
                      </v-col>
                    </v-row> 
                    <v-row justify="center" cols="12">
                      <v-col md="10" lg="6" cols="12">
                        <v-text-field v-model="floorBottomRightCornerLat" label="Top Bottom Right Latitude"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>

              <v-btn color="primary" @click="createFloor()">Create</v-btn>

              <v-btn @click="cancel()" text>Cancel</v-btn>
              
            </v-stepper-content>
          </v-stepper-items>

            
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateBasicStep from "./createBasicStep";
import PlacementStep from "./createPlacementStep";
import Mapbox from "../../shared/mapbox";
import VenueSdk from "../../../sdks/venueSdk";
import VenueService from "../../../sdks/venueService";

export default {
  components: {
    CreateBasicStep,
    Mapbox,
    PlacementStep
  },
  data() {
    return {
      e1: 1,
      zoom: 18,
      name: "",
      locations: [],
      lat: 0,
      lng: 0
    };
  },
  methods: {
    cancel: function() {
      this.$router.push({path: `../edit`});
    },
    createFloor() {    
      VenueSdk.postFloor( {
          clientId: this.$route.params.clientId,  
          venueId: this.$route.params.venueId,
          floorHeightM: this.floorHeightM,
          floorWidthM: this.floorWidthM,
          floorDepthM: this.floorDepthM,
          floorBottomLeftCornerLong: this.floorBottomLeftCornerLong,
          floorBottomLeftCornerLat: this.floorBottomLeftCornerLat,
          floorTopRightCornerLong: this.floorTopRightCornerLong,
          floorTopRightCornerLat: this.floorTopRightCornerLat,
          floorTopLeftCornerLong: this.floorTopLeftCornerLong,
          floorTopLeftCornerLat: this.floorTopLeftCornerLat,
          floorBottomRightCornerLong: this.floorBottomRightCornerLong,
          floorBottomRightCornerLat: this.floorBottomRightCornerLat
      })      
      .then( request => {
              VenueSdk
                .getVenues(this.$route.params.clientId)
                .then(venues => VenueService.setVenues(venues.data));
        this.$router.push({path: `../../../venue`});
      });
    }
  }
};
</script>