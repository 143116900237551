<template>
  <div>
    <v-card elevation="12">
      <div class="counterfilter-header-bar d-flex justify-space-around">
        <div class="filter-container" style="display: flex;align-items: center;">
          <v-row >
            <v-col cols="12" >
              <v-btn-toggle v-model="modeToggle" class="filter-input">
                <v-btn small>Current Venue</v-btn>
                <v-btn small>Venue vs Venue</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </div>

        <div class="filter-container">
          <date-filter v-on:date-change="handleDate"></date-filter>
        </div>

        <div class="filter-container">
          <res-filter v-model="resLabel" v-on:set-res="setRes"></res-filter>
        </div>
      </div>
    </v-card>
    <div class="counter-report-container">
      <current-venue :filter="makeFilter()"></current-venue>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentVenue from "./currentVenue";
import ResFilter from "../shared/resolutionFilter";
import DateFilter from "../shared/dateFilter";
import moment from "moment";

export default {
  components: {
    CurrentVenue,
    ResFilter,
    DateFilter
  },
  data() {
    return {
      modeToggle: 0,
      resLabel: "Hour",
      resToggle: 2,
      startTime: null,
      endTime: null
    };
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE'])
  },
  methods: {
    setRes(resolution) {
      this.resToggle = resolution;
    },
    handleDate(thing) {
      this.startTime = thing.start;
      this.endTime = thing.end;
      this.resLabel = thing.res;
    },
    makeFilter() {
      return {
        startTime: this.startTime,
        endTime: this.endTime,
        res: this.resToggle,
        mode: this.modeToggle
      };
    }
  }
};
</script>

<style lang="scss">
.counter-report-container {
  height: 75vh;
}

.counterfilter-header-bar {
  display: flex;
  justify-content: space-around;
  max-width: 1100px;
}

.filter-container {
  max-width: 200px;

  .col-12 {
    padding: 0px;
  }
}

.filter-title {
}

.filter-input {
}
</style>
