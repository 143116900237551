<template>
  <div class="inner_body padding">
    <add-partner v-on:reload="load"></add-partner>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in partners" :key="item.id">
            <td>{{item.name}}</td>
            <td>
              <!-- <v-btn @click="invitePartner(item)">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn> -->
              <v-btn @click="manage(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <AddClient v-model="addDialog" v-on:reload="reload"></AddClient>
    <Invite v-model="inviteDialog" :client="selectedClient"></Invite>
  </div>
</template>

<script>
import AddPartner from "./addPartnerModal";

export default {
  components: {
    AddPartner
  },
  data: function() {
    return {
      partners: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.axios
        .get("partner")
        .then(response => (this.partners = response.data));
    },
    manage: function(partner) {
      this.$router.push({ path: `/partner/${partner.id}/clients` });
    }
  }
};
</script>

<style>
</style>