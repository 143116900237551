<template>
  <v-card
    style="padding:15px;text-align:center;min-height:240px"
    :elevation="zone.isStation ? 6 : 2"
    :class="[
      zone.isStation ? 'venue-level-card' : '',
    ]"
  >
     <zone-adjust v-if="!zone.isStation" :zoneId="zone.id"></zone-adjust>
     <div class="adjust-filler" v-if="zone.isStation"></div>
    <v-progress-circular
      :color="makeColour(zone.value)"
      :size="100"
      :width="15"
      :value="zone.value"
    >{{innerText(zone.value)}}</v-progress-circular>

    <h3 class="ma-2">{{getDisplayName(zone)}}</h3>
  </v-card>
</template>

<script>
import ZoneAdjust from "./zoneAdjustBar";

export default {
  components: { ZoneAdjust },
  props: {
    displayStats: false,
    zone: null
  },
  methods: {
    getDisplayName(zone) {
      return !this.displayStats
        ? zone.name
        : `${zone.count} out of ${zone.capacity}`;
    },
    innerText(val) {
      return val.toFixed(1) + "%";
    },
    makeColour(percent) {
      const slot = Math.floor(percent / 10);

      /*
          green = #a3e048    (up to 50%)
          #CDD840
          yellow = #f7d038   (60 - 70)
->        #F1A335  
          orange = #eb7532   (80 - 90)
->         #E94E29
          red = #e6261f      (90 - 100)
          */

      switch (slot) {
        case 0:
          return "#D8F2B1";
        case 1:
          return "#D8F2B1";
        case 2:
          return "#D8F2B1";
        case 3:
          return "#BDE97C";
        case 4:
          return "#A3E048";
        case 5:
          return "#CDD840";
        case 6:
          return "#f7d038 ";
        case 7:
          return "#F1A335";
        case 8:
          return "#eb7532";
        case 9:
          return "#E94E29";
        case 10:
          return "#e6261f";
        default:
          return "#e6261f";
      }
    }
  }
};
</script>

<style>
.venue-level-card {
  /* background-color: wheat !important; */
  border: 4px solid lightblue !important;
}

.adjust-filler {
  height: 36px;
}
</style>