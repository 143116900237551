<template>
  <div class="view-map dashboard" v-if="DMAP_READY">
    <div class="map-inner">
      <mapview :markers="VENUE_MARKERS" :jumpTo="GET_JUMPER"></mapview>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from './shared/mapview'

export default {
  components: { Mapview },
  data: () => ({}),
  async beforeMount() {
    this.$store.dispatch('INIT_VENUES').then((response) => {
      this.$store.commit('SET_MAP_READY', true)
    })
  },
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'VENUE_MARKERS', 'DMAP_READY', 'GET_JUMPER'])
  },
  methods: {}
}
</script>