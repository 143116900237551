<template>
<div>
  <v-dialog @keydown.esc="close()" @keydown.enter="close()" v-model="value" width="500">
    <div class="dialog-content">
      <div class="dialog-form">
        <div class="dialog-field">
          <v-text-field v-model="name" label="Name"></v-text-field>
        </div>
        <div class="dialog-field">
          <v-text-field v-model="decowaveId" label="Decowave ID"></v-text-field>
        </div>
        <div class="dialog-field">
          <v-text-field v-model="manualId" label="Manual System ID" hint="(Optional)" persistent-hint></v-text-field>
        </div>
        <div class="dialog-field select">
          <v-select v-model="slotType" :items="GET_SLOT_TYPES" item-text="text" item-value="type" label="Device type" dense></v-select>
        </div>
      </div>
      <div class="dialog-buttons">
        <div class="btn-left"><v-btn color="primary" @click="addDevice()">Ok</v-btn></div>
        <div class="btn-left"><v-btn @click="value = false">Cancel</v-btn></div>
      </div>
      <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">{{ text }}</v-snackbar>
    </div>
  </v-dialog>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceService from "../../sdks/deviceApiService";

export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      name: "",
      manualId: "",
      decowaveId: "",
      slotType: 0,
      snackbar: false,
      text: "Not set (contact IT)",
      color: "",
      timeout: 3000
    };
  },
  computed:{
      ...mapGetters(['GET_SLOT_TYPES'])
  },
  watch: {
    value() {
      this.name="";
      this.manualId="";
      this.slotType= 0;
      this.$emit("input", this.value);
    }
  },
  methods: {
    addDevice() {
      DeviceService.post({
        type: this.slotType,
        name: this.name,
        manualId: this.manualId,
        decowaveId: this.decowaveId,
      }).then(response => {
        this.$emit("input", false);
        this.slotType = 0;
        this.name = this.decowaveId = this.manualId = ""
        this.$emit("reload");
      })
      .catch(error => {
        this.snackShow("error",error.response.data);
      });
    },
    snackShow(color,msg) {
        this.color = color;
        this.text = msg;
        this.snackbar = true;
    }
  }
};
</script>

<style>
.v-form {
  margin: 10px;
}
</style>
