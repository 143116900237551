import Vue from 'vue'
import Amplify from 'aws-amplify';
import { AmplifyEventBus } from "aws-amplify-vue";
import axios from 'axios'

var endpoint = "user"


class UserSdk {

  
    getUserFromToken() {
        return Vue.axios.get(endpoint + '/token');
    }

    getUsers(mode,id) {
        let url = endpoint;

        if(mode) {
            url += `?mode=${mode}`;
        }

        if(id) {
            url += `&id=${id}`;
        }
        return Vue.axios.get(url);
    }
    postClient(request ) {
        return Vue.axios.post(endpoint, request)
    }

    getAdmins() {
        return Vue.axios.get(endpoint + '/admin');
    }

    getClientUsers(clientId) {
        return Vue.axios.get(endpoint + '/client/' + clientId);
    }

    delete(userId){
        return Vue.axios.get(endpoint + '/delete/' + userId);
    }
}

var userSdk = new UserSdk();

export default userSdk;