<template>
  <v-row>
    <v-col cols="12">
      <v-select v-model="value" v-on:click="handleSelectChange" :items="dropdown_icon" item-text="text" label="Date"></v-select>
    </v-col>
    <date-picker-modal v-model="openCustom" v-on:submit="onCustom"></date-picker-modal>
  </v-row>
</template>

<script>
import moment from "moment";
import DatePickerModal from "./datePickerModal"

export default {
  components: {
    DatePickerModal
  },
  data() {
    return {
      openCustom: false,
      value: "Today",
      resLabel: "Hour",
      startTime: this.dayStart(Date()),
      endTime: this.dayEnd(Date()),
      dropdown_icon: ["Today","Yesterday","Last 7 days","Last 30 days","Custom"],
    };
  },
  mounted() {
    this.emitChange();
  },
  methods: {
      onCustom(data) {
        const date = Date.p
        this.resLabel = "Hour";
        this.startTime = this.dayStart(data.startDate);
        this.endTime = this.dayEnd(data.startDate);
        this.emitChange();
      },
      setTime(mode) {
      switch (mode) {
        case "Today":
          this.reslabel = "Hour";
          this.startTime = this.dayStart(moment());
          this.endTime = this.dayEnd(moment());
          break;
        case "Yesterday":
          this.resLabel = "Hour";
          this.startTime = this.dayStart(moment().subtract(1, "days"));
          this.endTime = this.dayEnd(moment().subtract(1, "days"));
          break;
        case "Last 7 days":
          this.resLabel = "Day";
          this.startTime = this.dayStart(moment().subtract(7, "days"));
          this.endTime = this.dayEnd(moment().subtract(0, "days"));
          break;
        case "Last 30 days":
          this.resLabel = "Day";
          this.startTime = this.dayStart(moment().subtract(1, "months"));
          this.endTime = this.dayEnd(moment().subtract(0, "days"));
          break;
        case "Custom":
            
            this.openCustom = true;
            break;
      }

      this.emitChange();
    },
    emitChange() {
        this.$emit("date-change",{ start: this.startTime, end: this.endTime, res: this.resLabel})
    },
          rangeStr() {
      const start = moment(this.startTime).format(
        "Do MMM YYYY" // , H:mm:ss
      );
      const end = moment(this.endTime).format("Do MMM YYYY");
      return `${start} - ${end}`;
    },
      setValue(newVal)
      {
          this.value = newVal;
          this.$emit("input", this.newVal);
      },
    dayStart(date) {
      return moment(date)
        .hour(0)
        .minute(0)
        .second(0);
    },
    dayEnd(date) {
      return moment(date)
        .hour(23)
        .minute(59)
        .second(59);
    },
    handleSelectChange() {
      this.setTime(this.value)
      this.$emit("input", this.value);
    }
  },
  watch: {
    value() {
      this.handleSelectChange()
        
    }
  }
};
</script>

<style>
</style>