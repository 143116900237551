<template>
  <div>
    <div style="height: 350px">
      <mapbox v-on:map-clicked="mapClicked" v-on:zoom="zoom=$event" :locations="locations"></mapbox>
    </div>
  </div>
</template>

<script>
import Mapbox from "../../shared/mapbox";

export default {
  components: {
    Mapbox
  },
  props: {
      zoom: Number
  },
  data: () => {
    return {
      locations: [],
      lat: 0,
      lng: 0
    };
  },
  methods: {
    mapClicked: function(clickCoords) {
      this.lat = clickCoords.lat;
      this.lng = clickCoords.lng;

      this.locations =[clickCoords];
    }
  }
};
</script>

<style>
</style>