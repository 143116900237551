import moment from "moment";
import { endDay, startDay } from '../helpers/day-operations'
import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request'

const _duration_list = [
    { id: 0, name: "Today", label: "Hour", labelId: 1, size: 45, start: startDay(moment()), end: endDay(moment()) },
    { id: 1, name: "Yesterday", label: "Hour", labelId: 1, size: 45, start: startDay(moment().subtract(1, "days")), end: endDay(moment().subtract(1, "days")) },
    { id: 2, name: "Last 7 days", label: "Day", labelId: 2, size: 70, start: startDay(moment().subtract(7, "days")), end: endDay(moment().subtract(0, "days")) },
    { id: 3, name: "Last 30 days", label: "Day", labelId: 2, size: 70, start: startDay(moment().subtract(1, "months")), end: endDay(moment().subtract(0, "days")) },
    { id: 4, name: "Select a range", label: "Day", labelId: 2, size: 70, start: null, end: null }
]

export default {
    state: {
        heatmap_ready: false,
        duration_list: _duration_list,
        selected_duration: _duration_list[0],
        durantion_range: { min: 1, max: 24, labels: [], type: "" },
        venue_heatmap: {}
    },
    getters : {
        HEATMAP_READY: state => {
            return state.heatmap_ready
        },
        GET_DURATION: state => {
            return state.duration_list
        },
        GET_SELECTED_DURATION: state => {
            return state.selected_duration
        },
        GET_VENUE_HEATMAP: state => {
            return state.venue_heatmap
        },
        GET_DRANGE: state => {
            return state.durantion_range
        }
    },
    actions: {
        CREATE_HEATMAP: async (context) => {        
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('CREATE_HEAT_RANGE', null, { root: true })
        },
        CHANGE_DURATION_RANGE: async (context, dates) => {
            context.state.selected_duration.start = dates[0]
            context.state.selected_duration.end = dates[1]
            await context.dispatch('CREATE_HEAT_RANGE', null, { root: true })
        },
        CREATE_HEAT_RANGE: async (context) => {

            let venueID = context.rootGetters.CURRENT_VENUE.id
            let clientID = context.rootGetters.GET_USER_ID

            let _start = context.state.selected_duration.start
            let _end = context.state.selected_duration.end
            let _resolution = context.state.selected_duration.labelId

            console.log(context.state.selected_duration)

            if(_start !== null && _end !== null){
            
                let rq = {start: _start, end: _end, resolution: _resolution, venueId: venueID}
    
                await fetchRQ(`location/${clientID}/heatmap/timekeys`, rq).then((data) => {
                    if(data.valid){
                        let _max = data.message.length
                        context.commit('CHANGE_DRANGE', { min: 1, max: _max, labels: data.message, size: context.state.selected_duration.size, type: context.state.selected_duration.label })
                    }
                })
            }

        },
        FIND_HEAT_MAP: async (context, _selected) => {

            let venueID = context.rootGetters.CURRENT_VENUE.id
            let clientID = context.rootGetters.GET_USER_ID
            let _resolution = context.state.selected_duration.labelId

            let rq = {venueId: venueID, timeKey: _selected, period: _resolution}

            await fetchRQ(`location/${clientID}/heatmap/slice`, rq).then((data) => {

                if(data.valid){
                    let features = { ... JSON.parse(JSON.stringify(data.message)), id: "HeatMap"}
                    let geoJson = { type: "geojson", data: features }
                    context.commit('CHANGE_VENUE_HEATMAP', geoJson)
                }
            })
        }

    },
    mutations: {
        SET_HEATMAP_READY: (state, value) => {
            state.heatmap_ready = value
        },
        CHANGE_HEAT_DURATION: (state, value) => {
            state.selected_duration = value
        },
        CHANGE_DRANGE: (state, value) => {
            state.durantion_range = value
        },
        CHANGE_VENUE_HEATMAP: (state, value) => {
            state.venue_heatmap = value
        }
    }
}