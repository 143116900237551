<template>
  <v-dialog v-model="value" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
    <div class="dialog-box-inner">
      <div class="box-header">
        <div class="left">
            <div class="filter-area table">
            </div>
        </div>
        <div class="right">
          <v-btn icon @click="value = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="history_list">
           <v-data-table :headers="GET_HISTORY_LIST.headers" :items="GET_HISTORY_LIST.values" :items-per-page="5" class="elevation-1"></v-data-table>
      </div>
    </div>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  components: { },
  data() {
    return {
        date: new Date().toISOString().substr(0, 10),
        dateSelector: false,
    };
  },
  computed:{
      ...mapGetters(['GET_HISTORY_LIST', 'GET_HISTORY_DATE'])
  },
  methods: {
    close() {
      this.$emit("input", false);
    }
  },
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    value() {      
      this.$emit("input", this.value);
    }
  }
};
</script>