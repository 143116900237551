import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

export default {
    state: {
        map_ready: false,
        selected_date: new Date().toISOString().substr(0, 10),
        venue_tracks: null,
    },
    getters : {
        TRACKINGMAP_READY: state => {
            return state.map_ready
        },
        TRACK_DATE: state => {
            return state.selected_date
        },
        VENUE_TRACKS: state => {
            return state.venue_tracks
        }
    },
    actions: {
        INIT_TRACKING_MAP: async (context) =>{
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            await context.dispatch('CREATE_TRACK_DATA')
        },
        CREATE_TRACK_DATA: async(context, _date) => {

            let date = _date != null ? _date : context.state.selected_date
            let _floor = context.rootGetters.GET_SELECTED_FLOOR
            let venueID = context.rootGetters.CURRENT_VENUE.id
            let clientID = context.rootGetters.GET_USER_ID

            let rq = { date: date, venueId: venueID, floor: _floor }
            let tracks = await fetchRQ(`location/${clientID}/geotracks`, rq)
            let tracksResponse = JSON.parse(JSON.stringify(tracks))

            if(tracksResponse.valid && tracksResponse.message.isValid){
                let _json = { ... JSON.parse(JSON.stringify(tracksResponse.message.message))}
                context.commit('SET_VENUE_TRACKS', _json)
            }
        },
        GET_REALTIME_TRACK: async(context, _devices) => {

            let venueID = context.rootGetters.CURRENT_VENUE.id
            let floor = context.rootGetters.GET_SELECTED_FLOOR

            let rq = {venueId: venueID, historyItems: 10, devices: _devices, floor: floor, isHistory: true}

            return await fetchRQ(`location/getrealtimedata`, rq)

        }
    },
    mutations: {
        SET_TMAP_READY: (state, value) => {
            state.map_ready = value
        },
        SET_TRACK_DATE: (state, value) => {
            state.selected_date = value
        },
        SET_VENUE_TRACKS: (state, value) => {
            state.venue_tracks = value
        },
        SET_LIFETIME_TRACKS: (state, value) => {
            state.lifetime_tracking = value
        }

    }
}