<template>
    <div class="view-list padding-body">
        <venue-selection-box v-if="!IS_VENUE_SELECTED"></venue-selection-box>
        <div class="table-list" v-else>
            <div class="inner" v-if="GET_ALERTS_MGS_LIST_READY">
                <div class="list-box">
                    <template>
                        <v-data-table :headers="GET_LIST_ALERTS_MSG.headers" :items="GET_LIST_ALERTS_MSG.values" :items-per-page="10">
                            <template v-slot:item.action="{item}">
                                <v-btn class="mr-1" @click="DeleteAlert(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                                <v-btn class="mr-1" @click="EditAlert(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueSelectionBox from '../../shared/venue-selection-box'

export default {
    components: { VenueSelectionBox },
    data() {
        return {
            devices: []
        };
    },
    computed:{
        ...mapGetters(['CURRENT_VENUE', 'IS_VENUE_SELECTED', 'GET_ALERTS_MGS_LIST_READY', 'GET_LIST_ALERTS_MSG'])
    },
    watch: {
        CURRENT_VENUE(newValue, oldValue){
            this.$store.dispatch('GENERATE_ALERTS_MSG_LIST', this.devices).then(response => {
                this.$store.commit('SET_ALERTS_MGS_LIST_READY', true)
            })
        }
    },
    methods: {
        DeleteAlert(item){},
        EditAlert(item){}
    },
    mounted() {
        if(this.IS_VENUE_SELECTED){
            this.$store.dispatch('GENERATE_ALERTS_MSG_LIST', this.devices).then(response => {
                this.$store.commit('SET_ALERTS_MGS_LIST_READY', true)
            })
        }
    },
}
</script>