<template>
  <tr v-if="floor!=null">
    <td>{{floor.floorIndex}}</td>
    <td>{{floor.floorHeightM}}</td>
    <td>{{floor.floorWidthM}}</td>
    <td>{{floor.floorDepthM}}</td>
    <td>
        <div v-if="floor.floorPlanUrl==null">
          <a v-if="!showFloorPlanUpload" @click="showFloorPlanUpload=true">Upload</a>
          <v-file-input v-if="showFloorPlanUpload" accept="image/*, .jpg, .png" v-on:change="uploadFloorPlan" label="File input" dense></v-file-input>
        </div>
        <div v-if="floor.floorPlanUrl != null ">
          <a :href="floor.floorPlanUrl">Download</a>
          <!-- <a @click="view">View</a> -->
        </div>
    </td>
    <!-- accept="image/png, image/jpeg, image/bmp" -->
    <td>
      <div v-if="floor.geoJsonUrl==null">
        <a v-if="!showGeoJsonUpload" @click="showGeoJsonUpload=true">Upload</a>
        <v-file-input v-if="showGeoJsonUpload" v-on:change="uploadGeojson" accept=".json" label="File input" dense></v-file-input>
      </div>
      <div v-if="floor.geoJsonUrl!=null">
        <a :href="floor.geoJsonUrl">Download</a>
      </div>
    </td>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template> -->
    </v-snackbar>
  </tr>
</template>

<script>
export default {
  props: {
    floor: Object,
    venueId: String,
    clientId: String,
  },
  data() {
    return {
      showFloorPlanUpload: false,
      showGeoJsonUpload: false,
      text: '',
      snackbar: false,
      timeout: 1500
    };
  },
 
  methods: {
    // view() {
    //   window.open(this.localFloor.floorPlanUrl,"_blank");
    // },
    uploadGeojson(files) {
      this.uploadFile(files, "geojson");
      this.showGeoJsonUpload = false;
    },
    uploadFloorPlan(files) {
      this.uploadFile(files, "floorplan");
      this.showFloorPlanUpload = false;
    },
    async uploadFile(files, mode) {
      let formData = new FormData();
      formData.append("files", files, files.name);

      var request = {
        clientId: this.clientId,
        venueId: this.venueId,
        mode: mode,
        formData: formData,
        floorIndex: this.floor.floorIndex
      }

      try {
        await this.$store.dispatch('venues/uploadFile', request,{ root: true });
        this.text = "Updated";
        this.snackbar = true;
      }
      catch (ex) {
        console.error(ex);
        this.text = "Failed!"
        this.snackbar = true;
      }
    },
  },
};
</script>

<style>
</style>