import DataResponse from '../entities/response'

export default async function (url) {

    let _response = new DataResponse(false, "Uknown error", 500).init()
    const host = process.env.VUE_APP_API

    try {

        const fetchResponse = await fetch(host + "/" + url);
        const data = await fetchResponse.json();

        _response = { valid: true, code: 401, message: data }
        
    } catch (error) {
        _response = { valid: false, code: 501, message: error.message }
    }  

    return _response
    
}
