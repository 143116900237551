import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

const _alert_types = [
    { id: 'enter', text: 'Enter the zone'},
    { id: 'leave', text: 'Leave the zone'},
    { id: 'fromto', text: 'From one to another zone'},
    { id: 'temperature', text: 'Temperature issue'},
    { id: 'falldown', text: 'The client is falldown'},
    { id: 'sos', text: 'Panic button activated'},
    { id: 'heartrate', text: 'Heart rate issue'}
]

const _alerts_msg_headers = [
    { id: 'message', text: 'Message', sortable: true, order: 1},
    { id: 'deviceId', text: 'Device ID', sortable: true, order: 2},
    { id: 'type', text: 'Type', sortable: true, order: 3 },
    { id: 'created', text: 'Date Created', sortable: true, order: 4},
    { id: 'expired', text: 'Date Expired', sortable: true, order: 5},
    { id: 'action', text: 'Action', sortable: false, order: 6, align: 'right'},
]

export default {
    state: {
        is_alerts_msg_ready: false,
        alerts_mgs_list: { headers: [], values: [] }
    },
    getters:{
        GET_ALERTS_MGS_LIST_READY: state => {
            return state.is_alerts_msg_ready
        },
        GET_LIST_ALERTS_MSG: state => {
            return state.alerts_mgs_list
        }
    },
    actions: {
        GENERATE_ALERTS_MSG_LIST: async (context, _devices) => {
            
            let _list = { headers: [], values: [] }
            let _venue = context.rootGetters.CURRENT_VENUE

            if(_venue != null && _venue.id != null){

                let _request = { venueId: _venue.id, devices: _devices }
                let _alerts_messages = await fetchRQ(`alerts/getdefined`, _request)

                if(_alerts_messages != null && _alerts_messages.valid && _alerts_messages.message.isValid){

                    _alerts_messages.message.data.forEach(_alert => {
                        let _type = _alert_types.find(x => x.id == _alert.type)
                        let _item = { message: _alert.message, deviceId: _alert.deviceID, type: _type != undefined ? _type.text : "Not defined", created: new Date(_alert.created.fullDate).toLocaleDateString("en-US"), expired: new Date(_alert.expired.fullDate).toLocaleDateString("en-US") }
                        _list.values.push(_item)
                    })

                }
            }

            _list.headers = await context.dispatch('GENEREATE_LIST_HEADERS', null, { root: true })
            context.commit('SET_LIST_ALERTS_MSG', _list)
        },
        GENEREATE_LIST_HEADERS: async (context) => {

            let headers = []

            _alerts_msg_headers.forEach(header => {
                headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return headers;
        }
    },
    mutations: {
        SET_ALERTS_MGS_LIST_READY: (state, value) => {
            state.is_alerts_msg_ready = value
        },
        SET_LIST_ALERTS_MSG: (state, value) => {
            state.alerts_mgs_list = value
        },
    }
}