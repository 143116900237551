<template>
  <div>
    <div id="admin-bar">
      <v-app>
        <v-app-bar app color="primary" dark style="height:64px;margin-top:0px;transform:translateY(0px);left:0px;right:0px;">
          <router-link to="/" class="logo-box"><img height="40" src="/img/mini-logo.png" alt="Briteyellow" />
            <span class="logo-title">BriteLocate Platform</span>
          </router-link>
        </v-app-bar>
      </v-app>
    </div>
    <div class="login-panel flex-column">
      <h1>Login</h1><br>
      <div v-show="mode!=='signup'">
        <amplify-authenticator></amplify-authenticator>
      </div>

      <div v-show="mode==='signup'">
        <amplify-sign-up v-bind:signUpConfig="signUpConfig"></amplify-sign-up>
      </div>

      <p v-if="showContactBY">Briteyellow need to allow access. Please contact info@briteyellow.com</p>
      <p v-if="error">
        Error getting user, try hitting the F5 key to refresh browswer or contact
        <a href="mailto:info@briteyello.com">info@briteyellow.com</a>
      </p>
    </div>
     <v-snackbar v-model="snackbar" color="'success'" :timeout="timeout">Your confirmation code has been emailed to you!</v-snackbar>
  </div>
</template>

<script>
import Amplify from "aws-amplify";
import UserSdk from "../sdks/userSdk";
import UserService from "../services/userService";
import AuthInterceptor from '../sdks/authInterceptor'

export default {
  data: () => {
    return {
      showContactBY: false,
      error: false,
      mode: "login",
      timeout: 6000,
      snackbar: false,
      signUpConfig: {
        header: "Create your user account to get started",
        hideAllDefaults: true,
        defaultCountryCode: "1",
        signUpFields: [
          {
            label: "Email",
            key: "username",
            required: true,
            displayOrder: 1,
            type: "email",
            custom: false
          },
          {
            label: "Password",
            key: "password",
            required: true,
            displayOrder: 2,
            type: "password"
          }
        ]
      }
    };
  },
  mounted() {

    this.reevaluateAuthStatus();

    AuthInterceptor.listener = this.authListener;

    if (this.$route.query.mode === "signup") {
        this.mode = "signup";
        return;
      }

  },
  methods: {
    authListener(event) {
        this.mode = event;
        this.handleAuthEvent(event);
    },
    handleAuthEvent(eventInfo) {
      if (eventInfo === "signedIn" || eventInfo === "signIn") {
              Amplify.Auth.currentAuthenticatedUser().then(user => {
                this.userRedirect();
              });
            }

      if(eventInfo === "confirmSignUp") {
        this.snack
      }
    },
    reevaluateAuthStatus() {
      Amplify.Auth.currentAuthenticatedUser().then(_ => { this.userRedirect() }).catch(e => {});
    },
    userRedirect() {
      UserSdk.getUserFromToken()
        .then(result => {
          UserService.user = result.data.user;
          switch (result.data.user.type) {
            case 1:
              this.$router.push({ path: "admin/clients" });
              break;
            case 2:
              this.$router.push({
                path: `partner/${result.data.user.partnerId}/clients`
              });
              break;
            case 3:
              this.$router.push({
                path: `client/${result.data.user.clientId}/dashboard`
              });
              break;
            default:
              this.showContactBY = true;
          }
        })
        .catch(e => {
          console.error("Failed to get user token", e);
          this.error = true;
        });
    }
  }
};
</script>

<style>
#admin-bar {
  height: 0px;
}

.login-panel {
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
}
</style>