<template>
  <div class="view-map tracking">
    <venue-selection-box v-if="!IS_VENUE_SELECTED"></venue-selection-box>
    <div class="map-inner" v-else>
      <div class="filter-area map" v-if="TRACKINGMAP_READY">
        <div class="filter-date left">
          <v-menu v-model="dateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" label="Select a date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="dateSelector = false" :max="maxDate"></v-date-picker>
          </v-menu>
        </div>
        <div class="filter-floor left">
          <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
        </div>
        <div class="filter-refresh-button left">
          <v-btn color="primary" @click="refreshData()">Refresh</v-btn>
        </div>
      </div>
      <mapview :geoMap="GET_VENUE_MAP" :tracks="VENUE_TRACKS" :jumpTo="GET_JUMPER"></mapview>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from '../shared/mapview'
import VenueSelectionBox from '../shared/venue-selection-box'

export default {
  components: { Mapview, VenueSelectionBox },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    maxDate: new Date().toISOString().substr(0, 10),
    dateSelector: false,
    selectedFloor: null
  }),
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'TRACKINGMAP_READY', 'GET_JUMPER', 'GET_VENUE_MAP', 'GET_VENUE_FLOORS', 'IS_VENUE_SELECTED', 'GET_SELECTED_FLOOR', 'VENUE_TRACKS'])
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.$store.dispatch('INIT_TRACKING_MAP').then((response) => {    
        this.selectedFloor = this.GET_SELECTED_FLOOR
        this.$store.commit('SET_TMAP_READY', true)
      })
    },
    date(newValue, oldValue){
      this.$store.commit('SET_TRACK_DATE', newValue)
      this.$store.dispatch('CREATE_TRACK_DATA')
    }
  },
  mounted() { 
    if(this.IS_VENUE_SELECTED){
      this.$store.commit('SET_TRACK_DATE', this.date)
      this.$store.dispatch('INIT_TRACKING_MAP').then((response) => {
        this.selectedFloor = this.GET_SELECTED_FLOOR
        this.$store.commit('SET_TMAP_READY', true)
      })
    }
  },
  methods: {
    refreshData(){
      this.$store.dispatch('CREATE_TRACK_DATA')
    },
    changeFloor(){
      this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
      this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor) 
      this.refreshData();
    },

  }
};
</script>