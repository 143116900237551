<template>
  <div>
    <v-row>
        <v-col cols="3">
            <v-text-field readonly v-model="product.name"></v-text-field>
        </v-col>
      <v-col cols="2">
        <v-text-field v-model="quantity" filled type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="ppu" readonly></v-text-field>
      </v-col>
            <v-col cols="2">
        <v-text-field v-model="lpu" readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="total"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  data: () => {
    return {
      quantity: "0",
      numQuant: 0
    };
  },
  mounted() {
    this.quantity = this.product.quantity;
  },
  watch: {
      quantity() {
          this.numQuant = parseInt(this.quantity);
          if(this.numQuant<0) {
            this.quantity = "0";
            this.numQuant = 0;

          } else {

            this.$emit('update', { name: this.product.name, quantity: this.numQuant})
          }

      }
  },
  computed: {
    ppu() {return '£ ' + this.product.pricePerUnit;},
    total() {return '£ ' + this.product.total;},
    lpu() {
        if(this.product.discount === 0) {

          return '£ ' + this.product.liscencePrice;
        } else {
          const percent = (this.product.discount * -100);
          const discountedPrice = this.product.liscencePrice * (1 - this.product.discount);
          return `£ ${discountedPrice.toFixed(2)} (${percent}%)`;
        }

      }

  }
};
</script>

<style>
</style>