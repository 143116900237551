<template>
  <div class="padding-body">
    <div class="info-box" v-if="!IS_VENUE_SELECTED">
      <div class="notification">
        <div class="inner"><v-icon dark>mdi-cancel</v-icon> To view the data, please select a venue</div>
      </div>
    </div>
    <div class="table-list" v-else>
      <div class="inner">
        <div class="list-box" v-if="GET_ALERTS_LIST_READY">
            <template>
              <v-data-table :headers="GET_LIST_ALERTS.headers" :items="GET_LIST_ALERTS.values" :items-per-page="15">
                  <template v-slot:item.action="{item}">
                      <v-btn class="mr-1" @click="editAlert(item)"><v-icon small title="Edit Zone">mdi-pencil-outline</v-icon></v-btn>
                      <v-btn class="mr-1" @click="deleteAlert(item)"><v-icon small title="Delete zone">mdi-trash-can-outline</v-icon></v-btn>
                  </template>
              </v-data-table>
            </template>
        </div>
        <div class="btn-box">
          <v-btn color="primary right-margin" @click="addAlert()">Add alert</v-btn>
        </div>
        <alerts-box v-model="GET_MODAL_STATUS.venue_alerts"></alerts-box>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AlertsBox from './alerts/index'

export default {
  components: { AlertsBox },
  data() {
    return {};
  },
  computed:{
      ...mapGetters(['GET_LIST_ALERTS', 'GET_MODAL_STATUS', 'CURRENT_VENUE', 'IS_VENUE_SELECTED', 'GET_ALERTS_LIST_READY'])
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.$store.dispatch('CREATE_VENUE_ALERTS_LIST').then(response => {
        this.$store.commit('SET_ALERTS_LIST_READY', true)
      })
      this.$store.dispatch('INIT_ALERT_MAP').then((response) => {
        this.$store.commit('SET_ALERT_MAP_READY', true)
      })
    }
  },
  methods: {
    addAlert(){
      this.$store.commit('SET_ALERT_CREATE', true)   
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_alerts", value: true })
    },
    editAlert(item){
      this.$store.commit('SET_ALERT_CREATE', false)
      this.$store.commit('SET_SELECTED_ALERT', item.id)
      this.$store.commit('SET_MODAL_STATUS', { target: "venue_alerts", value: true }) 
    },
    deleteAlert(item){
      this.$store.dispatch('DELETE_ALERT', item)
    }
  },
  mounted() {
    if(this.IS_VENUE_SELECTED){
      this.$store.dispatch('CREATE_VENUE_ALERTS_LIST').then(response => {
        this.$store.commit('SET_ALERTS_LIST_READY', true)
      })
      this.$store.dispatch('INIT_ALERT_MAP').then((response) => {
        this.$store.commit('SET_ALERT_MAP_READY', true)
      })
    }
  },
};
</script>