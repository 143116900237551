import Vue from 'vue'


class DeviceLocationApiService {

    get(clientId, venueId) {
        return Vue.axios.get(`client/${clientId}/venue/${venueId}/devicelocation`);
    }

    getdoors(clientId, venueId) {
        return Vue.axios.get(`client/${clientId}/venue/${venueId}/devicelocation/doors`);
    }
  
    post(clientId, venueId, request) {
        return Vue.axios.post(`client/${clientId}/venue/${venueId}/devicelocation`, request);
    }

    assignDevice(clientId,venueId,deviceLocId,deviceId) {
        return Vue.axios.get(`client/${clientId}/venue/${venueId}/devicelocation/${deviceLocId}/assign/${deviceId}`);
    }

    unassignDevice(clientId, venueId, deviceLocId, deviceId){
        return Vue.axios.get(`client/${clientId}/venue/${venueId}/devicelocation/${deviceLocId}/unassign/${deviceId}`);
    }


    // post(request ) {
    //     return Vue.axios.post(endpoint,request)
    // }

    // assignDevice(request) {
    //     return Vue.axios.post(`${endpoint}/assign`,request)
    // }

    // unassignDevice(request)  {
    //     return Vue.axios.post(`${endpoint}/unassign`,request)
    // }
}

var deviceService = new DeviceLocationApiService();

export default deviceService;