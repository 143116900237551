import fetchGetRQ from '../../plugins/data-get-requests'

export default {
    state: {
        user: { id: "" }
    },
    getters : {
        GET_USER: state => {
            return state.user
        },
        GET_USER_ID: state => {
            return state.user.id
        }
    },
    actions: {
        FIND_USER: async (context) => {

        }

    },
    mutations: {
        CHANGE_USER: (state, value) => {
            state.user = value
        },
        CHANGE_USER_ID: (state, value) => {
            state.user.id = value
        }
    }
}