import Vue from 'vue'
import fetchGetRQ from '../../plugins/data-get-requests'
import fetchRQ from '../../plugins/data-request.js'

const _headers = [
    { id: 'name', text: 'Name', sortable: true, order: 1},
    { id: 'description', text: 'Description', sortable: true, order: 2},
    { id: 'type', text: 'Type', sortable: true, order: 2},
    { id: 'fromzone', text: 'From Zone', sortable: true, order: 3 },
    { id: 'tozone', text: 'To Zone', sortable: true, order: 4 },
    { id: 'created', text: 'Date Created', sortable: true, order: 5},
    { id: 'action', text: 'Action', sortable: false, order: 6, align: 'right'},
]

const _alertTypes = [
    { id: 'enter', text: 'Enter the zone'},
    { id: 'leave', text: 'Leave the zone'},
    { id: 'fromto', text: 'From one to another zone'},
]

export default {
    state: {
        is_alert_create: true,
        is_alertmap_ready: false,
        is_alerts_list_ready: false,
        all_zones: [],
        alert_selection: { cross: false, leave: false, fromto: false },
        alerts:{ headers: [], values: []},
        selected_alert_id: null,
        selected_alert_data: {}
    },
    getters:{
        ALERTMAP_READY: state => {
            return state.is_alertmap_ready
        },
        GET_LIST_ALERTS: state => {
            return state.alerts
        },
        GET_ALERT_CREATE: state => {
            return state.is_alert_create
        },
        GET_ALERT_TYPES: state => {
            return _alertTypes
        },
        GET_ALL_ZONES: state => {
            return state.all_zones
        },
        GET_ALERT_SELECTION: state => {
            return state.alert_selection
        },
        GET_ALERTS_LIST_READY: state => {
            return state.is_alerts_list_ready
        },
        GET_SELECTED_ALERT: state => {
            return state.selected_alert_id
        },
        GET_SELECTED_ALERT_DATA: state => {
            return state.selected_alert_data
        }
    },
    actions: {
        CREATE_VENUE_ALERTS_LIST: async (context) => {

            let _list = { headers: [], values: []}
            let _venue = context.rootGetters.CURRENT_VENUE

            if(_venue != null && _venue.id != null){
                let _alerts = await fetchGetRQ(`alerts/getall/${_venue.id}`)

                if(_alerts != null && _alerts.valid && _alerts.message.isValid){
                    
                    let results = _alerts.message.data;

                    results.forEach(async result => {
                        
                        let alertZones = { from: "Not Defined", to: "Not Defined" }
                        let _alertZones = result.zones != null ? result.zones : null;
                        let _defineType = _alertTypes.find(x => x.id == result.type)
                        let alertType = _defineType ? _defineType.text : "Not Defined";

                        if(_alertZones != null){
                            await context.dispatch('CREATE_VENUE_ZONES_LIST', null, {root: true}).then(response => {
                                let _zones = context.rootGetters.GET_LIST_ZONES.values
                                if(_zones != null && _zones.length > 0){
                                   alertZones.from =  _alertZones != null && _alertZones.begin.zoneId != null ? _alertZones.begin.name : alertZones.from 
                                   alertZones.to = _alertZones != null && _alertZones.end.zoneId != null ? _alertZones.end.name : alertZones.to 
                                }
                            })
                        }

                        _list.values.push({ id: result.id, name: result.name, description: result.description, type: alertType, fromzone: alertZones.from, tozone: alertZones.to, created: result.created.dateOnly })
                    })
                }
            }

            _list.headers = await context.dispatch('CREATE_VENUE_ALERTS_HEADERS', null, { root: true })
            context.commit('SET_LIST_ALERTS', _list)
        },
        CREATE_VENUE_ALERTS_HEADERS: async (context) => {
            let headers = []

            _headers.forEach(header => {
                headers.push({value: header.id, text: header.text, sortable: header.sortable, align: header.align})
            })

            return headers;
        },
        FIND_ALL_ZONES: async (context) => {

            let _zones = context.rootGetters.GET_LIST_ZONES

            if(_zones == null || _zones.values.length == 0) {
                context.dispatch('CREATE_VENUE_ZONES_LIST', null, { root: true }).then((response) => {
                    _zones = context.rootGetters.GET_LIST_ZONES
                })
            }

            context.commit('SET_ALL_ZONES', _zones.values)
        },
        INIT_ALERT_MAP: async (context) => {
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
            context.commit('SET_ALERT_MAP_READY', true)
        },
        FIND_SELECTED_ALERT: async (context) => {

            let alert = context.state.selected_alert_id
            let _alert = await fetchGetRQ(`alerts/get/${alert}`)

            if(_alert != null && _alert.valid && _alert.message.isValid){

                let result = _alert.message.data

                let _data = {
                    id: result.id,
                    type: result.type,
                    zones:{
                        begin:{
                            zoneid: result.zones.begin != null ? result.zones.begin.zoneId : "",
                            name: result.zones.begin != null ? result.zones.begin.name : "",
                        },
                        end:{
                            zoneid: result.zones.end != null ? result.zones.end.zoneId : "",
                            name: result.zones.end != null ? result.zones.end.name : "",
                        }
                    },
                    name: result.name,
                    description: result.description
                }
                
                context.commit('SET_SELECTED_ALERT_DATA', _data)
            }

        },
        CREATE_ALERT: async (context, data) => {

            let _venue = context.rootGetters.CURRENT_VENUE

            if(_venue != null && _venue.id != null){

                let names = context.dispatch('FIND_ZONES_NAME', null, { root: true })
                let _data = {...data, venueId: _venue.id }
                
                _data.zones.begin.name = names.begin
                _data.zones.end.name = names.end

                let _alert = await fetchRQ(`alerts/create`, _data)
                
                if(_alert != null && _alert.valid && _alert.message.isValid){
                    context.commit('SET_MODAL_STATUS', { target: "venue_alerts", value: false}) 
                    context.dispatch('CREATE_VENUE_ALERTS_LIST')
                }
            }       

        },
        EDIT_ALERT: async (context, data) => {
   
            let venue = context.rootGetters.CURRENT_VENUE
            let alertId = context.state.selected_alert_id       
            let names = await context.dispatch('FIND_ZONES_NAME', data.zones, { root: true })

            let _data = {...data, id: alertId, venueId: venue.id }

            _data.zones.begin.name = names.begin
            _data.zones.end.name = names.end

            let _alert = await fetchRQ(`alerts/update`, _data)
                
            if(_alert != null && _alert.valid && _alert.message.isValid){
                context.commit('SET_MODAL_STATUS', { target: "venue_alerts", value: false}) 
                context.dispatch('CREATE_VENUE_ALERTS_LIST', null, { root: true })
            }

        },
        DELETE_ALERT: async (context, item) => {

            let _alert = await fetchGetRQ(`alerts/delete/${item.id}`)

            if(_alert != null && _alert.valid && _alert.message.isValid){
                if(_alert.message.data.deletedID != null){
                    context.dispatch('CREATE_VENUE_ALERTS_LIST')
                }
            }
        },

        UPDATE_ALERT_SELECTION: async (context, type) => {
            context.dispatch('REFRESH_ALERT_SELECTION', null, { root: true })
            context.dispatch('FIND_ALL_ZONES', null, { root: true })
            context.commit('SET_ALERT_SELECTION', { target: type, value: true });
        },
        REFRESH_ALERT_SELECTION: async (context) => {
            let _keys = Object.keys(context.state.alert_selection)
            _keys.forEach(key => {
                context.state.alert_selection[key] = false
            })
        }

    },
    mutations: {
        SET_LIST_ALERTS: (state, value) => {
            state.alerts = value
        },
        SET_ALERT_CREATE: (state, value) => {
            state.is_alert_create = value
        },
        SET_ALL_ZONES: (state, value) => {
            state.all_zones = value
        },
        SET_ALERT_SELECTION: (state, value) => {
            state.alert_selection[value.target] = value.value
        },
        SET_ALERT_MAP_READY: (state, value) => {
            state.is_alertmap_ready = value
        },
        SET_ALERTS_LIST_READY: (state, value) => {
            state.is_alerts_list_ready = value
        },
        SET_SELECTED_ALERT: (state, value) => {
            state.selected_alert_id = value
        },
        SET_SELECTED_ALERT_DATA: (state, value) => {
            state.selected_alert_data = value
        }
    }
}