<template>
<div>
   <admin-bar></admin-bar>
    <v-content>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-content>
    </div>
</template>

<script>
import AdminBar from "./adminBar";

export default {
  components: {
    AdminBar
  }
};
</script>