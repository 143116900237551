import fetchRQ from '../../../plugins/data-request'
import fetchGetRQ from '../../../plugins/data-get-requests'

export default {
    state: {
        date_visibility: true,
        wireless_map_ready: false,
        wireless_counts: [],
        timer: {}
    },
    getters : {
        IS_WIRELESSMAP_READY: state => {
            return state.wireless_map_ready
        },
        GET_WIRELESS_DEVICES: state => {
            return state.wireless_counts
        },
        GET_DATE_VISIBILITY: state => {
            return state.date_visibility
        },
        GET_TIMER: state => {
            return state.timer
        }
    },
    actions: {
        INIT_WIRELESS_MAP: async (context) =>{
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
        },
        GENERATE_DATA: async (context, values) => {

            let venue = context.rootGetters.CURRENT_VENUE.id

            clearInterval(context.state.timer)
            
            if(values.type == "real"){           
                context.state.timer = setInterval(() => {
                    context.dispatch('FIND_REALTIME_DATA', venue, { root: true })
                }, 3000)
                context.commit('SET_DATE_VISIBILITY', true)
            }else{
                context.commit('SET_DATE_VISIBILITY', false)
            }

        },
        FIND_REALTIME_DATA: async(context, venue) => {

            let response = await fetchGetRQ(`counter/wireless/live/${venue}`)

            if(response.valid && response.message.isValid){

                let _id = 0
                let records = []
                
                response.message.data.forEach((record) => {
                    records.push({id: _id, value: [record.coordinates.lng, record.coordinates.lat]})
                    _id++
                })
                context.commit('SET_WIRELESS_DEVICES', records)
            }

        }
    }, 
    mutations: {
        SET_WIRELESSMAP_READY: (state, value) => {
            state.wireless_map_ready = value
        },
        SET_WIRELESS_DEVICES: (state, value) => {
            state.wireless_counts = value
        },
        SET_DATE_VISIBILITY: (state, value) => {
            state.date_visibility = value
        }
    }
}