import Vue from 'vue'
import axios from 'axios'

class FloorUploadRequest {
    constructor(clientId,venueId,floorIndex,mode,formData) {
        this.clientId = clientId;
        this.venueId = venueId;
        this.floorIndex = floorIndex;
        this.mode = mode;
        this.formData = formData;
    }
}

class venueSdk {

    postVenue(request, clientId) {
        return Vue.axios.post(`client/${clientId}/venue`, request);
    }

    getVenues(clientId) {
        return Vue.axios.get(`client/${clientId}/venue`);
    }

    getVenue(clientId,venueId) {
        return Vue.axios.get(`client/${clientId}/venue/${venueId}`);
    }

    putVenue(venue) {
        return Vue.axios.put(`client/${venue.clientId}/venue`,venue);
    }

    addFloor(venue) {
        return Vue.axios.post(`client/${venue.clientId}/venue/${venue.id}/floor`,venue);
    }
    

    // type flooruploadrequest
    uploadFloorFile(request) {
        return Vue.axios
        .post(
          `client/${request.clientId}/venue/${request.venueId}/floor/${request.floorIndex}/upload/${request.mode}`,
          request.formData
        )
    }


    postFloor(request) {
        return Vue.axios.post(`client/${request.clientId}/venue/${request.venueId}/floor/${request.floorHeightM}/width/${request.floorWidthM}/depth/${request.floorDepthM}/bottomLeftLong/${request.floorBottomLeftCornerLong}/bottomLeftLat/${request.floorBottomLeftCornerLat}/topRightLong/${request.floorTopRightCornerLong}/topRightLat/${request.floorTopRightCornerLat}/TopLeftLong/${request.floorTopLeftCornerLong}/TopLeftLat/${request.floorTopLeftCornerLat}/BottomRightLong/${request.floorBottomRightCornerLong}/BottomRightLat/${request.floorBottomRightCornerLat}`, request); 
    } 
   
    
}

var sdk = new venueSdk();
export default sdk;