import Vue from 'vue'
import axios from 'axios'

var endpoint = "invite"


class InviteApiService{

    postInvite(request) {
        return Vue.axios.post(endpoint, request)
    }
}

var apiService = new InviteApiService();

export default apiService;