<template>
  <v-dialog v-model="value" width="400" @keydown.esc="close()" @keydown.enter="close()">
   
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Create Tour</v-card-title>
      <div style="margin:10px">
        <v-form>
          <v-text-field v-model="url" label="URL"></v-text-field>
        </v-form>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addTour()">Ok</v-btn>
        <v-btn @click="value = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TourApiService from "./tourApiService.js";

export default {
  props: {
    value: Boolean,
    clientId: String,
    venueId: String
  },
  data() {
    return {
      url: undefined
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    }
  },
  methods: {
    addTour() {
      TourApiService.postTour(
        {
          url: this.url,
          clientId: this.clientId,
          venueId: this.venueId,
          type: 2
        },
        this.clientId
      ).then(response => {
        this.value = false;
        this.url = undefined;
        this.$emit("reload");
      });
    }
  }
};
</script>

<style>
</style>