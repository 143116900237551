<template>
  <div>
    <v-dialog v-model="value" width="400" @keydown.esc="close()" @keydown.enter="close()">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Update User</v-card-title>
        <div>
          <v-form>
            <v-text-field v-model="firstName" label="Firstname"></v-text-field>
            <v-text-field v-model="lastName" label="LastName"></v-text-field>
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="UpdateUser()">Update user data</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">{{ text }}</v-snackbar>
  </div>
</template>

<script>
import UserSdk from "../../sdks/userSdk";

export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      email: "",
      firstName: "",
      snackbar: false,
      text: "",
      color: "",
      timeout: 3000
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    }
  },
  methods: {
    UpdateUser() {

      // let _body = { email: this.email, scopeId: this.scopeId, mode: this.mode, firstName: this.firstName }

      // InviteSdk.postInvite(_body).then(response => {

      //   let msgType = response.data.success ? "success" : "error";
      //   this.snackShow(msgType,response.data.msg);
      //   this.value = false;
      //   this.$emit("reload");

      // }).catch(error => {
      //   this.snackShow("error","Unknown Error, contact IT");
      // });
      
    },
    snackShow(color,msg) {
        this.color = color;
        this.text = msg;
        this.snackbar = true;
    }
  }
};
</script>